import { ProductCustomEntity } from '@/types/StandardCost'
import Component from './components/Component'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useMemo } from 'react'
import { TypographyP } from '@/components/ui/typography'

interface ComponentsSectionProps {
    product: ProductCustomEntity
}
const stateSelector = (state: Store) =>
    state.stdCostSlice.state.stdCostData.components

const ComponentsSection = ({ product }: ComponentsSectionProps) => {
    const componentsIds = product.COMPONENTES

    const components = useBaseStore(stateSelector)

    const productComponents = useMemo(
        () => componentsIds.map((id) => components.entities[id]),
        [product]
    )

    const extraCosts = useMemo(
        () =>
            productComponents.filter(
                (component) =>
                    component.ESTRUTURA === 'EXTRA-COMPONENTE' ||
                    component.ESTRUTURA == 'EXTRA-PRODUTO'
            ),
        [product]
    )

    const notExtraCosts = useMemo(
        () =>
            productComponents
                .filter(
                    (component) =>
                        component.ESTRUTURA !== 'EXTRA-COMPONENTE' &&
                        component.ESTRUTURA !== 'EXTRA-PRODUTO'
                )
                .sort((a, b) => {
                    if (
                        a.productUsedAsComponentId &&
                        !b.productUsedAsComponentId
                    ) {
                        return -1
                    } else if (
                        !a.productUsedAsComponentId &&
                        b.productUsedAsComponentId
                    ) {
                        return 1
                    } else {
                        return 0
                    }
                }),
        [product]
    )

    return (
        <div className="w-[40%] overflow-y-auto overflow-x-hidden ">
            <div className="flex flex-col overflow-hidden">
                <div className="px-4 pt-4">
                    <TypographyP className="font-semibold">
                        Componentes
                    </TypographyP>
                </div>
                <div className="w-full p-4 space-y-4 overflow-hidden">
                    {notExtraCosts.map((component) => (
                        <Component key={component._id} id={component._id} />
                    ))}
                </div>
            </div>
            {extraCosts.length > 0 && (
                <div>
                    <div className="px-4 pt-4">
                        <TypographyP className="font-semibold">
                            Custos extras
                        </TypographyP>
                    </div>
                    <div className="w-full p-4 space-y-4 overflow-hidden">
                        {extraCosts.map((component) => (
                            <Component key={component._id} id={component._id} />
                        ))}
                    </div>
                </div>
            )}
            <div className="p-4" />
        </div>
    )
}

export default ComponentsSection
