import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import {
    AcceptTypes,
    ProductChild,
    ProductParent,
} from '@/store/productStructureSlice/type'
import { Store } from '@/store/type'
import { getDateFromId } from '@/utils/date'
import { numberFormat } from '@/utils/stringFormatter'
import { format } from 'date-fns'
import { Box, Boxes, Pencil, X } from 'lucide-react'
import { useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'

interface DroppedProductProps {
    product: ProductParent | ProductChild
}

const stateSelector = (state: Store) => ({
    dropItem: state.productStructureSlice.actions.dropItem,
    removeItem: state.productStructureSlice.actions.removeItem,
    selectItem: state.productStructureSlice.actions.selectItem,
})

const DroppedProduct = ({ product }: DroppedProductProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [isHovering, setIsHovering] = useState(false)
    const [isComponentsVisibile, setIsComponentsVisibile] = useState(true)

    const { dropItem, removeItem, selectItem } = useBaseStore(stateSelector)

    const [{ isDragging }, drag] = useDrag<
        ProductParent | ProductChild,
        unknown,
        { isDragging: boolean }
    >(
        () => ({
            type: AcceptTypes.PRODUCT,
            item: () => {
                return {
                    ...product,
                }
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [product]
    )

    const [{ isOver, canDrop }, drop] = useDrop<
        ProductParent | ProductChild,
        unknown,
        { canDrop: boolean; isOver: boolean }
    >(
        () => ({
            accept: [
                product.SK_EMPRESA,
                AcceptTypes.PRODUCT,
                AcceptTypes.PARENT,
            ],
            canDrop: (item) => {
                if (item.id === product.id) return false
                if (product.id === item.parentId) return false
                // if (
                //     !item.parentId &&
                //     (product as ProductParent)?.products?.length > 0
                // )
                //     return false

                if (
                    (product as ProductParent).products.some(
                        (prod) => prod.id === item.id
                    )
                )
                    return false

                if (item.SK_EMPRESA !== product.SK_EMPRESA) return false

                if (product.parentId) return false

                return true
            },
            drop: (item) => {
                dropItem({
                    ...item,
                    parentId: product.id,
                    id: `${item.DS_REVISAO_INICIAL || ''}${
                        item.DS_REVISAO_FINAL || ''
                    }${item.id}`,
                    type: AcceptTypes.CHILD,
                })
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [product]
    )

    drag(drop(ref))

    return (
        <div
            ref={ref}
            className={cn(
                'rounded-md mb-1.5',
                isHovering && (product as ProductParent).products?.length > 0
                    ? 'bg-accent'
                    : '',
                isOver && canDrop ? 'bg-accent' : '',
                isOver && !canDrop ? 'cursor-no-drop' : ''
            )}
        >
            <div
                className={cn(
                    'relative flex items-start gap-2 p-2 rounded-md cursor-pointer group hover:bg-accent',
                    product.ERROR && 'text-red-500',
                    !product.parentId && 'text-primary-500',
                    isDragging && 'opacity-80'
                )}
                onMouseOver={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <div
                    className="-space-y-0.5 text-sm"
                    onClick={() => setIsComponentsVisibile((prev) => !prev)}
                >
                    <div className="flex items-center gap-2">
                        {product.parentId ? (
                            <Box size={18} />
                        ) : (
                            <Boxes size={20} strokeWidth={1.5} />
                        )}
                        <TypographyP>
                            <span>{product.NK_PRODUTO}</span> -{' '}
                            {product.DS_PRODUTO}
                        </TypographyP>
                        {product.parentId && (
                            <TypographyMuted>
                                {' '}
                                -{' '}
                                {product.DD_PRODUTO_PROPRIO === 1
                                    ? 'Produto próprio'
                                    : 'Produto terceiro'}
                            </TypographyMuted>
                        )}
                    </div>
                    {product.parentId ? (
                        <div
                            className={cn('flex gap-4 items-center pl-8 py-1')}
                        >
                            <div className="-space-y-0.5">
                                <TypographyMuted>Qtde</TypographyMuted>
                                <TypographyP>
                                    {product.QTD_COMPONENTE
                                        ? numberFormat(
                                              Number(product.QTD_COMPONENTE),
                                              6
                                          )
                                        : '0'}
                                </TypographyP>
                            </div>
                            <div className="-space-y-0.5">
                                <TypographyMuted>
                                    Revisao inicial
                                </TypographyMuted>
                                <TypographyP>
                                    {product.DS_REVISAO_INICIAL || 'N/A'}
                                </TypographyP>
                            </div>
                            <div className="-space-y-0.5">
                                <TypographyMuted>Revisao final</TypographyMuted>
                                <TypographyP>
                                    {product.DS_REVISAO_FINAL || 'N/A'}
                                </TypographyP>
                            </div>
                            <div className="-space-y-0.5">
                                <TypographyMuted>Dt. inicial</TypographyMuted>
                                <TypographyP>
                                    {product.SK_TEMPO_INICIAL
                                        ? format(
                                              getDateFromId(
                                                  product.SK_TEMPO_INICIAL
                                              ),
                                              'dd/MM/yyyy'
                                          )
                                        : 'N/A'}
                                </TypographyP>
                            </div>
                            <div className="-space-y-0.5">
                                <TypographyMuted>Dt. final</TypographyMuted>
                                <TypographyP>
                                    {product.SK_TEMPO_FINAL
                                        ? format(
                                              getDateFromId(
                                                  product.SK_TEMPO_FINAL
                                              ),
                                              'dd/MM/yyyy'
                                          )
                                        : 'N/A'}
                                </TypographyP>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={cn(
                                'flex gap-4 items-center pl-8 pb-2 ',
                                isDragging
                                    ? 'text-neutral-300'
                                    : 'text-neutral-500'
                            )}
                        >
                            {(product as ProductParent).products.length}{' '}
                            componentes
                        </div>
                    )}
                </div>
                <div className="absolute hidden ml-auto bg-white rounded-md shadow-md top-2 right-4 group-hover:block">
                    <button
                        className="p-2 rounded-md hover:bg-neutral-200"
                        onClick={() => {
                            selectItem(product.id, product.parentId)
                        }}
                    >
                        <Pencil size={12} className="dark:text-zinc-700" />
                    </button>
                    <button
                        className="p-2 rounded-md hover:bg-neutral-200"
                        onClick={() => removeItem(product.id, product.parentId)}
                    >
                        <X size={12} className="dark:text-zinc-700" />
                    </button>
                </div>
            </div>
            {isComponentsVisibile && (
                <div className="pl-6">
                    {(product as ProductParent).products?.length > 0 &&
                        (product as ProductParent).products.map((sub) => (
                            <DroppedProduct key={sub.id} product={sub} />
                        ))}
                </div>
            )}
        </div>
    )
}

export default DroppedProduct
