import { isAxiosError } from '@/api/business'
import { fetchCurrencies } from '@/api/business/approver'
import { useToast } from '@/components/ui/use-toast'
import { Approver } from '@/api/business/approver/types'
import { useQuery } from '@tanstack/react-query'

export const approverKeys = {
    all: ['approver'] as const,
    lists: () => [...approverKeys.all, 'list'] as const,
    list: (initial: number, end: number) =>
        [...approverKeys.lists(), { initial, end }] as const,
    details: () => [...approverKeys.all, 'detail'] as const,
    detail: (params: string) => [...approverKeys.details(), params] as const,
}

interface ApproverQuery {
    onSuccess?: (data: Approver[]) => void
}

export const useApproverQuery = (props?: ApproverQuery) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchCurrencies(),
        queryKey: approverKeys.lists(),
        staleTime: Infinity,
        onSuccess: props?.onSuccess,
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar os aprovadores. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
