import { Table } from '@tanstack/react-table'
import { ProductionVolumeTable } from '.'
import { read, utils, writeFile } from 'xlsx'
import { ProductStructureFlat, ProductionVolume } from '@/types/Product'
import { CreateProductionVolumeBulkProps } from '@/api/business/productionVolume/types'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'

const BULK_HEADERS: {
    original:
        | keyof ProductionVolumeTable
        | keyof CreateProductionVolumeBulkProps
    header: string
}[] = [
    { original: 'SK_EMPRESA', header: 'COD_EMPRESA' },
    { original: 'NK_PRODUTO', header: 'COD_PRODUTO' },
    { original: 'QTD_VOLUME', header: 'VOLUME' },
    { original: 'SK_TEMPO', header: 'DATA' },
]

const HEADERS: {
    original: keyof ProductionVolumeTable
    header: string
}[] = [
    { original: 'SK_EMPRESA', header: 'COD_EMPRESA' },
    { original: 'NK_PRODUTO', header: 'COD_PRODUTO' },
    { original: 'DS_PRODUTO', header: 'PRODUTO' },
    { original: 'QTD_VOLUME', header: 'VOLUME' },
    { original: 'SK_TEMPO', header: 'DATA' },
]

export const useProductionVolumeSpreadsheet = () => {
    const getTableRows = (
        table: Table<ProductionVolumeTable>
    ): Partial<ProductStructureFlat>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original)) {
                const header = HEADERS.find((h) => h.original === key)?.header

                if (header && value) {
                    if (header === 'DATA') {
                        obj[header] = format(getDateFromId(value), 'MM/yyyy')
                    } else {
                        obj[header] = value.toString()
                    }
                }
            }

            return obj as ProductStructureFlat
        })

    const onDownload = (tableInstance: Table<ProductionVolumeTable>) => {
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Volume de produção')

        writeFile(workbook, 'VolumeDeProducao.xlsx', {
            compression: true,
        })
    }

    const onDownloadBulkModel = (
        tableData?: CreateProductionVolumeBulkProps[]
    ) => {
        const headers = BULK_HEADERS.map((header) => header.header)

        const worksheet =
            tableData && tableData.length > 0
                ? utils.json_to_sheet(
                      tableData.map((data) => {
                          const obj = {} as Record<string, any>

                          for (const [key, value] of Object.entries(data)) {
                              const header = HEADERS.find(
                                  (h) => h.original === key
                              )?.header

                              if (header) {
                                  obj[header] = value.toString()
                              }
                          }

                          return obj as ProductionVolume
                      })
                  )
                : utils.aoa_to_sheet([headers])

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Volume de producao')

        writeFile(workbook, 'VolumeDeProducao.xlsx', {
            compression: true,
        })
    }

    const onReadData = async (sheetFile: File) => {
        const data = await sheetFile.arrayBuffer()
        const workbook = read(data)
        const sheet = workbook.Sheets[workbook.SheetNames[0]]

        const sheetJson =
            utils.sheet_to_json<CreateProductionVolumeBulkProps>(sheet)

        return sheetJson.map((sheetData) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(sheetData)) {
                const original = BULK_HEADERS.find(
                    (h) => h.header === key
                )?.original

                if (original) {
                    obj[original] = value.toString()
                }
            }

            return obj as CreateProductionVolumeBulkProps
        })
    }

    return {
        onDownload,
        onDownloadBulkModel,
        onReadData,
    }
}
