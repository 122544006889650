import { setItem } from '@/utils/storage'
import { ImmerStateCreator, Store } from '@/store/type'
import { AuthSlice, AuthStore } from './type'
import instance from '@/api/business'
import { AxiosError } from 'axios'

const initialState: AuthStore = {
    token: null,
    user: null,
    userId: null,
    tokenExpired: false,
}

export const useAuthSlice: ImmerStateCreator<AuthSlice> = (set, get) => ({
    state: initialState,
    actions: {
        login: (user) => {
            set((state) => {
                state.authSlice.state.user = user.user
                state.authSlice.state.token = user.token
                state.authSlice.state.userId = user.userId
                state.authSlice.state.tokenExpired = false
            })

            setItem(localStorage, 'token', user.token)

            instance.interceptors.response.use(
                (onSuccess) => onSuccess,
                (onError: AxiosError) => {
                    if (onError.response?.status === 401)
                        set((state) => {
                            state.authSlice.state.tokenExpired = true
                        })

                    throw onError
                }
            )
        },
        logout: (queryClient) => {
            set((state) => {
                state.authSlice.state.token = null
                state.authSlice.state.user = null
            })
            localStorage.removeItem('token')
            queryClient.clear()

            get().resetSlice.onResetAll()
        },
        resetAuthState: () =>
            set((state) => {
                state.authSlice.state = initialState
            }),
        onReset: () =>
            set((state) => {
                state.authSlice.state = initialState
            }),
    },
})

export const authSliceStateSelector = (state: Store) => state.authSlice.state

export const authSliceActionsSelector = (state: Store) =>
    state.authSlice.actions
