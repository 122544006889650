import { getIdFromDate } from '@/utils/date'
import { ImmerStateCreator, Store } from '../type'
import {
    ProductParent,
    ProductStructureSlice,
    ProductStructureStore,
} from './type'

const initialState: ProductStructureStore = {
    droppedProductStructure: [],
    selectedProductStructure: null,
    isEditProductStructureDialogOpen: false,
    isCopyStructureDialogOpen: false,
}

export const useProductStructureSlice: ImmerStateCreator<
    ProductStructureSlice
> = (set) => ({
    state: initialState,
    actions: {
        dropItem: (item) => {
            set((state) => {
                const prevState =
                    state.productStructureSlice.state.droppedProductStructure

                const hasCompany = prevState.find(
                    (company) => company.companyId === item.SK_EMPRESA
                )
                if (hasCompany) {
                    prevState.forEach((company) => {
                        if (company.companyId === item.SK_EMPRESA) {
                            if (!item.parentId) {
                                const newItem = item as ProductParent

                                const hasProduct =
                                    company.productStructures.find(
                                        (product) => product.id === newItem.id
                                    )

                                if (!hasProduct) {
                                    company.productStructures.push(newItem)
                                }
                            } else {
                                company.productStructures.forEach((product) => {
                                    const hasProduct = product.products.some(
                                        (prod) => prod.id === item.id
                                    )

                                    if (!hasProduct) {
                                        if (product.id === item.parentId) {
                                            if (item.products.length > 0) {
                                                product.products.push(
                                                    ...item.products
                                                        .filter(
                                                            (prod) =>
                                                                prod.id !==
                                                                    product.id &&
                                                                !product.products.some(
                                                                    (p) =>
                                                                        p.id ===
                                                                        prod.id
                                                                )
                                                        )
                                                        .map((newProd) => ({
                                                            ...newProd,
                                                            parentId:
                                                                product.id,
                                                        }))
                                                )
                                            } else {
                                                item.products = []
                                                product.products.push(item)
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    })
                } else {
                    prevState.push({
                        companyId: item.SK_EMPRESA,
                        companyName: item.ABREVIATURA_EMPRESA || '',
                        productStructures: [item as ProductParent],
                    })
                }
            })
        },
        copyStructure: (structure) => {
            set((state) => {
                const prevState =
                    state.productStructureSlice.state.droppedProductStructure

                const hasCompany = prevState.find(
                    (company) =>
                        company.companyId === structure.SK_EMPRESA.toString()
                )
                if (hasCompany) {
                    prevState.forEach((company) => {
                        if (
                            company.companyId ===
                            structure.SK_EMPRESA.toString()
                        ) {
                            company.productStructures.push(structure)
                        }
                    })
                } else {
                    prevState.push({
                        companyId: structure.SK_EMPRESA.toString(),
                        companyName: structure.ABREVIATURA_EMPRESA || '',
                        productStructures: [structure],
                    })
                }
            })
        },
        editItem: ({
            final,
            finalDate,
            initial,
            initialDate,
            quantity,
            parentId,
            productId,
            ownProduct,
        }) => {
            set((state) => {
                const prevState =
                    state.productStructureSlice.state.droppedProductStructure
                if (parentId) {
                    prevState.forEach((company) => {
                        company.productStructures.forEach((product) => {
                            if (product.id === parentId) {
                                product.products.forEach((prod) => {
                                    if (prod.id === productId) {
                                        prod.id = `${
                                            prod.DS_REVISAO_INICIAL || ''
                                        }${prod.DS_REVISAO_FINAL || ''}${
                                            prod.SK_PRODUTO
                                        }`
                                        prod.DS_REVISAO_INICIAL = initial
                                        prod.DS_REVISAO_FINAL = final
                                        prod.SK_TEMPO_INICIAL = Number(
                                            getIdFromDate(initialDate).join('')
                                        )
                                        prod.SK_TEMPO_FINAL = Number(
                                            getIdFromDate(finalDate).join('')
                                        )
                                        prod.QTD_COMPONENTE = quantity
                                        prod.DD_PRODUTO_PROPRIO = ownProduct
                                            ? 1
                                            : 0
                                    }
                                })
                            }
                        })
                    })
                } else {
                    prevState.map((company) => {
                        company.productStructures.map((product) => {
                            if (product.id === productId) {
                                product.products.forEach((prod) => {
                                    prod.id = `${
                                        prod.DS_REVISAO_INICIAL || ''
                                    }${prod.DS_REVISAO_FINAL || ''}${
                                        prod.SK_PRODUTO
                                    }`
                                    prod.DS_REVISAO_INICIAL = initial
                                    prod.DS_REVISAO_FINAL = final
                                    prod.SK_TEMPO_INICIAL = Number(
                                        getIdFromDate(initialDate).join('')
                                    )
                                    prod.SK_TEMPO_FINAL = Number(
                                        getIdFromDate(finalDate).join('')
                                    )
                                    prod.DD_PRODUTO_PROPRIO = ownProduct ? 1 : 0
                                })
                            }
                        })
                    })
                }
            })
        },
        selectItem: (productId, parentId) => {
            set((state) => {
                const prevState = state.productStructureSlice.state

                prevState.selectedProductStructure = { parentId, productId }
                prevState.isEditProductStructureDialogOpen = true
            })
        },
        removeItem: (productId, parentId) => {
            if (parentId) {
                set((state) => {
                    state.productStructureSlice.state.droppedProductStructure.forEach(
                        (company) => {
                            company.productStructures.map((product) => {
                                if (product.id === parentId) {
                                    product.products = product.products.filter(
                                        (prod) => prod.id !== productId
                                    )
                                }
                            })
                        }
                    )
                })
            } else {
                set((state) => {
                    const prevState = state.productStructureSlice.state

                    const filtered = prevState.droppedProductStructure
                        .map((company) => {
                            company.productStructures =
                                company.productStructures.filter(
                                    (product) => product.id !== productId
                                )

                            return company
                        })
                        .filter(
                            (company) => company.productStructures.length > 0
                        )
                    prevState.droppedProductStructure = filtered
                })
            }
        },
        onChangeEditProductStructureDialog: (isOpen) =>
            set((state) => {
                state.productStructureSlice.state.isEditProductStructureDialogOpen =
                    isOpen
            }),
        onChangeCopyProductStructureDialog: (isOpen) =>
            set((state) => {
                state.productStructureSlice.state.isCopyStructureDialogOpen =
                    isOpen
            }),
        setErrorOnProduct: (errors) => {
            set((state) => {
                const prevState = state.productStructureSlice.state

                prevState.droppedProductStructure.forEach((company) => {
                    company.productStructures.forEach((productStructure) => {
                        productStructure.products.forEach((product) => {
                            if (
                                errors.some(
                                    (error) =>
                                        error.parentId ===
                                            productStructure.id &&
                                        error.id === product.id
                                )
                            ) {
                                product.ERROR = true
                            }
                        })
                    })
                })
            })
        },
        resetErrors: () => {
            set((state) => {
                state.productStructureSlice.state.droppedProductStructure.forEach(
                    (company) => {
                        company.productStructures.forEach(
                            (productStructure) => {
                                productStructure.products.forEach((product) => {
                                    product.ERROR = false
                                })
                            }
                        )
                    }
                )
            })
        },
        onReset: () =>
            set((state) => {
                state.productStructureSlice.state = initialState
            }),
    },
})

export const productStructureStateSelector = (state: Store) =>
    state.productStructureSlice.state

export const productStructureActionsSelector = (state: Store) =>
    state.productStructureSlice.actions
