import { cn } from '@/lib/utils'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { currencyFormat } from '@/utils/stringFormatter'
import { Box, DollarSign } from 'lucide-react'
import { CostViewSelectOptions } from '../../../../../DefaultDetails/components/DetailsTab/components/Component'
import { useState } from 'react'
import { CostView } from '@/types/Product'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

interface ComponentProps {
    id: string
}

const componentSelector = (id: string) => (state: Store) =>
    state.stdCostSlice.state.stdCostData.components.entities[id]

const stateSelector = (state: Store) => ({
    selectedDate: state.stdCostSlice.state.selectedDate,
})
const actionsSelector = (state: Store) => ({
    onChangeCostView: state.stdCostSlice.actions.onChangeCostView,
})

const Component = ({ id }: ComponentProps) => {
    const component = useBaseStore(componentSelector(id))
    const { selectedDate } = useBaseStore(stateSelector)
    const { onChangeCostView } = useBaseStore(actionsSelector)

    const [selectedCostView, setSelectedCostView] = useState<CostView | ''>(
        component?.VISAO_SELECIONADA || ''
    )

    const isProduct = component.productUsedAsComponentId
    const isExtraComponent =
        component.ESTRUTURA === 'EXTRA-COMPONENTE' ||
        component.ESTRUTURA === 'EXTRA-PRODUTO'

    const isComponent = !isProduct && !isExtraComponent

    const onChangeSelectedCostView = (value: CostView) => {
        setSelectedCostView(value)

        if (!component) return

        onChangeCostView({
            productId: component._id,
            costView: value,
            dateId: selectedDate.id,
        })
    }

    if (!component) return null

    return (
        <div className="flex flex-col p-4 overflow-hidden border rounded-lg shadow-xs bg-card">
            <div
                className={cn(
                    'flex items-center gap-2',
                    isProduct && 'text-yellow-600',
                    isExtraComponent && 'text-purple-400'
                )}
            >
                {isProduct ? (
                    <Box size={14} />
                ) : isExtraComponent ? (
                    <DollarSign size={16} />
                ) : (
                    <Box size={14} />
                )}
                <TypographyP
                    className={cn(
                        'flex-1 overflow-hidden whitespace-nowrap text-ellipsis',
                        isProduct && 'text-yellow-600',
                        isExtraComponent && 'text-purple-400'
                    )}
                >
                    {component.PRODUTO_COMPONENTE}
                </TypographyP>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className="pl-6">
                    <TypographyMuted>Custo</TypographyMuted>
                    <TypographyP
                        className={cn(
                            'text-md',
                            component.VISAO_SELECIONADA ===
                                CostView.CUSTO_INFORMADO &&
                                component.CUSTO_SELECIONADO === 0
                                ? 'text-red-500'
                                : ''
                        )}
                    >
                        {currencyFormat(component.CUSTO_SELECIONADO, 6)}
                    </TypographyP>
                </div>
                {isComponent && (
                    <div>
                        <Select
                            onValueChange={(value) =>
                                onChangeSelectedCostView(value as CostView)
                            }
                            value={selectedCostView || ''}
                        >
                            <SelectTrigger
                                className={cn(
                                    'rounded-md border text-xs px-2 py-1.5 h-auto',
                                    component.VISAO_SELECIONADA ===
                                        CostView.CUSTO_INFORMADO
                                        ? 'border-emerald-500 text-emerald-500'
                                        : 'border-primary-600 text-primary-600'
                                )}
                            >
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    {CostViewSelectOptions.map((option) => {
                                        return (
                                            <SelectItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </SelectItem>
                                        )
                                    })}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Component
