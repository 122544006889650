import { useMemo } from 'react'
import Select from '../Select'
import { getMonthsFromRange, getYearsFromRange } from '@/utils/date'
import { format, isAfter, isBefore, lastDayOfMonth, setMonth } from 'date-fns'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { CalendarIcon } from 'lucide-react'
import { Button } from '../ui/button'
import { ptBR } from 'date-fns/locale'
import { cn } from '@/lib/utils'

interface DatePickerMonthWithRangeProps {
    className?: string
    setDate: ({ from, to }: { from: Date; to: Date }) => void
    date: { from: Date; to: Date }
    startMonthRange?: {
        start?: number
        end?: number
    }
    endMonthRange?: {
        start?: number
        end?: number
    }
    startYearRange?: {
        start?: number
        end?: number
    }
    endYearRange?: {
        start?: number
        end?: number
    }
}

const DatePickerMonthWithRange = ({
    className,
    date,
    startMonthRange,
    endMonthRange,
    startYearRange,
    endYearRange,
    setDate,
}: DatePickerMonthWithRangeProps) => {
    const startMonths = useMemo(
        () => getMonthsFromRange(startMonthRange?.start, startMonthRange?.end),
        [startMonthRange]
    )
    const endMonths = useMemo(
        () => getMonthsFromRange(endMonthRange?.start, endMonthRange?.end),
        [endMonthRange]
    )

    const startYears = useMemo(
        () => getYearsFromRange(startYearRange?.start, startYearRange?.end),
        [startYearRange]
    )
    const endYears = useMemo(
        () => getYearsFromRange(endYearRange?.start, endYearRange?.end),
        [endYearRange]
    )

    const start = isBefore(date.from, date.to) ? date.from : date.to
    const end = isAfter(date.to, date.from) ? date.to : date.from

    const onChange = ({ from, to }) => {
        let start = isBefore(from, to) ? from : to
        const end = isAfter(to, from) ? to : from

        if (!startMonths.some((month) => month.number === start.getMonth())) {
            start = setMonth(start, startMonths[0].number)
        }

        setDate({ from: start, to: end })
    }

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id="date"
                    variant={'outline'}
                    className={cn(
                        className,
                        'justify-start text-left font-normal',
                        !date && 'text-muted-foreground'
                    )}
                >
                    <CalendarIcon className="w-4 h-4 mr-2" />
                    {start ? (
                        end ? (
                            <>
                                {format(new Date(start), 'LLL/y', {
                                    locale: ptBR,
                                })}{' '}
                                até{' '}
                                {format(new Date(end), 'LLL/y', {
                                    locale: ptBR,
                                })}
                            </>
                        ) : (
                            format(new Date(start), 'LLL/y')
                        )
                    ) : (
                        <span>Selecione uma data</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <div className="flex gap-4 p-4">
                    <div className="flex-1 p-2 border rounded-md border-divider-color">
                        <label
                            htmlFor=""
                            className="block mb-1 text-sm text-disabled"
                        >
                            De:
                        </label>
                        <div className="flex items-center gap-2">
                            <Select
                                className="w-fit"
                                placeholder="Selecionar mês"
                                options={startMonths.map((month) => ({
                                    label: month.short,
                                    value: month.number.toString(),
                                }))}
                                onChange={(newValue) => {
                                    const from = new Date(
                                        start.getFullYear(),
                                        Number(newValue),
                                        1
                                    )

                                    onChange({
                                        from,
                                        to: end,
                                    })
                                }}
                                value={start.getMonth().toString()}
                            />
                            <Select
                                placeholder="Selecionar ano"
                                options={startYears.map((year) => ({
                                    label: year.long,
                                    value: year.long,
                                }))}
                                onChange={(newValue) => {
                                    const from = new Date(
                                        Number(newValue),
                                        start.getMonth(),
                                        1
                                    )

                                    onChange({
                                        from,
                                        to: end,
                                    })
                                }}
                                value={start.getFullYear().toString()}
                            />
                        </div>
                    </div>
                    <div className="flex-1 p-2 border rounded-md border-divider-color">
                        <label
                            htmlFor=""
                            className="block mb-1 text-sm text-disabled"
                        >
                            Até:
                        </label>
                        <div className="flex items-center gap-2">
                            <Select
                                className="w-fit"
                                placeholder="Selecionar mês"
                                options={endMonths.map((month) => ({
                                    label: month.short,
                                    value: month.number.toString(),
                                }))}
                                onChange={(newValue) => {
                                    const to = new Date(
                                        end.getFullYear(),
                                        Number(newValue),
                                        1
                                    )

                                    onChange({
                                        from: start,
                                        to: lastDayOfMonth(to),
                                    })
                                }}
                                value={end.getMonth().toString()}
                            />
                            <Select
                                placeholder="Selecionar ano"
                                options={endYears.map((year) => ({
                                    label: year.long,
                                    value: year.long,
                                }))}
                                onChange={(newValue) => {
                                    const to = new Date(
                                        Number(newValue),
                                        end.getMonth(),
                                        1
                                    )

                                    onChange({
                                        from: start,
                                        to: lastDayOfMonth(to),
                                    })
                                }}
                                value={end.getFullYear().toString()}
                            />
                        </div>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export default DatePickerMonthWithRange
