import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/components/ui/hover-card'
import { TypographyP } from '@/components/ui/typography'
import { getInitials } from '@/utils/stringFormatter'

interface HoverUserProps {
    username: string
    user: string
    email: string
}

export const HoverUser = ({ username, user, email }: HoverUserProps) => {
    return (
        <HoverCard>
            <HoverCardTrigger
                className="text-xs font-semibold cursor-pointer"
                asChild
            >
                <TypographyP>{user}</TypographyP>
            </HoverCardTrigger>
            <HoverCardContent className="border-none text-primary-800 w-fit">
                <div className="flex items-center justify-between space-x-4">
                    <Avatar>
                        <AvatarFallback className="text-white bg-primary-500">
                            {username && getInitials(username)}
                        </AvatarFallback>
                    </Avatar>
                    <div>
                        <TypographyP className="text-sm font-semibold">
                            {username}
                        </TypographyP>
                        <TypographyP>{email}</TypographyP>
                    </div>
                </div>
            </HoverCardContent>
        </HoverCard>
    )
}
