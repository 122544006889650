import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { Status, statusLocale, statusVariants } from '@/types/Status'
import { TooltipPortal } from '@radix-ui/react-tooltip'

type StructureStatusProps = {
    status: Status
    className?: string
}

const StructureStatus = ({
    status = 'PENDENTE',
    className,
}: StructureStatusProps) => {
    return (
        <TooltipProvider>
            <Tooltip delayDuration={300}>
                <TooltipTrigger asChild>
                    <div
                        className={cn(
                            'w-4 h-4 z-10 rounded-full mx-auto',
                            statusVariants({
                                status,
                            }),
                            className
                        )}
                    ></div>
                </TooltipTrigger>
                <TooltipPortal container={document.body}>
                    <TooltipContent>{statusLocale[status]}</TooltipContent>
                </TooltipPortal>
            </Tooltip>
        </TooltipProvider>
    )
}

export default StructureStatus
