import { utils, writeFile } from 'xlsx'
import { ProductStructureFlat } from '@/types/Product'
import { numberFormat } from '@/utils/stringFormatter'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'

const HEADERS: {
    original: keyof ProductStructureFlat
    header: string
}[] = [
    { original: 'NK_PRODUTO', header: 'COD_PRODUTO' },
    { original: 'PRODUTO_ESTRUTURA', header: 'PRODUTO' },
    { original: 'UM_PRODUTO_ESTRUTURA', header: 'UM PRODUTO' },
    { original: 'NK_COMPONENTE', header: 'COD_COMPONENTE' },
    { original: 'PRODUTO_COMPONENTE', header: 'COMPONENTE' },
    { original: 'UM_PRODUTO_COMPONENTE', header: 'UM COMPONENTE' },
    { original: 'SK_EMPRESA', header: 'COD_EMPRESA' },
    { original: 'ABREVIATURA_EMPRESA', header: 'EMPRESA' },
    { original: 'QTD_COMPONENTE', header: 'QTD COMPONENTE' },
    { original: 'DS_REVISAO_INICIAL', header: 'REVISAO INICIAL' },
    { original: 'DS_REVISAO_FINAL', header: 'REVISAO FINAL' },
    { original: 'SK_TEMPO_INICIAL', header: 'DATA REVISAO INICIAL' },
    { original: 'SK_TEMPO_FINAL', header: 'DATA REVISAO FINAL' },
]

export const useProductStructureSpreadsheet = () => {
    const getTableRows = (
        data: ProductStructureFlat[]
    ): Partial<ProductStructureFlat>[] =>
        data.map((product) => {
            const obj = {}

            for (const [key, value] of Object.entries(product)) {
                const header = HEADERS.find((h) => h.original === key)?.header

                if (header && value) {
                    if (key === 'QTD_COMPONENTE') {
                        obj[header] = numberFormat(value, 6)
                    } else if (
                        key === 'SK_TEMPO_INICIAL' ||
                        key === 'SK_TEMPO_FINAL'
                    ) {
                        obj[header] = format(getDateFromId(value), 'dd/MM/yyyy')
                    } else {
                        obj[header] = value.toString()
                    }
                }
            }

            return obj as ProductStructureFlat
        })

    const onDownload = (productStructure) => {
        const rows = getTableRows(productStructure)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Estrutura de produtos')

        writeFile(workbook, 'EstruturaDeProdutos.xlsx', {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
