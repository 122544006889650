import { isAxiosError } from '@/api/business'
import { approveStructure } from '@/api/business/structureStatus'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import useDisclosure from '@/hooks/useDisclosure'
import { costHistoryKeys } from '@/queries/useHistoryQuery'
import { structureStatusKeys } from '@/queries/useStructureStatusQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

type ApproveStructureDialogProps = {
    disabled: boolean
}

const ApproveStructureDialog = ({ disabled }: ApproveStructureDialogProps) => {
    const { id } = useParams()
    const { isOpen, onClose, onOpenChange } = useDisclosure()

    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading } = useMutation({
        mutationFn: approveStructure,
        onSuccess: () => {
            if (id) {
                queryClient.invalidateQueries(structureStatusKeys.detail(id))
                queryClient.invalidateQueries(costHistoryKeys.detail(id))
            }
            toast({
                title: 'Estrutura aprovada com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            const message = 'Erro ao aprovar estrutura'

            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao aprovar estrutura',
                        description: message,
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const onSubmit = () => {
        if (id) mutate({ id })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogTrigger asChild>
                <Button className="self-start" disabled={disabled}>
                    Aprovar
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Aprovar estrutura</DialogTitle>
                </DialogHeader>
                <div>
                    <TypographyP>
                        Realmente deseja aprovar a estrutura e seus custos?
                    </TypographyP>
                </div>
                <DialogFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button onClick={onSubmit} disabled={isLoading}>
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ApproveStructureDialog
