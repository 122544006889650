import { Skeleton } from '@/components/ui/skeleton'

const PageSkeleton = () => {
    return (
        <div className="p-4 space-y-2">
            <div className="space-y-1.5">
                <Skeleton className="w-[300px] h-4" />
                <Skeleton className="w-[200px] h-3" />
            </div>
            <div className="space-y-1.5">
                <div className="flex items-center gap-4 p-3 border rounded-lg">
                    <Skeleton className="w-5 h-5 rounded-full" />
                    <Skeleton className="w-[320px] h-4" />
                </div>
                <div className="flex items-center gap-4 p-3 border rounded-lg">
                    <Skeleton className="w-5 h-5 rounded-full" />
                    <Skeleton className="w-[320px] h-4" />
                </div>
                <div className="flex items-center gap-4 p-3 border rounded-lg">
                    <Skeleton className="w-5 h-5 rounded-full" />
                    <Skeleton className="w-[320px] h-4" />
                </div>
                <div className="flex items-center gap-4 p-3 border rounded-lg">
                    <Skeleton className="w-5 h-5 rounded-full" />
                    <Skeleton className="w-[320px] h-4" />
                </div>
            </div>
        </div>
    )
}

export default PageSkeleton
