import {
    AlterationType,
    MudancaCusto,
} from '@/api/business/structureStatus/type'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { statusLocale } from '@/types/Status'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { format, parseISO } from 'date-fns'

type CostDetailDialogProps = {
    isOpen: boolean
    item: MudancaCusto
    onClose: () => void
}

const ALTERATION_STATUS: Record<AlterationType, string> = {
    ADICAO: 'Adição',
    STATUS_ALTERADO: 'Status alterado',
    EDICAO: 'Edição',
    EXCLUSAO: 'Exclusão',
} as const

const CostDetailDialog = ({ isOpen, item, onClose }: CostDetailDialogProps) => {
    const {
        DD_TIPO_CUSTO,
        TIPO_ALTERACAO,
        PRODUTO_ESTRUTURA,
        PRODUTO_COMPONENTE,
        VL_CUSTO_ORCADO,
        VL_ALIQUOTA_COFINS,
        VL_ALIQUOTA_FCP,
        VL_ALIQUOTA_ICMS,
        VL_ALIQUOTA_IPI,
        VL_ALIQUOTA_PIS,
        VL_ALIQUOTA_ST,
        VL_COFINS,
        VL_FCP,
        VL_ICMS,
        VL_IPI,
        VL_PIS,
        VL_ST,
        NM_USUARIO,
        DS_STATUS,
        DS_CUSTO,
        DS_MOEDA,
        DD_EMAIL,
        CREATED_AT,
    } = item

    const isExtraCost = DD_TIPO_CUSTO.includes('EXTRA')

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[680px]">
                <DialogHeader>
                    <DialogTitle>
                        {isExtraCost ? 'Custo extra' : 'Custo informado'}
                    </DialogTitle>
                </DialogHeader>
                <div className="space-y-2">
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">Produto: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {PRODUTO_ESTRUTURA}
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">Componente: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {PRODUTO_COMPONENTE}
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">Descrição: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {DS_CUSTO || '-'}
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">
                                Tipo de custo:{' '}
                            </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {isExtraCost ? 'Custo extra' : 'Custo informado'}
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">Valor: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {currencyFormat(
                                Number(VL_CUSTO_ORCADO),
                                4,
                                DS_MOEDA
                            )}
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">Status: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {statusLocale[DS_STATUS]}
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">Ação: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {ALTERATION_STATUS[TIPO_ALTERACAO]}
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">
                                Executado por:{' '}
                            </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {NM_USUARIO} ({DD_EMAIL})
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">
                                Data execução:{' '}
                            </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {format(
                                parseISO(CREATED_AT),
                                'dd/MM/yyyy, HH:mm:ss'
                            )}
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">Cofins: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {currencyFormat(Number(VL_ALIQUOTA_COFINS))}
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            ({percentageFormat(Number(VL_COFINS))})
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">FCP: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {currencyFormat(Number(VL_ALIQUOTA_FCP))}
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            ({percentageFormat(Number(VL_FCP))})
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">ICMS: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {currencyFormat(Number(VL_ALIQUOTA_ICMS))}
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            ({percentageFormat(Number(VL_ICMS))})
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">PIS: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {currencyFormat(Number(VL_ALIQUOTA_PIS))}
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            ({percentageFormat(Number(VL_PIS))})
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">ST: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {currencyFormat(Number(VL_ALIQUOTA_ST))}
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            ({percentageFormat(Number(VL_ST))})
                        </TypographyP>
                    </div>
                    <div className="flex items-center gap-2">
                        <TypographyP asChild>
                            <span className="font-semibold">IPI: </span>
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            {currencyFormat(Number(VL_ALIQUOTA_IPI))}
                        </TypographyP>
                        <TypographyP className="text-muted-foreground">
                            ({percentageFormat(Number(VL_IPI))})
                        </TypographyP>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default CostDetailDialog
