import ConfirmDialog from '@/components/ConfirmDialog'
import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button } from '@/components/ui/button'
import { createColumnHelper } from '@tanstack/react-table'
import { TableData } from '@/components/Table/type'
import useBulkDialog, { BulkDialogActionNames } from './useBulkDialog'
import { ChangeEvent, useMemo } from 'react'
import { ArrowUpToLine, Download, ListPlus, Paintbrush, X } from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import { isAxiosError } from '@/api/business'
import { useProductionVolumeSpreadsheet } from '../../useProductionVolumeSpreadsheet'
import { CreateProductionVolumeBulkProps } from '@/api/business/productionVolume/types'
import { productionVolumeKeys } from '@/queries/useProductionVolumeQuery'
import { createProductionVolumeBulk } from '@/api/business/productionVolume'
import { cn } from '@/lib/utils'
import Table from '@/components/Table'
interface BulkDialogProps {
    isOpen: boolean
    onClose: () => void
}

export type BulkData = CreateProductionVolumeBulkProps & {
    STATUS?: number
    MENSAGEM?: string
    DS_PRODUTO?: string
    id: string
}

type BulkTable = TableData<BulkData>

const columnHelper = createColumnHelper<BulkData>()

const BulkDialog = ({ isOpen, onClose }: BulkDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { tableData, feedbackResponse, productsLoading, dispatch } =
        useBulkDialog()
    const { onDownloadBulkModel, onReadData } = useProductionVolumeSpreadsheet()

    const {
        isOpen: isFeedbackDialogOpen,
        onClose: onFeedbackDialogClose,
        onOpen: onFeedbackDialogOpen,
    } = useDisclosure()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createProductionVolumeBulk,
        onSuccess: (data) => {
            queryClient.invalidateQueries(productionVolumeKeys.lists())
            dispatch({
                type: BulkDialogActionNames.SET_DATA_STATUS,
                payload: data,
            })
            dispatch({
                type: BulkDialogActionNames.RESET,
            })
            onFeedbackDialogOpen()
            toast({
                title: 'Carga realizada com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao realizar carga',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const dataMemo = useMemo(() => tableData, [tableData])
    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    return (
                        <div className="flex items-center justify-center h-full p-0 text-red-500">
                            <button
                                onClick={() =>
                                    dispatch({
                                        type: BulkDialogActionNames.REMOVE_ROW,
                                        payload: { id: row.original.id },
                                    })
                                }
                            >
                                <X size={16} />
                            </button>
                        </div>
                    )
                },
                size: 35,
                enableResizing: false,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                header: 'Cod Empresa',
                size: 90,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('NK_PRODUTO', {
                header: 'Cod Produto',
                size: 90,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                cell: ({ getValue }) => {
                    return (
                        <div
                            className={cn('p-1', !getValue() && 'text-red-500')}
                        >
                            {getValue() ? getValue() : 'Produto não encontrado'}
                        </div>
                    )
                },
                size: 200,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('QTD_VOLUME', {
                header: 'Volume',
                size: 90,
                meta: {
                    enableColumnEditable: true,
                },
            }),
            columnHelper.accessor('SK_TEMPO', {
                header: 'Data',
                size: 90,
                meta: {
                    enableColumnEditable: true,
                },
            }),
        ],
        []
    )

    const onSubmit = () => {
        mutate(tableData.filter((data) => data.NK_PRODUTO && data.SK_EMPRESA))
    }

    const onChangeFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files
        if (file && file[0]) {
            const data = await onReadData(file[0])
            dispatch({
                type: BulkDialogActionNames.SET_TABLE_DATA,
                payload: data,
            })
        }
    }

    return (
        <>
            <ConfirmDialog
                className="flex flex-col h-full max-w-full overflow-hidden sm:rounded-none"
                title="Carga volume de produção"
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={onSubmit}
                isLoading={mutateLoading}
            >
                <div className="flex flex-col flex-1 w-full py-6 space-y-4 overflow-auto">
                    <div className="flex items-center justify-end gap-2">
                        {dataMemo.length > 0 && (
                            <Button
                                className="flex items-center justify-center w-10 h-10 p-0 bg-indigo-500 rounded-md"
                                onClick={() =>
                                    dispatch({
                                        type: BulkDialogActionNames.ADD_ROW,
                                    })
                                }
                            >
                                <ListPlus size={16} />
                            </Button>
                        )}
                        <Button
                            className="flex items-center justify-center w-10 h-10 p-0 rounded-md bg-amber-500"
                            onClick={() =>
                                dispatch({
                                    type: BulkDialogActionNames.RESET,
                                })
                            }
                        >
                            <Paintbrush size={16} />
                        </Button>
                        <label
                            htmlFor="file"
                            className={cn(
                                'flex items-center justify-center w-10 h-10 p-0 text-white rounded-md',
                                productsLoading
                                    ? 'bg-neutral-300'
                                    : 'bg-primary-600 cursor-pointer'
                            )}
                            onClick={(e) => {
                                if (productsLoading) {
                                    return e.preventDefault()
                                }
                            }}
                        >
                            <ArrowUpToLine size={16} />
                        </label>
                        <input
                            className="hidden"
                            id="file"
                            type="file"
                            onChange={onChangeFileInput}
                        />
                        <Button
                            className="flex items-center justify-center w-10 h-10 p-0 rounded-md bg-emerald-500"
                            onClick={() => onDownloadBulkModel(tableData)}
                        >
                            <Download size={16} />
                        </Button>
                    </div>
                    <div className="flex-1 w-full overflow-hidden">
                        {dataMemo.length > 0 && columnsMemo ? (
                            <Table<BulkTable>
                                data={dataMemo}
                                columns={columnsMemo}
                                meta={{
                                    cell: {
                                        className: 'px-0 py-0 items-center',
                                    },
                                    updateData: (rowIdx, columnId, value) => {
                                        dispatch({
                                            type: BulkDialogActionNames.UPDATE_TABLE_DATA,
                                            payload: {
                                                rowIdx: rowIdx as number,
                                                columnId,
                                                value,
                                            },
                                        })
                                    },
                                }}
                            />
                        ) : (
                            <div className="flex justify-center w-full">
                                <p>Nenhum dado importado</p>
                            </div>
                        )}
                    </div>
                </div>
            </ConfirmDialog>
            {isFeedbackDialogOpen && (
                <ConfirmDialog
                    title="Resposta"
                    className="max-w-3xl"
                    isOpen={isFeedbackDialogOpen}
                    onClose={onFeedbackDialogClose}
                    onConfirm={() => onFeedbackDialogClose()}
                >
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th>Produto</th>
                                <th>Mensagem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feedbackResponse.map((feedback, idx) => (
                                <tr key={idx}>
                                    <td>{feedback.PRODUTO}</td>
                                    <td>{feedback.BODY}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ConfirmDialog>
            )}
        </>
    )
}

export default BulkDialog
