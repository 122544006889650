import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import ProductsList from './components/ProductsList'
import ProductStructureDropzone from './components/ProductStructureDropzone'
import EditProductDialog from './components/EditProductDialog'
import { createProductStructure } from '@/api/business/productStructure'
import { productStructureKeys } from '@/queries/useProductStructureQuery'
import { isAxiosError } from '@/api/business'
import { Button } from '@/components/ui/button'
import CopyStructureDialog from './components/CopyStructureDialog'
import { useEffect } from 'react'
import { CreateProductStructureResponse } from '@/api/business/productStructure/types'

import { useBaseStore } from '@/store'
import { Store } from '@/store/type'

interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const stateSelector = (state: Store) => ({
    productStructures:
        state.productStructureSlice.state.droppedProductStructure,
    setErrors: state.productStructureSlice.actions.setErrorOnProduct,
    onResetProductStructure: state.productStructureSlice.actions.onReset,
    resetErrors: state.productStructureSlice.actions.resetErrors,
})

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const {
        productStructures,
        onResetProductStructure,
        setErrors,
        resetErrors,
    } = useBaseStore(stateSelector)

    const {
        mutate,
        isLoading: mutateLoading,
        isSuccess,
    } = useMutation({
        mutationFn: createProductStructure,
        onSuccess: () => {
            queryClient.invalidateQueries(productStructureKeys.lists())

            toast({
                title: 'Custo adicionado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else if (err.response?.status === 409) {
                    const data = err.response
                        ?.data as CreateProductStructureResponse[]

                    toast({
                        title: 'Conflito',
                        description:
                            'Não foi possível criar a estrutura de dados, pois há produtos já existentes nessa estrutura.',
                        variant: 'destructive',
                    })

                    setErrors(
                        data.map((error) => ({
                            id: error.SK_PRODUTO_COMPONENTE,
                            parentId: error.SK_PRODUTO_APONTADO,
                        }))
                    )
                } else {
                    toast({
                        title: 'Erro ao criar estrutura de produto',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const onSubmit = () => {
        console.log(productStructures)
        const productStructuresFlat = productStructures
            .flatMap((productStructure) => {
                return productStructure.productStructures
            })
            .flatMap((product) => {
                const { SK_PRODUTO, products } = product

                return products.map((prod) => ({
                    SK_EMPRESA: prod.SK_EMPRESA!,
                    SK_PRODUTO_APONTADO: SK_PRODUTO!,
                    SK_PRODUTO_COMPONENTE: prod.SK_PRODUTO!,
                    QTD_COMPONENTE: prod.QTD_COMPONENTE || '0',
                    SK_TEMPO_INICIAL: prod.SK_TEMPO_INICIAL!,
                    DS_REVISAO_INICIAL: prod.DS_REVISAO_INICIAL || '',
                    SK_TEMPO_FINAL: prod.SK_TEMPO_FINAL!,
                    DS_REVISAO_FINAL: prod.DS_REVISAO_FINAL || '',
                    DD_PRODUTO_PROPRIO:
                        prod.DD_PRODUTO_PROPRIO === 1
                            ? (1 as const)
                            : (0 as const),
                }))
            })

        if (
            productStructuresFlat.some(
                (product) =>
                    !product.SK_TEMPO_INICIAL ||
                    !product.SK_TEMPO_FINAL ||
                    !product.QTD_COMPONENTE ||
                    !product.SK_PRODUTO_APONTADO ||
                    !product.SK_PRODUTO_COMPONENTE
            )
        ) {
            toast({
                title: 'Preencha todos os campos',
                variant: 'destructive',
            })
        } else {
            resetErrors()
            mutate({
                COMPONENTES: productStructuresFlat,
            })
        }
    }

    useEffect(() => {
        if (isSuccess) {
            onResetProductStructure()
        }
    }, [isSuccess])

    return (
        <>
            <Dialog
                open={isOpen}
                onOpenChange={() => {
                    onResetProductStructure()
                    onClose()
                }}
            >
                <DialogContent className="md:max-w-full grid-rows-[min-content_1fr] md:rounded-none sm:h-full">
                    <DialogHeader>
                        <DialogTitle>Criar estrutura</DialogTitle>
                        {/* <DialogDescription>aa</DialogDescription> */}
                    </DialogHeader>
                    <div className="flex h-full overflow-hidden">
                        <div className="flex-1 h-full p-2 overflow-hidden">
                            <ProductStructureDropzone isOpen={isOpen} />
                        </div>
                        <div className="flex-1 h-full p-2 overflow-hidden">
                            <ProductsList isOpen={isOpen} />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button
                            className="w-[100px] rounded-md"
                            disabled={mutateLoading}
                            onClick={() => onSubmit()}
                        >
                            Salvar
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <EditProductDialog />
            <CopyStructureDialog />
        </>
    )
}

export default AddDialog
