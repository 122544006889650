import { useStructureStatusByIdQuery } from '@/queries/useStructureStatusQuery'
import { useParams } from 'react-router-dom'
import NotFound from '../NotFound'
import { ReactNode, useMemo } from 'react'
import { TypographyH2, TypographyP } from '@/components/ui/typography'
import PageSkeleton from './components/PageSkeleton'
import ProductExtraCostTable from './components/ProductExtraCostTable'
import { Separator } from '@/components/ui/separator'
import StructureDropdown from './components/StructureDropdown'
import InformedCostDropdown from './components/InformedCostDropdown'
import ExtraCostDropdown from './components/ExtraCostDropdown'
import ApproveStructureDialog from './components/ApproveStructureDialog'
import { isAxiosError } from '@/api/business'
import ComponentsTable from './components/ComponentsTable'
import HistorySection from './components/HistorySection'

type ValidateProps = {
    children: ReactNode
}

const Validate = ({ children }: ValidateProps) => {
    const { id } = useParams()

    if (!id) return <NotFound />

    return children
}

const ProductStructureStatusDetail = () => {
    const { id } = useParams()

    const { data, isLoading, isError, error } = useStructureStatusByIdQuery({
        id: id!,
    })

    const productExtraCosts = useMemo(() => {
        if (data) {
            return data.CUSTOS.EXTRA.filter(
                (cost) => cost.DD_ORIGEM_DADO === 'MANUAL'
            )
        }
        return []
    }, [data])

    if (isLoading || isError) {
        const errorMessage = isAxiosError(error)
            ? error.response?.data.message
            : 'Houve um erro ao buscar estrutura'

        return (
            <>
                {error && (
                    <div className="text-center">
                        <TypographyP className="p-4 text-red-500">
                            {errorMessage}
                        </TypographyP>
                    </div>
                )}
                <PageSkeleton />
            </>
        )
    }

    if (!data) return <NotFound />

    const scenes = [
        data.CENARIO_CUSTO_INFORMADO,
        data.CENARIO_ESTRUTURA,
        data.CENARIO_CUSTO_EXTRA,
    ]

    const disabled =
        scenes.some(
            (status) => status === 'PENDENTE' || status === 'ELABORACAO'
        ) || scenes.every((scene) => scene === 'APROVADO')

    return (
        <Validate>
            <div className="flex h-full overflow-hidden divide-x-[1px]">
                <div className="flex-1 p-4 space-y-4 overflow-x-hidden overflow-y-auto">
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <TypographyH2 className="flex items-center gap-2 border-none text-primary">
                                    {data.NK_PRODUTO_ESTRUTURA} -{' '}
                                    {data.DS_PRODUTO_ESTRUTURA}{' '}
                                    {data.DD_BLOQUEADO && (
                                        <span className="p-1 text-xs text-red-500 rounded-sm bg-red-50">
                                            Bloqueado
                                        </span>
                                    )}
                                </TypographyH2>
                                <TypographyP>
                                    {data.ABREVIATURA_EMPRESA}
                                </TypographyP>
                            </div>
                            <ApproveStructureDialog disabled={disabled} />
                        </div>
                        <Separator className="mt-4" />
                    </div>
                    <div className="flex gap-4">
                        <StructureDropdown status={data.CENARIO_ESTRUTURA} />
                        <InformedCostDropdown
                            status={data.CENARIO_CUSTO_INFORMADO}
                        />
                        <ExtraCostDropdown status={data.CENARIO_CUSTO_EXTRA} />
                    </div>
                    <div className="overflow-hidden">
                        <TypographyH2>Componentes</TypographyH2>
                        <div className="h-[800px] py-4 overflow-hidden ">
                            <ComponentsTable data={data} />
                        </div>
                    </div>
                    <div className="flex flex-col overflow-hidden">
                        <TypographyH2>Custo extra do produto</TypographyH2>
                        {productExtraCosts.length === 0 ? (
                            <div className="py-4">
                                <TypographyP className="text-muted-foreground">
                                    Não há custo extra para esse produto
                                </TypographyP>
                            </div>
                        ) : (
                            <div className="h-[360px] py-4 overflow-hidden">
                                <ProductExtraCostTable
                                    costs={productExtraCosts}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <HistorySection />
            </div>
        </Validate>
    )
}

export default ProductStructureStatusDetail
