import { ProductCustomEntity } from '@/types/StandardCost'
import { currencyFormat } from '@/utils/stringFormatter'
import { useState } from 'react'
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'

type CompositionPieChartProps = {
    product: ProductCustomEntity
    baseValue: number
    expensesValue: number
}

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
    } = props

    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const textAnchor = cos >= 0 ? 'start' : 'end'
    const labelRadius = outerRadius + 20

    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1)
    const ey = my

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={cx}
                y={cy - labelRadius + 6}
                dy={-18}
                textAnchor="middle"
                fill={fill}
                className="text-xs font-semibold"
            >
                {payload.name}
            </text>
            <text
                className="text-xs"
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
            >
                {`${currencyFormat(value, 6)}`}
            </text>
            <text
                className="text-xs"
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
            >
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    )
}

const CompositionPieChart = ({
    baseValue,
    expensesValue,
}: CompositionPieChartProps) => {
    const [activeIdx, setActiveIdx] = useState(0)
    const colors = ['#67c3d7', '#c49eea']

    const onPieEnter = (_: any, idx: number) => {
        setActiveIdx(idx)
    }

    return (
        <ResponsiveContainer>
            <PieChart>
                <Pie
                    dataKey="value"
                    data={[
                        {
                            value: baseValue,
                            name: 'Matéria prima',
                            fill: colors[0],
                        },
                        {
                            value: expensesValue,
                            name: 'Rateios',
                            fill: colors[1],
                        },
                    ]}
                    activeIndex={activeIdx}
                    activeShape={renderActiveShape}
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={45}
                    fill="#15a6c6"
                    onMouseEnter={onPieEnter}
                />
            </PieChart>
        </ResponsiveContainer>
    )
}

export default CompositionPieChart
