import {
    Box,
    Boxes,
    DollarSign,
    Pencil,
    RotateCcw,
    SquareArrowOutUpRight,
} from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import TabSection from '../../../../../TabSection'
import { currencyFormat, numberFormat } from '@/utils/stringFormatter'
import { format } from 'date-fns'
import { getDateFromId } from '@/utils/date'
import { useProductionVolumeQuery } from '@/queries/useProductionVolumeQuery'
import { useBaseStore } from '@/store'
import {
    getProductByIdSelector,
    stdCostSliceActionsSelector,
    stdCostSliceStateSelector,
} from '@/store/stdCostSlice'
import { TypographyP } from '@/components/ui/typography'
import { statusLocale, statusTextVariants } from '@/types/Status'

interface ProductProps {
    productId: string
}

const LIST_SIZE = 5

const Product = ({ productId }: ProductProps) => {
    const product = useBaseStore(getProductByIdSelector(productId))

    const {
        stdCostData: { components },
    } = useBaseStore(stdCostSliceStateSelector)

    const {
        onChangeStdCostQuantity: onChangeQuantity,
        onSelectStdCostRow: selectRow,
    } = useBaseStore(stdCostSliceActionsSelector)

    const [showAllProductionVolume, setShowAllProductionVolume] =
        useState(false)
    const [showAllComponents, setShowAllComponents] = useState(false)
    const [isQuantityEditable, setIsQuantityEditable] = useState(false)
    const [quantity, setQuantity] = useState(0)

    const inputRef = useRef<HTMLInputElement | null>(null)

    const {
        data: productionVolumeData,
        isLoading: productionVolumeLoading,
        isError: isProductionVolumeError,
    } = useProductionVolumeQuery()

    const productComponents = product.COMPONENTES.map(
        (id) => components.entities[id]
    )

    const productProductionVolume = useMemo(
        () =>
            productionVolumeData?.filter(
                (production) =>
                    production.SK_PRODUTO === product.SK_PRODUTO_ESTRUTURA
            ) || [],
        [productionVolumeData, product]
    )

    useEffect(() => {
        if (isQuantityEditable) {
            inputRef.current?.focus()
        }
    }, [isQuantityEditable])

    useEffect(() => {
        setShowAllComponents(false)
        setShowAllProductionVolume(false)
        setQuantity(
            product?.PRODUTO_QTD_SELECIONADA ||
                product?.QTD_PRODUCAO_APONTADA ||
                0
        )
    }, [product])

    return (
        <>
            <TabSection title="Produto">
                {product.PRODUTO_ESTRUTURA && (
                    <div className="flex items-center gap-2">
                        <Boxes
                            size={20}
                            strokeWidth={1.5}
                            className="text-primary-700 dark:text-primary"
                        />
                        <TypographyP className="flex-1 overflow-hidden text-primary-700 dark:text-primary whitespace-nowrap text-ellipsis">
                            {product.PRODUTO_ESTRUTURA}
                        </TypographyP>
                    </div>
                )}
            </TabSection>
            <TabSection
                title="Cenários"
                actionButton={
                    <button
                        onClick={() =>
                            open(
                                window.origin +
                                    `/dados-mestre/estrutura-produto/status/${product.SK_PRODUTO_ESTRUTURA}`
                            )
                        }
                    >
                        <SquareArrowOutUpRight size={14} />
                    </button>
                }
            >
                <TypographyP className="text-accent-foreground">
                    Estrutura:{' '}
                    <span
                        className={statusTextVariants({
                            status: product.CENARIO_ESTRUTURA,
                        })}
                    >
                        {statusLocale[product.CENARIO_ESTRUTURA]}
                    </span>
                </TypographyP>
                <TypographyP className="text-accent-foreground">
                    Custo informado:{' '}
                    <span
                        className={statusTextVariants({
                            status: product.CENARIO_CUSTO_INFORMADO,
                        })}
                    >
                        {statusLocale[product.CENARIO_CUSTO_INFORMADO]}
                    </span>
                </TypographyP>
                <TypographyP className="text-accent-foreground">
                    Custo extra:{' '}
                    <span
                        className={statusTextVariants({
                            status: product.CENARIO_CUSTO_EXTRA,
                        })}
                    >
                        {statusLocale[product.CENARIO_CUSTO_EXTRA]}
                    </span>
                </TypographyP>
            </TabSection>
            <TabSection title="Custo padrão">
                <div className="flex items-center justify-center">
                    <TypographyP>
                        {product
                            ? currencyFormat(product.CUSTO_SELECIONADO, 6)
                            : 0}
                    </TypographyP>
                </div>
            </TabSection>
            <TabSection title="Custo padrão total">
                <div className="flex items-center justify-center">
                    <TypographyP>
                        {product
                            ? currencyFormat(product.CUSTO_PADRAO_TOTAL, 6)
                            : 0}
                    </TypographyP>
                </div>
            </TabSection>
            <TabSection
                title={
                    <TypographyP className="font-semibold">
                        Qtde real
                        <span className="px-2 py-0.5 bg-accent ml-2 text-xs font-normal rounded-lg text-primary-600 dark:text-primary">
                            simulação
                        </span>
                    </TypographyP>
                }
                actionButton={
                    <div className="space-x-4">
                        {product?.PRODUTO_QTD_SELECIONADA ? (
                            <button
                                onClick={() =>
                                    onChangeQuantity({
                                        isProduct: true,
                                        id: product._id,
                                        quantity: null,
                                    })
                                }
                            >
                                <RotateCcw size={14} />
                            </button>
                        ) : null}
                        <button
                            onClick={() => {
                                setIsQuantityEditable(true)
                            }}
                        >
                            <Pencil size={12} />
                        </button>
                    </div>
                }
            >
                <div className="flex items-center justify-center">
                    {isQuantityEditable ? (
                        <input
                            ref={inputRef}
                            className="w-full h-full py-1 text-sm text-center bg-transparent border rounded-md text-primary"
                            type="number"
                            value={quantity}
                            onChange={(e) =>
                                setQuantity(Number(e.target.value))
                            }
                            onBlur={() => {
                                if (!product) return
                                setIsQuantityEditable(false)
                                onChangeQuantity({
                                    isProduct: true,
                                    id: product._id,
                                    quantity: Number(quantity),
                                })
                            }}
                        />
                    ) : (
                        <TypographyP>
                            {product ? numberFormat(quantity, 0) : 0}
                        </TypographyP>
                    )}
                </div>
            </TabSection>
            <TabSection title="Qtde real">
                <div className="flex items-center justify-center">
                    <TypographyP>
                        {product
                            ? numberFormat(product.QTD_PRODUCAO_APONTADA, 0)
                            : 0}
                    </TypographyP>
                </div>
            </TabSection>
            <TabSection title="Volume de produção">
                {productionVolumeLoading ? (
                    <TypographyP>Carregando...</TypographyP>
                ) : isProductionVolumeError ? (
                    <TypographyP className="text-red-500">
                        Erro ao carregar volume de produção
                    </TypographyP>
                ) : productProductionVolume.length > 0 ? (
                    <>
                        <table>
                            <thead>
                                <tr className="text-left text-primary-700 dark:text-primary">
                                    <th className="px-4">Data</th>
                                    <th className="px-4">Volume</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productProductionVolume
                                    .slice(
                                        0,
                                        showAllProductionVolume
                                            ? productProductionVolume.length
                                            : LIST_SIZE
                                    )
                                    .map((product) => (
                                        <tr key={product.SK_VOLUME_PRODUCAO}>
                                            <td className="px-4">
                                                {format(
                                                    getDateFromId(
                                                        product.SK_TEMPO
                                                    ),
                                                    'MM/yyyy'
                                                )}
                                            </td>
                                            <td className="px-4">
                                                {Intl.NumberFormat(
                                                    'pt-BR'
                                                ).format(
                                                    Number(product.QTD_VOLUME)
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        {productProductionVolume.length > LIST_SIZE && (
                            <div className="flex justify-end py-2">
                                <button
                                    className="text-primary-700 hover:underline dark:text-primary"
                                    onClick={() =>
                                        setShowAllProductionVolume(
                                            (prev) => !prev
                                        )
                                    }
                                >
                                    {showAllProductionVolume
                                        ? 'Mostrar menos'
                                        : 'Mostrar tudo'}
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <TypographyP className="text-primary-700 dark:text-primary">
                        Não há volume informado para esse produto
                    </TypographyP>
                )}
            </TabSection>
            <TabSection title="Componentes">
                <>
                    {productComponents.length > 0 ? (
                        productComponents
                            .slice(
                                0,
                                showAllComponents
                                    ? productComponents.length
                                    : LIST_SIZE
                            )
                            .map((component) => {
                                const isExtraCost =
                                    component.ESTRUTURA !== 'PADRAO'

                                return (
                                    <div
                                        key={component._id}
                                        className="flex items-center gap-2 mb-2 cursor-pointer hover:underline"
                                        onClick={() => {
                                            selectRow({
                                                id: component._id,
                                                isProduct: false,
                                            })
                                        }}
                                    >
                                        {isExtraCost ? (
                                            <DollarSign size={16} />
                                        ) : (
                                            <Box size={16} strokeWidth={2} />
                                        )}
                                        <TypographyP
                                            key={
                                                component.SK_PRODUTO_COMPONENTE
                                            }
                                            className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis"
                                        >
                                            {component.PRODUTO_COMPONENTE}
                                        </TypographyP>
                                    </div>
                                )
                            })
                    ) : (
                        <p>Nenhum componente</p>
                    )}
                    {product.COMPONENTES.length > LIST_SIZE && (
                        <div className="flex justify-end py-2">
                            <button
                                className="text-primary-700 hover:underline dark:text-primary"
                                onClick={() =>
                                    setShowAllComponents((prev) => !prev)
                                }
                            >
                                {showAllComponents
                                    ? 'Mostrar menos'
                                    : 'Mostrar tudo'}
                            </button>
                        </div>
                    )}
                </>
            </TabSection>
        </>
    )
}

export default Product
