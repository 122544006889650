import TabSection from '../../../../../TabSection'
import { DollarSign } from 'lucide-react'
import { percentageFormat } from '@/utils/stringFormatter'
import { IdToRemove } from '../..'
import { ComponentEntity } from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'
import { TypographyP } from '@/components/ui/typography'

interface ExtraCostProps {
    component: ComponentEntity
    onRemoveCostDialogOpen: () => void
    setIdToRemove: (props: IdToRemove) => void
}

const ExtraCost = ({
    component,
    onRemoveCostDialogOpen,
    setIdToRemove,
}: ExtraCostProps) => {
    const {
        stdCostData: { components, products },
    } = useBaseStore(stdCostSliceStateSelector)

    return (
        <>
            <TabSection title={'Produto'}>
                <TypographyP className="flex items-center gap-2 ">
                    <DollarSign size={16} />
                    <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                        {component.PRODUTO_COMPONENTE}
                    </span>
                </TypographyP>
            </TabSection>
            <TabSection title="Tipo">
                <TypographyP className="text-center text-purple-500 dark:text-purple-400">
                    Custo extra
                </TypographyP>
            </TabSection>{' '}
            <TabSection title="Impostos">
                <table className="">
                    <thead className="text-left">
                        <tr>
                            <th className="px-4 w-[100px]">
                                <TypographyP className="text-sm font-semibold text-primary-600">
                                    Descrição
                                </TypographyP>
                            </th>
                            <th className="px-4 w-[100px]">
                                <TypographyP className="text-sm font-semibold text-primary-600">
                                    Valor
                                </TypographyP>
                            </th>
                            <th className="px-4 w-[100px]">
                                <TypographyP className="text-sm font-semibold text-primary-600">
                                    Aliquota
                                </TypographyP>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP>PIS</TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {component.VL_PIS || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {percentageFormat(
                                        Number(component.VL_ALIQUOTA_PIS) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP>COFINS</TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {component.VL_COFINS || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {percentageFormat(
                                        Number(component.VL_ALIQUOTA_COFINS) ||
                                            0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP>FCP</TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {component.VL_FCP || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {percentageFormat(
                                        Number(component.VL_ALIQUOTA_FCP) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP>ICMS</TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {component.VL_ICMS || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {percentageFormat(
                                        Number(component.VL_ALIQUOTA_ICMS) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP>IPI</TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {component.VL_IPI || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {percentageFormat(
                                        Number(component.VL_ALIQUOTA_IPI) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP>ST</TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {component.VL_ST || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP>
                                    {percentageFormat(
                                        Number(component.VL_ALIQUOTA_ST) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TabSection>
            {component.MANIPULA_COMPONENTE === 1 && (
                <button
                    className="w-full py-2 text-center text-red-500 rounded-md bg-red-50 dark:bg-destructive dark:text-destructive-foreground"
                    onClick={() => {
                        setIdToRemove({
                            id: Number(component.SK_PRODUTO_COMPONENTE),
                            isExtraCost: true,
                            parentId:
                                components.entities[component._parentId]?._id ||
                                products.entities[component._parentId]._id ||
                                null,
                        })
                        onRemoveCostDialogOpen()
                    }}
                >
                    Remover
                </button>
            )}
        </>
    )
}

export default ExtraCost
