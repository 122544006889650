import ConfirmDialog from '@/components/ConfirmDialog'
import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import useEditDialog, { EditDialogActionNames } from './useEditDialog'
import { Separator } from '@/components/ui/separator'
import { getDateFromId, getIdFromDate } from '@/utils/date'
import { isAxiosError } from '@/api/business'
import { ProductStructureFlat } from '@/types/Product'
import { ProductStructureTable } from '../TreeTable'
import { productStructureKeys } from '@/queries/useProductStructureQuery'
import { DatePicker } from '@/components/DatePicker'
import { editProductStructure } from '@/api/business/productStructure'

interface EditDialogProps {
    row: Row<ProductStructureFlat | ProductStructureTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, ...props }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: editProductStructure,
        onSuccess: () => {
            queryClient.invalidateQueries(productStructureKeys.lists())
            toast({
                title: 'Estrutura de produto atualizado com sucesso',
            })
            props.onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao atualizar estrutura de produto',
                        description: 'Verifique os dados e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const { original } = row

    const { final, finalDate, initial, initialDate, quantity, dispatch } =
        useEditDialog(original)

    const onSubmit = () => {
        if (
            !original ||
            !finalDate ||
            !initialDate ||
            !original.SK_ESTRUTURA_DE_PRODUTO
        )
            return

        mutate({
            IDENTIFICADOR: original.SK_ESTRUTURA_DE_PRODUTO,
            CAMPOS: {
                DS_REVISAO_INICIAL: initial || '',
                DS_REVISAO_FINAL: final || '',
                QTD_COMPONENTE: quantity || '0',
                SK_TEMPO_INICIAL: initialDate,
                SK_TEMPO_FINAL: finalDate,
            },
        })
    }

    return (
        <ConfirmDialog
            title="Editar produto"
            onConfirm={onSubmit}
            isLoading={mutateLoading}
            {...props}
        >
            <div className="flex items-center h-full gap-10 py-4">
                <div className="flex-1 h-full space-y-4">
                    <div>
                        <p className="font-semibold">Informações</p>
                        <Separator />
                    </div>
                    <div>
                        <label className="text-sm text-neutral-500" htmlFor="">
                            Empresa
                        </label>
                        <input
                            className="w-full p-2 border rounded-md"
                            type="text"
                            value={original.SK_EMPRESA}
                            disabled
                        />
                    </div>
                    <div>
                        <label className="text-sm text-neutral-500" htmlFor="">
                            Produto
                        </label>
                        <input
                            className="w-full p-2 border rounded-md"
                            type="text"
                            value={original.PRODUTO_ESTRUTURA}
                            disabled
                        />
                    </div>
                    <div>
                        <label className="text-sm text-neutral-500" htmlFor="">
                            Quantidade
                        </label>
                        <input
                            className="w-full p-2 border rounded-md"
                            type="number"
                            value={quantity || 0}
                            onChange={(e) => {
                                dispatch({
                                    type: EditDialogActionNames.ON_CHANGE_QUANTITY,
                                    payload: {
                                        value: e.target.value,
                                    },
                                })
                            }}
                        />
                    </div>

                    <div className="flex items-center gap-4">
                        <div>
                            <label
                                className="text-sm text-neutral-500"
                                htmlFor=""
                            >
                                Revisão inicial
                            </label>
                            <input
                                className="w-full p-2 border rounded-md"
                                type="text"
                                value={initial || ''}
                                onChange={(e) => {
                                    dispatch({
                                        type: EditDialogActionNames.ON_CHANGE_INITIAL,
                                        payload: {
                                            value: e.target.value,
                                        },
                                    })
                                }}
                            />
                        </div>
                        <div>
                            <label
                                className="text-sm text-neutral-500"
                                htmlFor=""
                            >
                                Dt. Revisao inicial
                            </label>
                            <DatePicker
                                className="w-full"
                                date={
                                    initialDate
                                        ? getDateFromId(initialDate)
                                        : new Date()
                                }
                                setDate={(date) => {
                                    if (!date) return
                                    dispatch({
                                        type: EditDialogActionNames.ON_CHANGE_INITIAL_DATE,
                                        payload: {
                                            value: Number(
                                                getIdFromDate(date).join('')
                                            ),
                                        },
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <div>
                            <label
                                className="text-sm text-neutral-500"
                                htmlFor=""
                            >
                                Revisão final
                            </label>
                            <input
                                className="w-full p-2 border rounded-md"
                                type="text"
                                value={final || ''}
                                onChange={(e) => {
                                    dispatch({
                                        type: EditDialogActionNames.ON_CHANGE_FINAL,
                                        payload: {
                                            value: e.target.value,
                                        },
                                    })
                                }}
                            />
                        </div>
                        <div>
                            <label
                                className="text-sm text-neutral-500"
                                htmlFor=""
                            >
                                Dt. Revisao final
                            </label>
                            <DatePicker
                                className="w-full"
                                date={
                                    finalDate
                                        ? getDateFromId(finalDate)
                                        : new Date()
                                }
                                setDate={(date) => {
                                    if (!date) return
                                    dispatch({
                                        type: EditDialogActionNames.ON_CHANGE_FINAL_DATE,
                                        payload: {
                                            value: Number(
                                                getIdFromDate(date).join('')
                                            ),
                                        },
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default EditDialog
