import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import { useFormContext } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

const CurrencySelect = () => {
    const { data: currencyData = [] } = useCurrencyQuery()
    const { control } = useFormContext<AddSchema>()

    return (
        <FormField
            name="currencyId"
            control={control}
            render={({ field }) => {
                return (
                    <FormItem>
                        <FormLabel>Moeda</FormLabel>
                        <FormControl>
                            <Select
                                onValueChange={field.onChange}
                                value={field.value.toString()}
                            >
                                <SelectTrigger className="w-[150px]">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {currencyData.map((currency) => {
                                            return (
                                                <SelectItem
                                                    key={currency.SK_MOEDA}
                                                    value={currency.SK_MOEDA.toString()}
                                                >
                                                    {currency.DS_MOEDA}
                                                </SelectItem>
                                            )
                                        })}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default CurrencySelect
