import { isAxiosError } from '@/api/business'
import { useToast } from '@/components/ui/use-toast'
import { FetchCostHistoryProps } from '@/api/business/structureStatus/type'
import { useQuery } from '@tanstack/react-query'
import { fetchCostHistory } from '@/api/business/structureStatus'

export const costHistoryKeys = {
    all: ['costHistory'] as const,
    detail: (params: string) => [...costHistoryKeys.all, params] as const,
}

type CostHistoryQuery = FetchCostHistoryProps

export const useCostHistoryQuery = ({ structureId }: CostHistoryQuery) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchCostHistory({ structureId }),
        queryKey: costHistoryKeys.detail(structureId),
        staleTime: Infinity,
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar o histórico de custo. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
