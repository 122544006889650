import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { getItem } from '@/utils/storage'
import Sidenav from '@/components/Sidenav'
import Header from '@/components/Header'
import ChangePasswordDialog from '@/components/ChangePasswordDialog'
import { authSliceStateSelector } from '@/store/authSlice'
import { appConfigSliceStateSelector } from '@/store/appConfigSlice'
import { useBaseStore } from '@/store'

const ProtectedLayout = () => {
    const { user } = useBaseStore(authSliceStateSelector)
    const { isSidenavOpen } = useBaseStore(appConfigSliceStateSelector)

    const token = getItem(localStorage, 'token')
    const location = useLocation()

    if (!user && !token) {
        return (
            <Navigate to="/login" replace state={{ path: location.pathname }} />
        )
    }

    return (
        <>
            <div className="w-full h-screen bg-background">
                <Sidenav />
                <div
                    className={`${
                        isSidenavOpen
                            ? 'ml-sidenav-closed w-container-sidenav-closed sm:ml-sidenav sm:w-container-sidenav'
                            : 'ml-sidenav-closed w-container-sidenav-closed'
                    } h-full`}
                >
                    <Header />
                    <div
                        className={`h-container-header w-full overflow-y-auto`}
                    >
                        <Outlet />
                    </div>
                </div>
            </div>
            <ChangePasswordDialog />
        </>
    )
}

export default ProtectedLayout
