import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { ProductTable } from '../..'
import { updateProduct } from '@/api/business/product'
import { productKeys } from '@/queries/useProductQuery'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import {
    UpdateProductSchema,
    updateProductSchema,
} from '../../schemas/products'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import UMSelect from '../UMSelect'
import ClassificationSelect from '../ClassificationSelect'

interface EditDialogProps {
    row: Row<ProductTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const {
        DS_PRODUTO,
        SK_EMPRESA,
        DD_TAMANHO_EMBALAGEM,
        DD_UM,
        DD_CLASSIFICACAO,
        QTD_VOLUME,
    } = row.original

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: updateProduct,
        onSuccess: () => {
            queryClient.invalidateQueries(productKeys.lists())
            toast({
                title: 'Produto atualizado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao atualizar produto',
                        description: 'Verifique os dados e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const form = useForm<UpdateProductSchema>({
        resolver: zodResolver(updateProductSchema),
        defaultValues: {
            DS_PRODUTO,
            SK_EMPRESA,
            DD_TAMANHO_EMBALAGEM: DD_TAMANHO_EMBALAGEM?.toString() || '0',
            DD_UM,
            DD_CLASSIFICACAO,
            QTD_VOLUME: QTD_VOLUME?.toString() || '0',
        },
        values: {
            ...row.original,
            QTD_VOLUME: QTD_VOLUME?.toString() || '0',
            DD_TAMANHO_EMBALAGEM: DD_TAMANHO_EMBALAGEM?.toString() || '0',
        },
    })

    const onSubmit = (data: UpdateProductSchema) => {
        mutate({
            IDENTIFICADOR: row.original.SK_PRODUTO,
            CAMPOS: {
                ...data,
                DD_TAMANHO_EMBALAGEM: Number(data.DD_TAMANHO_EMBALAGEM),
                QTD_VOLUME: Number(data.QTD_VOLUME),
            },
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-h-full overflow-auto">
                <DialogHeader>
                    <DialogTitle>Editar produto</DialogTitle>
                    <DialogDescription>
                        Edite o produto preenchendo os campos abaixo
                    </DialogDescription>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            name="DS_PRODUTO"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Produto</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            name="SK_EMPRESA"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Código empresa</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <UMSelect />
                        <ClassificationSelect />
                        <FormField
                            name="DD_TAMANHO_EMBALAGEM"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Tamanho embalagem</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="number"
                                                {...field}
                                                value={field.value || ''}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            name="QTD_VOLUME"
                            control={form.control}
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Volume (ml)</FormLabel>
                                        <FormControl>
                                            <Input
                                                type="number"
                                                {...field}
                                                value={field.value || ''}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <div className="flex justify-end gap-2">
                            <Button
                                variant="ghost"
                                type="button"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button disabled={mutateLoading}>Confirmar</Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
