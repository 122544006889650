import { Product } from '@/types/Product'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useCallback, useEffect, useRef, useState } from 'react'

import DraggableProduct from './components/DraggableProduct'
import { DebouncedInput } from '@/components/DebouncedInput'
import { useProductQuery } from '@/queries/useProductQuery'
import Select from '@/components/Select'
import { TypographyP } from '@/components/ui/typography'

interface ProductsListProps {
    isOpen: boolean
}

const ProductsList = ({ isOpen }: ProductsListProps) => {
    const scrollElementRef = useRef<HTMLDivElement>(null)
    const [inputValue, setInputValue] = useState('')
    const [companies, setCompanies] = useState<string[]>([])
    const [companyId, setCompanyId] = useState('')

    const { data, isLoading, isError } = useProductQuery({ enabled: isOpen })
    const [filteredItems, setFilteredItems] = useState<Product[]>([])

    const count = filteredItems.length

    const virtualizer = useVirtualizer({
        count,
        getScrollElement: () => scrollElementRef.current,
        estimateSize: () => 50,
    })

    const items = virtualizer.getVirtualItems()

    const filterItems = useCallback(
        (items: Product[], value: string, companyId: string) => {
            const getSanitizedString = (string: string) =>
                string.toLowerCase().trim()

            return setFilteredItems(
                items.filter(
                    (item) =>
                        (getSanitizedString(item.DS_PRODUTO || '').includes(
                            getSanitizedString(value)
                        ) ||
                            getSanitizedString(item.SK_EMPRESA || '').includes(
                                getSanitizedString(value)
                            ) ||
                            getSanitizedString(
                                item.ABREVIATURA_EMPRESA || ''
                            ).includes(getSanitizedString(value)) ||
                            getSanitizedString(item.NK_PRODUTO || '').includes(
                                getSanitizedString(value)
                            )) &&
                        item.SK_EMPRESA.toString().includes(companyId)
                )
            )
        },
        []
    )

    const onInputChange = (value: string | number) => {
        setInputValue(value as string)
        if (data) {
            filterItems(data, value as string, companyId)
        }
    }

    useEffect(() => {
        if (data) {
            const uniqueCompanies = [
                ...new Set(data.map((prod) => prod.SK_EMPRESA)),
            ]
            setCompanies(uniqueCompanies)
            setCompanyId(uniqueCompanies[0])
            setFilteredItems(data)
        }
    }, [data, setFilteredItems])

    return (
        <div className="flex flex-col w-full h-full">
            <div className="w-full">
                <TypographyP className="mb-4 font-semibold">
                    Lista de produtos
                </TypographyP>
                <div className="flex items-center gap-4 mb-2">
                    <DebouncedInput
                        className="flex-1 px-3 py-2 text-sm border rounded-md"
                        type="text"
                        placeholder="Buscar por código ou produto..."
                        value={inputValue}
                        onChange={onInputChange}
                    />
                    <Select
                        className="w-auto h-full"
                        options={companies.map((company) => ({
                            label: company,
                            value: company,
                        }))}
                        value={companyId}
                        onChange={(value) => {
                            if (!data) return
                            setCompanyId(value)
                            filterItems(data, inputValue, value)
                        }}
                    />
                </div>
            </div>
            {isLoading && (
                <TypographyP className="text-sm text-center">
                    Carregando produtos...
                </TypographyP>
            )}
            {isError && (
                <TypographyP className="text-sm text-center text-red-500">
                    Erro ao carregar produtos
                </TypographyP>
            )}
            {data && (
                <div
                    ref={scrollElementRef}
                    className="flex-1 w-full p-2 overflow-y-auto rounded-md bg-background dark:bg-zinc-900"
                >
                    <div
                        className="relative w-full"
                        style={{
                            height: `${virtualizer.getTotalSize()}px`,
                        }}
                    >
                        {items.map((virtualItem) => {
                            const product = filteredItems[virtualItem.index]
                            return (
                                <DraggableProduct
                                    key={virtualItem.key}
                                    style={{
                                        height: `${virtualItem.size}px`,
                                        transform: `translateY(${virtualItem.start}px)`,
                                    }}
                                    product={product}
                                />
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProductsList
