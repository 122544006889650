import { CreateBulkInformedCostReturn } from '@/api/business/informedCost/types'
import { getIdFromDate } from '@/utils/date'
import { useCallback, useMemo, useReducer } from 'react'
import { BulkData } from '.'
import { isValid } from 'date-fns'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'

export interface BulkDialogState {}
export enum BulkDialogActionNames {
    SET_TABLE_DATA,
    UPDATE_TABLE_DATA,
    SET_DATA_STATUS,
    ADD_ROW,
    REMOVE_ROW,
    RESET_ALL,
    RESET_TABLE_DATA,
    RESET_RESPONSE,
    ON_REPLACE_STRUCTURE_CHANGE,
}

type BulkDialogActions =
    | {
          type: BulkDialogActionNames.SET_TABLE_DATA
          payload: { data: BulkData[]; replace: boolean }
      }
    | {
          type: BulkDialogActionNames.UPDATE_TABLE_DATA
          payload: { rowIdx: number; columnId: string; value: unknown }
      }
    | {
          type: BulkDialogActionNames.SET_DATA_STATUS
          payload: CreateBulkInformedCostReturn[]
      }
    | {
          type: BulkDialogActionNames.ADD_ROW
      }
    | {
          type: BulkDialogActionNames.REMOVE_ROW
          payload: {
              id: string
          }
      }
    | {
          type: BulkDialogActionNames.RESET_ALL
      }
    | {
          type: BulkDialogActionNames.RESET_RESPONSE
      }
    | {
          type: BulkDialogActionNames.RESET_TABLE_DATA
      }

interface State {
    tableData: BulkData[]
    feedbackResponse: CreateBulkInformedCostReturn[]
}

const initialState: State = {
    tableData: [],
    feedbackResponse: [],
}

const useBulkDialog = () => {
    const { data = [], isLoading } = useProductStructureQuery({
        enabled: true,
    })

    const products = useMemo(
        () => data?.filter((product) => product.DD_ESTRUTURA_PADRAO === 1),
        [data]
    )

    const reducer = useCallback(
        (
            state: typeof initialState,
            action: BulkDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case BulkDialogActionNames.SET_TABLE_DATA: {
                    const { data, replace } = action.payload

                    const newData = data.map((product) => {
                        const { SK_TEMPO } = product
                        let date = SK_TEMPO
                        const number = SK_TEMPO

                        if (SK_TEMPO) {
                            if (Number.isNaN(number)) {
                                if (isValid(new Date(SK_TEMPO)))
                                    date = Number(
                                        getIdFromDate(new Date(SK_TEMPO)).join(
                                            ''
                                        )
                                    )
                            }
                        }

                        const productId = `${product.SK_EMPRESA}${product.NK_PRODUTO_ESTRUTURA}`
                        const componentId = `${product.SK_EMPRESA}${product.NK_PRODUTO_COMPONENTE}`

                        const productFound = products?.find((prod) => {
                            return prod.SK_PRODUTO_ESTRUTURA === productId
                        })

                        const componentFound = products?.find((prod) => {
                            return (
                                prod.SK_PRODUTO_COMPONENTE === componentId &&
                                prod.SK_PRODUTO_ESTRUTURA === productId
                            )
                        })

                        return {
                            ...product,
                            id: crypto.randomUUID(),
                            SK_MOEDA: product.SK_MOEDA || 1,
                            SK_TEMPO: date,
                            PRODUTO_COMPONENTE:
                                componentFound?.PRODUTO_COMPONENTE,
                            PRODUTO_ESTRUTURA: productFound?.PRODUTO_ESTRUTURA,
                            VL_ALIQUOTA_COFINS:
                                product.VL_ALIQUOTA_COFINS || '0',
                            VL_ALIQUOTA_FCP: product.VL_ALIQUOTA_FCP || '0',
                            VL_ALIQUOTA_ICMS: product.VL_ALIQUOTA_ICMS || '0',
                            VL_ALIQUOTA_IPI: product.VL_ALIQUOTA_IPI || '0',
                            VL_ALIQUOTA_PIS: product.VL_ALIQUOTA_PIS || '0',
                            VL_ALIQUOTA_ST: product.VL_ALIQUOTA_ST || '0',
                            VL_COFINS: product.VL_COFINS || '0',
                            VL_CUSTO_ORCADO: product.VL_CUSTO_ORCADO || '0',
                            VL_FCP: product.VL_FCP || '0',
                            VL_ICMS: product.VL_ICMS || '0',
                            VL_IPI: product.VL_IPI || '0',
                            VL_PIS: product.VL_PIS || '0',
                            VL_ST: product.VL_ST || '0',
                        }
                    })

                    return {
                        tableData: replace
                            ? newData
                            : [...state.tableData, ...newData],
                        feedbackResponse: [],
                    }
                }
                case BulkDialogActionNames.SET_DATA_STATUS: {
                    const response = action.payload

                    return { ...state, feedbackResponse: response }
                }
                case BulkDialogActionNames.UPDATE_TABLE_DATA: {
                    const { columnId, rowIdx, value } = action.payload

                    return {
                        ...state,
                        tableData: state.tableData.map((row, idx) => {
                            if (idx === rowIdx) {
                                const row = state.tableData[rowIdx]

                                if (columnId === 'NK_PRODUTO_ESTRUTURA') {
                                    const productId = `${row['SK_EMPRESA']}${value}`
                                    const productFound = products?.find(
                                        (prod) =>
                                            prod.SK_PRODUTO_ESTRUTURA ===
                                            productId
                                    )

                                    return {
                                        ...row,
                                        [columnId]: value as string,
                                        PRODUTO_ESTRUTURA:
                                            productFound?.PRODUTO_ESTRUTURA,
                                    }
                                }

                                if (columnId === 'SK_EMPRESA') {
                                    const productId = `${value}${row['NK_PRODUTO_ESTRUTURA']}`
                                    const componentId = `${value}${row['NK_PRODUTO_COMPONENTE']}`

                                    const productFound = products?.find(
                                        (prod) =>
                                            prod.SK_PRODUTO_ESTRUTURA ===
                                            productId
                                    )

                                    const componentFound = products?.find(
                                        (prod) =>
                                            prod.SK_PRODUTO_ESTRUTURA ===
                                                productId &&
                                            prod.SK_PRODUTO_COMPONENTE ===
                                                componentId
                                    )

                                    return {
                                        ...row,
                                        [columnId]: value as string,
                                        PRODUTO_ESTRUTURA:
                                            productFound?.PRODUTO_ESTRUTURA ||
                                            '',
                                        PRODUTO_COMPONENTE:
                                            componentFound?.PRODUTO_COMPONENTE ||
                                            '',
                                    }
                                }

                                if (columnId === 'NK_PRODUTO_COMPONENTE') {
                                    const productId = `${row['SK_EMPRESA']}${row['NK_PRODUTO_ESTRUTURA']}`
                                    const componentId = `${row['SK_EMPRESA']}${value}`

                                    const componentFound = products?.find(
                                        (prod) =>
                                            prod.SK_PRODUTO_ESTRUTURA ===
                                                productId &&
                                            prod.SK_PRODUTO_COMPONENTE ===
                                                componentId
                                    )

                                    return {
                                        ...row,
                                        [columnId]: value as string,
                                        PRODUTO_COMPONENTE:
                                            componentFound?.PRODUTO_COMPONENTE,
                                    }
                                }

                                return {
                                    ...row,
                                    [columnId]: value,
                                }
                            }
                            return row
                        }),
                    }
                }
                case BulkDialogActionNames.ADD_ROW: {
                    return {
                        ...state,
                        tableData: [
                            ...state.tableData,
                            {
                                id: crypto.randomUUID(),
                                NK_PRODUTO_COMPONENTE: '',
                                NK_PRODUTO_ESTRUTURA: '',
                                SK_EMPRESA: '',
                                SK_MOEDA: 1,
                                SK_TEMPO: Number(
                                    getIdFromDate(new Date()).join('')
                                ),
                                VL_ALIQUOTA_COFINS: '0',
                                VL_ALIQUOTA_FCP: '0',
                                VL_ALIQUOTA_ICMS: '0',
                                VL_ALIQUOTA_IPI: '0',
                                VL_ALIQUOTA_PIS: '0',
                                VL_ALIQUOTA_ST: '0',
                                VL_COFINS: '0',
                                VL_CUSTO_ORCADO: '0',
                                VL_FCP: '0',
                                VL_ICMS: '0',
                                VL_IPI: '0',
                                VL_PIS: '0',
                                VL_ST: '0',
                                DD_TIPO_FRETE: 'CIF',
                                DD_OBSERVACAO: '',
                            },
                        ],
                    }
                }
                case BulkDialogActionNames.REMOVE_ROW: {
                    const { id } = action.payload
                    return {
                        ...state,
                        tableData: state.tableData.filter(
                            (cost) => cost.id !== id
                        ),
                    }
                }
                case BulkDialogActionNames.RESET_ALL:
                    return initialState
                case BulkDialogActionNames.RESET_RESPONSE:
                    return { ...state, feedbackResponse: [] }
                case BulkDialogActionNames.RESET_TABLE_DATA:
                    return {
                        ...state,
                        tableData: state.tableData.filter(
                            (product) =>
                                !!state.feedbackResponse.find(
                                    (fb) =>
                                        fb.SK_PRODUTO_ESTRUTURA ===
                                            `${product.SK_EMPRESA}${product.NK_PRODUTO_ESTRUTURA}` &&
                                        fb.SK_PRODUTO_COMPONENTE ===
                                            `${product.SK_EMPRESA}${product.NK_PRODUTO_COMPONENTE}` &&
                                        fb.STATUS !== 200
                                )
                        ),
                    }
                default:
                    return state
            }
        },
        [products]
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        productsLoading: isLoading,
        dispatch,
    }
}
export default useBulkDialog
