import { getMonthsFromRange, getYearsFromRange } from '@/utils/date'
import { useMemo } from 'react'
import Select from '../Select'

interface DatePickerMonthYearProps {
    month: number
    year: number
    setDate: (date: Date) => void
}

const DatePickerMonthYear = ({
    month,
    year,
    setDate,
}: DatePickerMonthYearProps) => {
    const months = useMemo(() => getMonthsFromRange(), [])
    const years = useMemo(() => getYearsFromRange(2020), [])

    const onChangeDate = (name: 'month' | 'year', value: string) => {
        let date: Date = new Date(month, year, 1)

        if (name === 'month') {
            date = new Date(year, Number(value), 1)
        } else if (name === 'year') {
            date = new Date(Number(value), month, 1)
        }

        setDate(date)
    }

    return (
        <div className="flex items-center gap-2">
            <Select
                className="w-[65px]"
                options={months.map((month) => ({
                    label: month.short,
                    value: month.number.toString(),
                }))}
                placeholder="Mês"
                onChange={(value) => onChangeDate('month', value)}
                value={month.toString()}
            />
            <Select
                className="w-[80px]"
                options={years.map((year) => ({
                    label: year.long.toString(),
                    value: year.long.toString(),
                }))}
                placeholder="Ano"
                onChange={(value) => onChangeDate('year', value)}
                value={year.toString()}
            />
        </div>
    )
}

export default DatePickerMonthYear
