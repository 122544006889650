import { useState } from 'react'
import useDisclosure from '@/hooks/useDisclosure'
import AddCostDialog from './components/AddCostDialog'
import AddExtraCostDialog from './components/AddExtraCostDialog'
import RemoveCostDialog from './components/RemoveCostDialog'
import Product from './components/Product'
import ExtraCost from './components/ExtraCost'
import Component from './components/Component'
import {
    ComponentCustomEntity,
    ProductCustomEntity,
} from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'

export interface IdToRemove {
    id: number
    parentId?: string | number | null
    isExtraCost: boolean
}

const CostsTab = () => {
    const {
        selectedStdCostRow: selectedRow,
        stdCostData: { components, products },
    } = useBaseStore(stdCostSliceStateSelector)

    const {
        isOpen: isAddCostDialogOpen,
        onClose: onAddCostDialogClose,
        onOpen: onAddCostDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isRemoveCostDialogOpen,
        onClose: onRemoveCostDialogClose,
        onOpen: onRemoveCostDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isAddExtraCostDialogOpen,
        onClose: onAddExtraCostDialogClose,
        onOpen: onAddExtraCostDialogOpen,
    } = useDisclosure()

    const isProduct = selectedRow?.isProduct

    const original =
        selectedRow && isProduct
            ? products.entities[selectedRow.id]
            : components.entities[selectedRow!.id]

    const isExtraCost =
        (original as ComponentCustomEntity)?.ESTRUTURA === 'EXTRA-COMPONENTE' ||
        (original as ComponentCustomEntity)?.ESTRUTURA === 'EXTRA-PRODUTO'

    const [idToRemove, setIdToRemove] = useState<IdToRemove | null>(null)

    return (
        <>
            <div className="flex flex-col h-full gap-6 p-4 rounded-md">
                {original ? (
                    <>
                        {isExtraCost ? (
                            <ExtraCost
                                component={original as ComponentCustomEntity}
                                setIdToRemove={setIdToRemove}
                                onRemoveCostDialogOpen={onRemoveCostDialogOpen}
                            />
                        ) : isProduct ? (
                            <Product
                                product={
                                    original as unknown as ProductCustomEntity
                                }
                                onAddExtraCostDialogOpen={
                                    onAddExtraCostDialogOpen
                                }
                                onRemoveCostDialogOpen={onRemoveCostDialogOpen}
                                setIdToRemove={setIdToRemove}
                            />
                        ) : (
                            <Component
                                componentId={selectedRow?.id || ''}
                                setIdToRemove={setIdToRemove}
                                onAddCostDialogOpen={onAddCostDialogOpen}
                                onAddExtraCostDialogOpen={
                                    onAddExtraCostDialogOpen
                                }
                                onRemoveCostDialogOpen={onRemoveCostDialogOpen}
                            />
                        )}
                    </>
                ) : null}
            </div>
            {isAddCostDialogOpen && (
                <AddCostDialog
                    isOpen={isAddCostDialogOpen}
                    onClose={onAddCostDialogClose}
                />
            )}
            {isAddExtraCostDialogOpen && (
                <AddExtraCostDialog
                    isOpen={isAddExtraCostDialogOpen}
                    onClose={onAddExtraCostDialogClose}
                />
            )}
            {idToRemove?.id && isRemoveCostDialogOpen ? (
                <RemoveCostDialog
                    id={idToRemove?.id}
                    description={
                        idToRemove?.isExtraCost
                            ? `Deseja remover o custo extra?`
                            : `Deseja remover o custo informado?`
                    }
                    isOpen={isRemoveCostDialogOpen}
                    onClose={onRemoveCostDialogClose}
                />
            ) : null}
        </>
    )
}

export default CostsTab
