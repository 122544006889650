import { ImmerStateCreator } from '../type'
import { ResetSlice } from './type'

// type ResetState = {}

export const useResetSlice: ImmerStateCreator<ResetSlice> = (_, get) => ({
    onResetAll: () => {
        for (const key of Object.keys(get())) {
            get()[key]?.actions?.onReset()
        }
    },
})
