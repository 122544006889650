import { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'
import DefaultDetails from './components/DefaultDetails'
import ExpandedDetails from './components/ExpandedDetails'

const Details = () => {
    const {
        isDetailsOpen,
        selectedStdCostRow: selectedRow,
        stdCostData: { components, products },
    } = useBaseStore(stdCostSliceStateSelector)

    const [isFullScreen, setIsFullScreen] = useState(false)

    const productOrComponent =
        selectedRow && selectedRow?.isProduct
            ? products.entities[selectedRow.id]
            : components.entities[selectedRow!.id]

    useEffect(() => {
        if (!selectedRow) return

        if (!selectedRow.isProduct) {
            setIsFullScreen(false)
        }
    }, [selectedRow])

    if (!productOrComponent) {
        return null
    }

    return (
        <div className="relative z-50 h-full p-4 md:py-6 md:pr-6">
            <AnimatePresence>
                {isDetailsOpen && isFullScreen ? (
                    <ExpandedDetails onChangeFullscreen={setIsFullScreen} />
                ) : (
                    <DefaultDetails
                        productOrComponent={productOrComponent}
                        onChangeFullScreen={setIsFullScreen}
                    />
                )}
            </AnimatePresence>
        </div>
    )
}

export default Details
