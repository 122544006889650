import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { DollarSign, PenLine } from 'lucide-react'
import { HoverUser } from '../HoverUser'
import { getLocaleStringDate } from '@/utils/date'
import { currencyFormat } from '@/utils/stringFormatter'
import { CurrencyType } from '@/types/Currency'
import { CostView } from '@/types/Product'
import { cn } from '@/lib/utils'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'

interface HistoryItemProps {
    name: string
    user: string
    email: string
    cost: number
    currency: CurrencyType | null
    costView: CostView | null
    createdAt: Date
}
const HistoryItem = ({
    name,
    user,
    email,
    cost,
    currency,
    costView,
    createdAt,
}: HistoryItemProps) => {
    return (
        <div className="flex items-start gap-2 p-4">
            <Avatar className="w-6 h-6 mr-2">
                <AvatarFallback
                    className={cn(
                        'text-white ',
                        currency && 'bg-green-500 dark:bg-green-800',
                        costView && 'bg-purple-500 dark:bg-purple-800'
                    )}
                >
                    {currency && <DollarSign size={14} strokeWidth={2} />}
                    {costView && <PenLine size={14} strokeWidth={2} />}
                </AvatarFallback>
            </Avatar>
            <div className="flex-1">
                <div className="flex items-start gap-1 mb-2 text-sm">
                    <div>
                        <div className="flex items-center gap-1">
                            <HoverUser
                                username={name}
                                user={user}
                                email={email}
                            />
                            {currency && (
                                <TypographyP>
                                    adicionou um novo custo
                                </TypographyP>
                            )}
                            {costView && (
                                <TypographyP>mudou o custo padrão</TypographyP>
                            )}
                        </div>
                        <TypographyMuted className="-mt-1">
                            {getLocaleStringDate(createdAt)}
                        </TypographyMuted>
                    </div>
                </div>
                {currency && (
                    <div className="p-2 text-sm rounded-md bg-muted/60">
                        <TypographyP>
                            <span className="text-muted-foreground">
                                Último preço informado:
                            </span>{' '}
                            {currencyFormat(Number(cost), 4, currency)}
                        </TypographyP>
                    </div>
                )}
                {costView && (
                    <div className="p-2 text-sm rounded-md">
                        <TypographyP>
                            <span className="font-bold">Última visão:</span>
                            <span
                                className={cn(
                                    'px-2 text-sm rounded-md ',
                                    costView === CostView.ULTIMO_PRECO &&
                                        'border border-primary-500 text-primary-500',
                                    costView === CostView.CUSTO_INFORMADO &&
                                        'border border-green-500 text-green-500'
                                )}
                            >
                                {costView}
                            </span>
                        </TypographyP>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HistoryItem
