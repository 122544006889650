import { isAxiosError } from '@/api/business'
import { fetchProductionVolume } from '@/api/business/productionVolume'
import { useToast } from '@/components/ui/use-toast'
import { useQuery } from '@tanstack/react-query'

export const productionVolumeKeys = {
    all: ['productionVolume'] as const,
    lists: () => [...productionVolumeKeys.all, 'list'] as const,
    list: (date: number) => [...productionVolumeKeys.lists(), date] as const,
    details: () => [...productionVolumeKeys.all, 'detail'] as const,
    detail: (props: { productId: string; componentId: string; date: number }) =>
        [...productionVolumeKeys.details(), { ...props }] as const,
}

export const useProductionVolumeQuery = () => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchProductionVolume(),
        queryKey: productionVolumeKeys.lists(),
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar os volumes de produção. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
