import { ThemeProvider } from './contexts/ThemeContextProvider'
import Router from './Router'

function App() {
    return (
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
            <Router />
        </ThemeProvider>
    )
}

export default App
