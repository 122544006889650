import { isAxiosError } from '@/api/business'
import { fetchCurrencyValues } from '@/api/business/currency'
import { useToast } from '@/components/ui/use-toast'
import { CurrencyValue } from '@/types/Currency'
import { useQuery } from '@tanstack/react-query'

export const currencyValuesKeys = {
    all: ['currencyValues'] as const,
    lists: () => [...currencyValuesKeys.all, 'list'] as const,
    list: (initial: number, end: number) =>
        [...currencyValuesKeys.lists(), { initial, end }] as const,
    details: () => [...currencyValuesKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...currencyValuesKeys.details(), params] as const,
}

interface CurrencyValuesQuery {
    onSuccess?: (data: CurrencyValue[]) => void
}

export const useCurrencyValuesQuery = (props?: CurrencyValuesQuery) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchCurrencyValues(),
        queryKey: currencyValuesKeys.lists(),
        staleTime: Infinity,
        onSuccess: props?.onSuccess,
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar os valores de moedas. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
