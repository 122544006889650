import { Table } from '@tanstack/react-table'
import { ExtraCostTable } from '.'
import { read, utils, writeFile } from 'xlsx'
import { useToast } from '@/components/ui/use-toast'
import { ExtraCost } from '@/types/Costs'
import { CreateBulkExtraCostProps } from '@/api/business/extraCost/types'
import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import { format } from 'date-fns'

const BULK_HEADERS: {
    original: keyof CreateBulkExtraCostProps
    header: string
}[] = [
    { original: 'SK_EMPRESA', header: 'COD_EMPRESA' },
    { original: 'SK_PRODUTO_ESTRUTURA', header: 'COD_PRODUTO' },
    { original: 'SK_PRODUTO_COMPONENTE', header: 'COD_PRODUTO_COMPONENTE' },
    { original: 'SK_TEMPO', header: 'DATA' },
    { original: 'DS_CUSTO', header: 'DESCRIÇÃO' },
    { original: 'VL_CUSTO_ORCADO', header: 'VALOR' },
    { original: 'CATEGORIA_VALOR', header: 'CATEGORIA' },
    { original: 'DD_TIPO_FRETE', header: 'FRETE' },
    { original: 'DD_OBSERVACAO', header: 'OBSERVAÇÃO' },
    {
        original: 'VL_ALIQUOTA_COFINS',
        header: 'VL_ALIQUOTA_COFINS',
    },
    { original: 'VL_COFINS', header: 'VL_COFINS' },
    { original: 'VL_ALIQUOTA_FCP', header: 'VL_ALIQUOTA_FCP' },
    { original: 'VL_FCP', header: 'VL_FCP' },
    { original: 'VL_ALIQUOTA_ICMS', header: 'VL_ALIQUOTA_ICMS' },
    { original: 'VL_ICMS', header: 'VL_ICMS' },
    { original: 'VL_ALIQUOTA_IPI', header: 'VL_ALIQUOTA_IPI' },
    { original: 'VL_IPI', header: 'VL_IPI' },
    { original: 'VL_ALIQUOTA_PIS', header: 'VL_ALIQUOTA_PIS' },
    { original: 'VL_PIS', header: 'VL_PIS' },
    { original: 'VL_ALIQUOTA_ST', header: 'VL_ALIQUOTA_ST' },
    { original: 'VL_ST', header: 'VL_ST' },
    { original: 'VL_ALIQUOTA_ICMS', header: 'VL_ALIQUOTA_ICMS' },
    { original: 'VL_ICMS', header: 'VL_ICMS' },
]

const HEADERS: {
    original: keyof ExtraCost
    header: string
}[] = [
    { original: 'SK_EMPRESA', header: 'COD_EMPRESA' },
    { original: 'NK_PRODUTO_ESTRUTURA', header: 'COD_PRODUTO' },
    { original: 'PRODUTO_ESTRUTURA', header: 'PRODUTO' },
    { original: 'NK_PRODUTO_COMPONENTE', header: 'COD_PRODUTO_COMPONENTE' },
    { original: 'PRODUTO_COMPONENTE', header: 'PRODUTO COMPONENTE' },
    { original: 'SK_TEMPO', header: 'DATA' },
    { original: 'DS_CUSTO', header: 'DESCRIÇÃO' },
    { original: 'VL_CUSTO_ORCADO', header: 'VALOR' },
    { original: 'DD_TIPO_FRETE', header: 'FRETE' },
    { original: 'DD_OBSERVACAO', header: 'OBSERVAÇÃO' },
    { original: 'CATEGORIA_VALOR', header: 'CATEGORIA' },
    {
        original: 'VL_ALIQUOTA_COFINS',
        header: 'VL_ALIQUOTA_COFINS',
    },
    { original: 'VL_COFINS', header: 'VL_COFINS' },
    { original: 'VL_ALIQUOTA_FCP', header: 'VL_ALIQUOTA_FCP' },
    { original: 'VL_FCP', header: 'VL_FCP' },
    { original: 'VL_ALIQUOTA_ICMS', header: 'VL_ALIQUOTA_ICMS' },
    { original: 'VL_ICMS', header: 'VL_ICMS' },
    { original: 'VL_ALIQUOTA_IPI', header: 'VL_ALIQUOTA_IPI' },
    { original: 'VL_IPI', header: 'VL_IPI' },
    { original: 'VL_ALIQUOTA_PIS', header: 'VL_ALIQUOTA_PIS' },
    { original: 'VL_PIS', header: 'VL_PIS' },
    { original: 'VL_ALIQUOTA_ST', header: 'VL_ALIQUOTA_ST' },
    { original: 'VL_ST', header: 'VL_ST' },
    { original: 'CREATED_AT', header: 'CRIADO EM' },
    { original: 'UPDATED_AT', header: 'ATUALIZADO EM' },
]

export const useExtraCostSpreadsheet = (
    tableInstance?: Table<ExtraCostTable>
) => {
    const { toast } = useToast()
    const { data: currencyData } = useCurrencyQuery()

    const getTableRows = (table: Table<ExtraCostTable>): Partial<ExtraCost>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original)) {
                const header = HEADERS.find((h) => h.original === key)?.header

                if (header && value) {
                    if (key === 'CREATED_AT' || key === 'UPDATED_AT') {
                        obj[header] = format(
                            new Date(value),
                            'dd/MM/yyyy HH:mm:ss'
                        )
                    } else {
                        obj[header] = value.toString()
                    }
                }
            }

            return obj
        })

    const getHeaders = (table: Table<ExtraCostTable>): string[] =>
        table
            .getFlatHeaders()
            .map(
                (header) =>
                    BULK_HEADERS.find((h) => header.id === h.original)
                        ?.header || ''
            )
            .filter((header) => Boolean(header))

    const onDownload = () => {
        if (!tableInstance)
            return toast({
                title: 'Erro ao fazer download',
                description:
                    'Houve um erro inesperado ao fazer o download da planilha. Tente novamente ou contate o suporte.',
                variant: 'destructive',
            })

        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Custo extra')
        writeFile(workbook, 'Custo extra.xlsx', {
            compression: true,
        })
    }

    const onDownloadBulkModel = (tableData?: CreateBulkExtraCostProps[]) => {
        const headers = BULK_HEADERS.map((header) => header.header)

        const worksheet =
            tableData && tableData.length > 0
                ? utils.json_to_sheet(
                      tableData.map((data) => {
                          const obj = {} as Record<string, any>

                          for (const [key, value] of Object.entries(data)) {
                              const header = HEADERS.find(
                                  (h) => h.original === key.trim()
                              )?.header

                              if (header) {
                                  obj[header] = value.toString()
                              }
                          }

                          return obj as CreateBulkExtraCostProps
                      })
                  )
                : utils.aoa_to_sheet([headers])

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Custo extra')

        writeFile(workbook, 'CustoExtra.xlsx', {
            compression: true,
        })
    }

    const onReadData = async (sheetFile: File) => {
        const data = await sheetFile.arrayBuffer()
        const workbook = read(data)
        const sheet = workbook.Sheets[workbook.SheetNames[0]]

        const sheetJson = utils.sheet_to_json<CreateBulkExtraCostProps>(sheet)

        return sheetJson.map((sheetData) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(sheetData)) {
                const original = BULK_HEADERS.find(
                    (h) => h.header === key
                )?.original

                if (original) {
                    if (original === 'SK_MOEDA') {
                        obj[original] =
                            currencyData?.find(
                                (currency) => currency.DS_MOEDA === value
                            )?.SK_MOEDA || 1
                    } else {
                        obj[original] = value.toString()
                    }
                }
            }

            return obj as CreateBulkExtraCostProps
        })
    }

    return {
        onDownloadBulkModel,
        onDownload,
        onReadData,
        getHeaders,
    }
}
