import { useFormContext } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useState } from 'react'

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

const ComponentSelect = () => {
    const { control } = useFormContext<AddSchema>()

    const { data: productStructureData = [] } = useProductStructureQuery({
        enabled: true,
    })
    const [products, setProducts] = useState<typeof productStructureData>([])

    const form = useFormContext<AddSchema>()
    const companyField = form.watch('companyId')

    useEffect(() => {
        if (companyField) {
            const unique = {}

            const filtered = productStructureData.filter(
                (product) => product.SK_EMPRESA.toString() === companyField
            )

            for (const product of filtered) {
                if (!unique[product.SK_PRODUTO_COMPONENTE]) {
                    unique[product.SK_PRODUTO_COMPONENTE] = product
                }
            }

            setProducts(Object.values(unique))
        } else {
            form.resetField('productId')
            setProducts([])
        }
    }, [productStructureData, companyField])

    return (
        <FormField
            name="componentId"
            control={control}
            render={({ field }) => {
                return (
                    <FormItem>
                        <FormLabel>Componente estrutura</FormLabel>
                        <FormControl>
                            <Select
                                onValueChange={field.onChange}
                                value={field.value}
                            >
                                <SelectTrigger>
                                    <SelectValue
                                        placeholder={
                                            !companyField
                                                ? 'Selecione uma empresa'
                                                : 'Selecione um produto'
                                        }
                                    />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {products.map((product) => {
                                            return (
                                                <SelectItem
                                                    key={
                                                        product.SK_PRODUTO_COMPONENTE
                                                    }
                                                    value={
                                                        product.SK_PRODUTO_COMPONENTE
                                                    }
                                                >
                                                    {product.PRODUTO_COMPONENTE}
                                                </SelectItem>
                                            )
                                        })}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default ComponentSelect
