import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { DatePicker } from '@/components/DatePicker'
import useEditProductDialog, {
    EditDialogActionNames,
} from './useEditProductDialog'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { Input } from '@/components/ui/input'

const EditProductDialog = () => {
    const {
        initialDate,
        finalDate,
        final,
        initial,
        quantity,
        isOpen,
        item,
        own,
        onChangeProduct,
        onOpenChange,
        dispatch,
    } = useEditProductDialog()

    return (
        <>
            {isOpen && (
                <Dialog open={isOpen} onOpenChange={onOpenChange}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>
                                {item?.parentId ? 'Editar produto' : 'Replicar'}
                            </DialogTitle>
                            <DialogDescription className="text-sm text-neutral-500">
                                {item?.parentId
                                    ? 'Alterar informações do componente.'
                                    : 'As mudanças feitas serão aplicadas para todos os componentes dessa estrutura.'}
                            </DialogDescription>
                        </DialogHeader>
                        <div className="flex items-center gap-2 overflow-hidden">
                            <div className="flex-1 overflow-hidden space-y-1.5">
                                <TypographyMuted>Produto</TypographyMuted>
                                <Input
                                    value={`${item?.NK_PRODUTO} - ${item?.DS_PRODUTO}`}
                                    readOnly
                                />
                            </div>
                            {item?.parentId && (
                                <div className="w-1/4 space-y-1.5">
                                    <TypographyMuted asChild>
                                        <label htmlFor="" className="block">
                                            Qtde
                                        </label>
                                    </TypographyMuted>
                                    <Input
                                        className="w-full px-2 py-1 border rounded-md"
                                        type="number"
                                        onChange={(e) =>
                                            dispatch({
                                                type: EditDialogActionNames.ON_CHANGE_QUANTITY,
                                                payload: {
                                                    value: e.target.value,
                                                },
                                            })
                                        }
                                        value={quantity}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex-1 space-y-1.5">
                                <TypographyMuted asChild>
                                    <label htmlFor="" className="block">
                                        Revisão inicial
                                    </label>
                                </TypographyMuted>
                                <Input
                                    onChange={(e) =>
                                        dispatch({
                                            type: EditDialogActionNames.ON_CHANGE_INITIAL,
                                            payload: { value: e.target.value },
                                        })
                                    }
                                    value={initial}
                                />
                            </div>
                            <div className="flex-1 space-y-1.5">
                                <TypographyMuted asChild>
                                    <label htmlFor="" className="block">
                                        Dt. revisão inicial
                                    </label>
                                </TypographyMuted>
                                <DatePicker
                                    className="w-full"
                                    date={initialDate}
                                    setDate={(date) =>
                                        date &&
                                        dispatch({
                                            type: EditDialogActionNames.ON_CHANGE_INITIAL_DATE,
                                            payload: { value: date },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="flex-1 space-y-1.5">
                                <TypographyMuted asChild>
                                    <label htmlFor="" className="block">
                                        Revisão Final
                                    </label>
                                </TypographyMuted>
                                <Input
                                    onChange={(e) =>
                                        dispatch({
                                            type: EditDialogActionNames.ON_CHANGE_FINAL,
                                            payload: { value: e.target.value },
                                        })
                                    }
                                    value={final}
                                />
                            </div>
                            <div className="flex-1 space-y-1.5">
                                <TypographyMuted asChild>
                                    <label htmlFor="" className="block">
                                        Dt. revisão final
                                    </label>
                                </TypographyMuted>
                                <DatePicker
                                    className="w-full"
                                    date={finalDate}
                                    setDate={(date) =>
                                        date &&
                                        dispatch({
                                            type: EditDialogActionNames.ON_CHANGE_FINAL_DATE,
                                            payload: { value: date },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            {!item?.parentId && (
                                <div className="flex items-center gap-2">
                                    <Checkbox
                                        id="check"
                                        checked={own === 1 ? true : false}
                                        onCheckedChange={(checked) =>
                                            dispatch({
                                                type: EditDialogActionNames.ON_CHANGE_IS_OWN_PRODUCT,
                                                payload: { value: !!checked },
                                            })
                                        }
                                    />
                                    <TypographyP asChild>
                                        <label htmlFor="check">
                                            Produto próprio
                                        </label>
                                    </TypographyP>
                                </div>
                            )}
                        </div>
                        <DialogFooter>
                            <Button
                                className="rounded-md w-[100px]"
                                onClick={() => {
                                    if (item) {
                                        onChangeProduct({
                                            final,
                                            finalDate,
                                            initial,
                                            initialDate,
                                            productId: item.id,
                                            parentId: item.parentId,
                                            quantity,
                                            ownProduct: own === 1,
                                        })
                                        onOpenChange(false)
                                    }
                                }}
                            >
                                Aplicar
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}

export default EditProductDialog
