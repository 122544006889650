import { TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { AcceptTypes, ProductParent } from '@/store/productStructureSlice/type'
import { Product } from '@/types/Product'
import { Box } from 'lucide-react'
import { HTMLAttributes } from 'react'
import { useDrag } from 'react-dnd'

interface DraggableProductProps extends HTMLAttributes<HTMLDivElement> {
    product: Product
}

const DraggableProduct = ({ product, ...props }: DraggableProductProps) => {
    const [{ isDragging, canDrag }, drag] = useDrag<
        ProductParent,
        unknown,
        { isDragging: boolean; canDrag: boolean }
    >(
        () => ({
            type: AcceptTypes.PRODUCT,
            item: {
                ...product,
                parentId: null,
                id: product.SK_PRODUTO,
                type: AcceptTypes.PRODUCT,
                products: [],
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
                canDrag: product.DD_BLOQUEADO === 0,
            }),
            canDrag: () => {
                return product.DD_BLOQUEADO === 0
            },
        }),
        [product]
    )

    return (
        <div
            ref={drag}
            {...props}
            className={cn(
                'flex gap-4 absolute w-full items-center px-2 py-2 text-xs rounded-md overflow-hidden',
                isDragging && 'hover:bg-accent cursor-grabbing',
                canDrag ? 'hover:bg-accent cursor-pointer' : 'opacity-50'
            )}
        >
            <div>
                <Box size={18} />
            </div>
            <div className="flex-1 -space-y-1 overflow-hidden pointer-events-none">
                <div className="flex items-center gap-2">
                    <TypographyP className="w-full overflow-hidden text-sm whitespace-nowrap text-ellipsis pointer">
                        {product.NK_PRODUTO} - {product.DS_PRODUTO}
                    </TypographyP>
                    <span
                        className={cn(
                            'px-2 py-1 rounded-md',
                            product.DD_ORIGEM_DADO === 'PROTHEUS'
                                ? 'text-cyan-600 bg-cyan-50 dark:bg-cyan-600/30 dark:text-cyan-400'
                                : 'text-green-600 rounded-lg bg-green-50 dark:bg-emerald-600/30 dark:text-emerald-400'
                        )}
                    >
                        {product.DD_ORIGEM_DADO}
                    </span>
                </div>
                <TypographyP
                    className={cn(
                        'text-xs text-muted-foreground',
                        isDragging && 'text-muted-foreground'
                    )}
                >
                    {product.ABREVIATURA_EMPRESA}
                </TypographyP>
            </div>
        </div>
    )
}

export default DraggableProduct
