import { isAxiosError } from '@/api/business'
import { removeCost } from '@/api/business/stdCost'
import ConfirmDialog from '@/components/ConfirmDialog'
import { useToast } from '@/components/ui/use-toast'
import { stdCostKeys } from '@/queries/useStdCostsQuery'
import { useBaseStore } from '@/store'
import {
    stdCostSliceActionsSelector,
    stdCostSliceStateSelector,
} from '@/store/stdCostSlice'
import { ComponentCustomEntity } from '@/types/StandardCost'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface RemoveCostDialogProps {
    id: number
    description: string
    isOpen: boolean
    onClose: () => void
}

const RemoveCostDialog = ({
    id,
    description,
    isOpen,
    onClose,
}: RemoveCostDialogProps) => {
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const {
        selectedDate: date,
        selectedStdCostRow: selectedRow,
        stdCostData: { components, products },
        isAnualAvg,
    } = useBaseStore(stdCostSliceStateSelector)

    const { onSelectStdCostRow: selectRow } = useBaseStore(
        stdCostSliceActionsSelector
    )

    const productOrComponent =
        selectedRow && selectedRow?.isProduct
            ? products.entities[selectedRow.id]
            : components.entities[selectedRow!.id]

    const isExtraCost =
        (productOrComponent as ComponentCustomEntity)?.ESTRUTURA ===
            'EXTRA-COMPONENTE' ||
        (productOrComponent as ComponentCustomEntity)?.ESTRUTURA ===
            'EXTRA-PRODUTO'

    const { mutate, isLoading } = useMutation({
        mutationFn: removeCost,
        onMutate: () => {
            if (isExtraCost) {
                if (
                    (productOrComponent as ComponentCustomEntity)?._parentId &&
                    selectedRow
                ) {
                    selectRow({
                        id: (productOrComponent as ComponentCustomEntity)
                            ._parentId,
                        isProduct: !(
                            productOrComponent as ComponentCustomEntity
                        )._parentId,
                    })
                }
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(stdCostKeys.list(date.id, isAnualAvg))
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao remover custo',
                        description:
                            'Não foi possível remover o custo, tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    return (
        <ConfirmDialog
            onClose={onClose}
            isOpen={isOpen}
            title="Remover custo"
            onConfirm={() => {
                mutate(id)
            }}
            dialogType="destructive"
            isLoading={isLoading}
        >
            <p className="mt-2 text-base">{description}</p>
        </ConfirmDialog>
    )
}

export default RemoveCostDialog
