import { cva } from 'class-variance-authority'

export type Status =
    | 'PENDENTE'
    | 'ELABORACAO'
    | 'FINALIZADO'
    | 'APROVADO'
    | 'PARCIALMENTE APROVADO'
    | 'PARCIALMENTE FINALIZADO'

export const statusVariants = cva('', {
    variants: {
        status: {
            PENDENTE: 'bg-neutral-300',
            ELABORACAO: 'bg-amber-500',
            FINALIZADO: 'bg-primary',
            APROVADO: 'bg-green-500',
            'PARCIALMENTE FINALIZADO': 'bg-indigo-500',
            'PARCIALMENTE APROVADO': 'bg-teal-600',
        },
    },
})
export const statusTextVariants = cva('', {
    variants: {
        status: {
            PENDENTE: 'text-neutral-500',
            ELABORACAO: 'text-amber-500',
            FINALIZADO: 'text-primary',
            APROVADO: 'text-green-500',
            'PARCIALMENTE FINALIZADO': 'text-indigo-500',
            'PARCIALMENTE APROVADO': 'text-teal-600',
        },
    },
})

export const statusLocale = {
    APROVADO: 'Aprovado',
    ELABORACAO: 'Em elaboração',
    FINALIZADO: 'Finalizado',
    PENDENTE: 'Pendente',
    'PARCIALMENTE APROVADO': 'Parcialmente aprovado',
    'PARCIALMENTE FINALIZADO': 'Parcialmente finalizado',
} as const
