import instance from '..'
import {
    CreateBulkExtraCostProps,
    CreateBulkExtraCostReturn,
    CreateExtraCostProps,
} from './types'
import { ExtraCost } from '@/types/Costs'

export const fetchExtraCosts = async () => {
    const { data } = await instance.post<ExtraCost[]>(
        '/custopadrao/portalcusto/novocusto/custoextra'
    )

    return data
}

export const createExtraCost = async (props: CreateExtraCostProps) => {
    const { data } = await instance.put(
        '/custopadrao/portalcusto/novocusto/custoextra',
        {
            ...props,
        }
    )

    return data
}

export const createBulkExtraCost = async (
    props: CreateBulkExtraCostProps[]
) => {
    const { data } = await instance.put<CreateBulkExtraCostReturn[]>(
        '/custopadrao/portalcusto/novocusto/carga/custoextra',
        props
    )

    return data
}
