import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteProductionVolume } from '@/api/business/productionVolume'
import { useToast } from '@/components/ui/use-toast'
import { productionVolumeKeys } from '@/queries/useProductionVolumeQuery'
import { isAxiosError } from '@/api/business'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { Button } from '@/components/ui/button'
import { RowSelectionState } from '@tanstack/react-table'

interface DeleteDialogProps {
    ids: number[]
    isOpen: boolean
    onClose: () => void
    setSelectedRows: (rowSelection: RowSelectionState) => void
}

const DeleteDialog = ({
    ids,
    isOpen,
    onClose,
    setSelectedRows,
}: DeleteDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading } = useMutation({
        mutationFn: deleteProductionVolume,
        onSuccess: () => {
            queryClient.invalidateQueries(productionVolumeKeys.lists())
            onClose()
            toast({
                title: 'Volume de produção removido com sucesso',
            })

            setSelectedRows({})
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar volume de produção',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const onConfirm = () => {
        mutate({ SK_VOLUME_PRODUCAO: ids })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Excluir moeda</DialogTitle>
                </DialogHeader>
                <div>
                    <TypographyP>
                        Realmente deseja excluir{' '}
                        <span className="font-bold">{ids.length}</span> itens?
                    </TypographyP>
                    <DialogFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button
                            variant="destructive"
                            size="default"
                            disabled={isLoading}
                            onClick={onConfirm}
                        >
                            Confirmar
                        </Button>
                    </DialogFooter>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteDialog
