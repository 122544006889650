import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useRef, useState } from 'react'
import { ProductStructure } from '@/types/Product'
import { useVirtualizer } from '@tanstack/react-virtual'
import { DebouncedInput } from '@/components/DebouncedInput'
import { cn } from '@/lib/utils'
import { useToast } from '@/components/ui/use-toast'
import { AcceptTypes } from '@/store/productStructureSlice/type'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { TypographyP } from '@/components/ui/typography'

type FormattedData = ProductStructure

const stateSelector = (state: Store) => ({
    isOpen: state.productStructureSlice.state.isCopyStructureDialogOpen,
    droppedStructures:
        state.productStructureSlice.state.droppedProductStructure,
    onOpenChange:
        state.productStructureSlice.actions.onChangeCopyProductStructureDialog,
    onCopy: state.productStructureSlice.actions.copyStructure,
})

const CopyStructureDialog = () => {
    const { droppedStructures, isOpen, onOpenChange, onCopy } =
        useBaseStore(stateSelector)

    const { toast } = useToast()

    const scrollElementRef = useRef<HTMLDivElement>(null)
    const [inputValue, setInputValue] = useState('')

    const { data, isLoading, isError } = useProductStructureQuery({
        enabled: isOpen,
    })

    const [formattedData, setFormattedData] = useState<FormattedData[]>([])
    const [filteredItems, setFilteredItems] = useState<FormattedData[]>([])

    const count = filteredItems.length

    const virtualizer = useVirtualizer({
        count,
        getScrollElement: () => scrollElementRef.current,
        estimateSize: () => 40,
    })

    const items = virtualizer.getVirtualItems()

    const filterItems = (items: typeof formattedData, value: string) => {
        const getSanitizedString = (string: string) =>
            string.toLowerCase().trim()

        return setFilteredItems(
            items.filter(
                (item) =>
                    getSanitizedString(item.PRODUTO_ESTRUTURA || '').includes(
                        getSanitizedString(value)
                    ) ||
                    getSanitizedString(
                        item.SK_EMPRESA.toString() || ''
                    ).includes(getSanitizedString(value)) ||
                    getSanitizedString(item.ABREVIATURA_EMPRESA || '').includes(
                        getSanitizedString(value)
                    ) ||
                    getSanitizedString(item.NK_PRODUTO || '').includes(
                        getSanitizedString(value)
                    )
            )
        )
    }

    const onInputChange = (value: string | number) => {
        setInputValue(value as string)
        if (formattedData) {
            filterItems(formattedData, value as string)
        }
    }

    useEffect(() => {
        if (data) {
            const uniqueProducts: FormattedData[] = []
            data.forEach((product) => {
                const productFound = uniqueProducts.find(
                    (prod) =>
                        prod.SK_PRODUTO_ESTRUTURA ===
                            product.SK_PRODUTO_ESTRUTURA &&
                        prod.DD_ORIGEM_DADO === product.DD_ORIGEM_DADO
                )

                if (!productFound) {
                    uniqueProducts.push({
                        ABREVIATURA_EMPRESA: product.ABREVIATURA_EMPRESA,
                        NK_PRODUTO: product.NK_PRODUTO,
                        PRODUTO_ESTRUTURA: product.PRODUTO_ESTRUTURA,
                        SK_EMPRESA: product.SK_EMPRESA,
                        SK_ESTRUTURA_DE_PRODUTO:
                            product.SK_ESTRUTURA_DE_PRODUTO,
                        SK_PRODUTO: product.SK_PRODUTO_ESTRUTURA,
                        SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                        UM_PRODUTO_ESTRUTURA: product.UM_PRODUTO_ESTRUTURA,
                        DD_ORIGEM_DADO: product.DD_ORIGEM_DADO,
                        COMPONENTES: data.filter(
                            (comp) =>
                                comp.SK_PRODUTO_ESTRUTURA ===
                                    product.SK_PRODUTO_ESTRUTURA &&
                                comp.DD_ORIGEM_DADO === product.DD_ORIGEM_DADO
                        ),
                        DD_PRODUTO_PROPRIO: product.DD_PRODUTO_PROPRIO,
                        DD_ESTRUTURA_PADRAO: product.DD_ESTRUTURA_PADRAO,
                    })
                }
            })

            setFormattedData(uniqueProducts)
            setFilteredItems(uniqueProducts)
        }
    }, [data])

    useEffect(() => {
        if (!isOpen) setInputValue('')
    }, [isOpen])

    const onClickProduct = (product: ProductStructure) => {
        if (
            droppedStructures.some((structure) =>
                structure.productStructures.some(
                    (prod) => prod.id === product.SK_PRODUTO_ESTRUTURA
                )
            )
        ) {
            return toast({
                title: 'Essa estrutura já foi copiada',
                variant: 'destructive',
            })
        }

        onCopy({
            id: product.SK_PRODUTO_ESTRUTURA,
            parentId: null,
            SK_EMPRESA: product.SK_EMPRESA.toString(),
            ABREVIATURA_EMPRESA: product.ABREVIATURA_EMPRESA,
            type: AcceptTypes.PRODUCT,
            DD_UM: product.UM_PRODUTO_ESTRUTURA,
            DS_PRODUTO: product.PRODUTO_ESTRUTURA,
            NK_PRODUTO: product.NK_PRODUTO,
            SK_ESTRUTURA_DE_PRODUTO: product.SK_ESTRUTURA_DE_PRODUTO,
            SK_PRODUTO: product.SK_PRODUTO_ESTRUTURA,
            UM_PRODUTO_ESTRUTURA: product.UM_PRODUTO_ESTRUTURA,
            products: product.COMPONENTES.map((component) => ({
                id: `${component.DS_REVISAO_INICIAL}${component.DS_REVISAO_FINAL}${component.SK_PRODUTO_COMPONENTE}`,
                products: [],
                parentId: product.SK_PRODUTO_ESTRUTURA,
                type: AcceptTypes.PRODUCT,
                SK_EMPRESA: product.SK_EMPRESA.toString(),
                ABREVIATURA_EMPRESA: product.ABREVIATURA_EMPRESA,
                DD_UM: product.UM_PRODUTO_ESTRUTURA,
                DS_PRODUTO: component.PRODUTO_COMPONENTE,
                NK_PRODUTO: component.NK_COMPONENTE,
                SK_PRODUTO: component.SK_PRODUTO_COMPONENTE,
                SK_ESTRUTURA_DE_PRODUTO: component.SK_ESTRUTURA_DE_PRODUTO,
                SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                UM_PRODUTO_ESTRUTURA: component.UM_PRODUTO_COMPONENTE,
                QTD_COMPONENTE: component.QTD_COMPONENTE,
                SK_TEMPO_INICIAL: component.SK_TEMPO_INICIAL,
                SK_TEMPO_FINAL: component.SK_TEMPO_FINAL,
                DS_REVISAO_INICIAL: component.DS_REVISAO_INICIAL,
                DS_REVISAO_FINAL: component.DS_REVISAO_FINAL,
            })),
        })
        onOpenChange(false)
    }

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            <DialogContent className="flex flex-col overflow-hidden md:max-w-3xl h-4/6">
                <DialogHeader>
                    <DialogTitle>Copiar estrutura</DialogTitle>
                    <DialogDescription>
                        Copiar uma estrutura existente.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col flex-1 w-full overflow-hidden">
                    <div className="w-full">
                        <DebouncedInput
                            className="w-full mb-4"
                            type="text"
                            placeholder="Buscar por código ou produto..."
                            value={inputValue}
                            onChange={onInputChange}
                        />
                    </div>
                    {isLoading && (
                        <TypographyP className="text-center">
                            Carregando estruturas...
                        </TypographyP>
                    )}
                    {isError && (
                        <TypographyP className="text-sm text-center text-red-500">
                            Erro ao carregar estruturas
                        </TypographyP>
                    )}
                    <div
                        ref={scrollElementRef}
                        className="flex-1 w-full p-2 overflow-y-auto rounded-md bg-slate-50 dark:bg-zinc-900"
                    >
                        <div
                            className="relative w-full"
                            style={{
                                height: `${virtualizer.getTotalSize()}px`,
                            }}
                        >
                            <div
                                className="w-full"
                                style={{
                                    transform: `translateY(${
                                        items[0]?.start ?? 0
                                    }px)`,
                                }}
                            >
                                {items.map((virtualRow) => {
                                    const product =
                                        filteredItems[virtualRow.index]

                                    return (
                                        <div
                                            key={virtualRow.index}
                                            className="p-2 text-sm border-b cursor-pointer hover:bg-slate-200 dark:hover:bg-accent"
                                            onClick={() =>
                                                onClickProduct(product)
                                            }
                                        >
                                            <TypographyP className="flex items-center justify-between">
                                                <span>
                                                    {product.NK_PRODUTO} -{' '}
                                                    {product.PRODUTO_ESTRUTURA}
                                                </span>
                                                <span
                                                    className={cn(
                                                        'px-2 py-1 rounded-md text-xs',
                                                        product.DD_ORIGEM_DADO ===
                                                            'PROTHEUS'
                                                            ? 'text-cyan-600 bg-cyan-50 dark:bg-cyan-600/30 dark:text-cyan-400'
                                                            : 'text-green-600 rounded-lg bg-green-50 dark:bg-emerald-600/30 dark:text-emerald-400'
                                                    )}
                                                >
                                                    {product.DD_ORIGEM_DADO}
                                                </span>
                                            </TypographyP>
                                            <TypographyP className="text-muted-foreground">
                                                <span>
                                                    {product.COMPONENTES.length}{' '}
                                                    componentes
                                                </span>
                                            </TypographyP>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default CopyStructureDialog
