import { getInitials } from '@/utils/stringFormatter'
import { Menu } from 'lucide-react'
import { Avatar, AvatarFallback } from '../ui/avatar'
import ProfileMenu from './components/ProfileMenu'
import { useBaseStore } from '@/store'
import { authSliceStateSelector } from '@/store/authSlice'
import {
    appConfigSliceActionsSelector,
    appConfigSliceStateSelector,
} from '@/store/appConfigSlice'
import { TypographyP } from '../ui/typography'
const Header = () => {
    const { user } = useBaseStore(authSliceStateSelector)
    const { isSidenavOpen } = useBaseStore(appConfigSliceStateSelector)
    const { setSidenavOpen } = useBaseStore(appConfigSliceActionsSelector)

    return (
        <div className="flex items-center justify-between w-full px-4 border-b h-header">
            <div className="flex items-center gap-4">
                <button onClick={() => setSidenavOpen(!isSidenavOpen)}>
                    <Menu size={18} />
                </button>
            </div>
            <div className="flex items-center h-full gap-4 ml-auto">
                <ProfileMenu
                    className="p-4 hover:bg-accent"
                    trigger={
                        <div className="flex items-center h-full gap-2 pointer-events-none">
                            <Avatar className="w-6 h-6 text-xs">
                                <AvatarFallback className="bg-primary-500 text-gray-50">
                                    {getInitials(user || '')}
                                </AvatarFallback>
                            </Avatar>
                            <TypographyP className="hidden pointer-events-none sm:block">
                                {user}
                            </TypographyP>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default Header
