import { Separator } from '@/components/ui/separator'
import { TypographyP } from '@/components/ui/typography'
import { ReactNode } from 'react'

interface TabSectionProps {
    title: ReactNode
    actionButton?: ReactNode
    children: ReactNode
}

const TabSection = ({ title, actionButton, children }: TabSectionProps) => {
    return (
        <div>
            <div className="flex items-center justify-between mb-1.5">
                {typeof title == 'string' ? (
                    <TypographyP className="font-semibold">{title}</TypographyP>
                ) : (
                    title
                )}
                {actionButton}
            </div>
            <Separator className="mb-2" />
            <div className="p-2 rounded-lg bg-accent">{children}</div>
        </div>
    )
}

export default TabSection
