import Details from './components/Details'
import StdCostsTable from './components/StdCostsTable'
import { useBaseStore } from '@/store'
import { useGetDetailsOpenState } from '@/store/stdCostSlice'
import StdCostHeader from './components/StdCostHeader'
import StdCostStatus from './components/StdCostStatus'
import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    stdCostData: state.stdCostSlice.state.stdCostData,
})

const StdCosts = () => {
    const isDetailsOpen = useBaseStore(useGetDetailsOpenState)
    const { stdCostData } = useBaseStore(stateSelector)

    return (
        <>
            <div className="flex w-full h-full">
                <div className="flex flex-col flex-1 p-3 overflow-hidden md:p-4">
                    <StdCostHeader />
                    <StdCostStatus />
                    {stdCostData.products.ids.length > 0 && (
                        <div className="overflow-hidden">
                            <StdCostsTable />
                        </div>
                    )}
                </div>
                {isDetailsOpen && <Details />}
            </div>
        </>
    )
}

export default StdCosts
