import { Boxes, MinusCircle, Plus } from 'lucide-react'
import { useMemo } from 'react'
import TabSection from '../../../../../TabSection'
import { currencyFormat } from '@/utils/stringFormatter'
import { IdToRemove } from '../..'
import { ProductEntity } from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'
import { TypographyP } from '@/components/ui/typography'

interface ProductProps {
    product: ProductEntity
    onAddExtraCostDialogOpen: () => void
    onRemoveCostDialogOpen: () => void
    setIdToRemove: (props: IdToRemove) => void
}

const Product = ({
    product,
    onAddExtraCostDialogOpen,
    onRemoveCostDialogOpen,
    setIdToRemove,
}: ProductProps) => {
    const {
        stdCostData: { components },
    } = useBaseStore(stdCostSliceStateSelector)

    const extraCosts = useMemo(
        () =>
            product.COMPONENTES.map((id) => components.entities[id]).filter(
                (component) =>
                    component.ESTRUTURA === 'EXTRA-COMPONENTE' ||
                    component.ESTRUTURA === 'EXTRA-PRODUTO'
            ) || [],
        [product]
    )

    return (
        <>
            <TabSection title={'Produto'}>
                <TypographyP className="flex items-center gap-2">
                    <Boxes
                        size={20}
                        strokeWidth={1.5}
                        className="text-primary-700 dark:text-primary"
                    />
                    <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-primary-700 dark:text-primary">
                        {product?.PRODUTO_ESTRUTURA}
                    </span>
                </TypographyP>
            </TabSection>
            <TabSection
                title="Custos extras"
                actionButton={
                    product?.MANIPULA_PRODUTO_ESTRUTURA === 1 && (
                        <button onClick={onAddExtraCostDialogOpen}>
                            <Plus size={16} />
                        </button>
                    )
                }
            >
                {extraCosts.length > 0 ? (
                    <table>
                        <thead>
                            <tr className="text-left text-primary-600">
                                <th className="px-2">
                                    <TypographyP className="text-sm font-semibold text-primary-600 dark:text-primary">
                                        Descrição
                                    </TypographyP>
                                </th>
                                <th className="px-2">
                                    <TypographyP className="text-sm font-semibold text-primary-600 dark:text-primary">
                                        Valor
                                    </TypographyP>
                                </th>
                                <th className="px-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {extraCosts.map((component) => (
                                <tr key={component.SK_PRODUTO_COMPONENTE}>
                                    <td className="px-2">
                                        <TypographyP>
                                            {component.PRODUTO_COMPONENTE}
                                        </TypographyP>
                                    </td>
                                    <td className="px-2">
                                        <TypographyP>
                                            {currencyFormat(
                                                component.ULTIMO_PRECO,
                                                4
                                            )}
                                        </TypographyP>
                                    </td>
                                    <td className="px-2">
                                        {product?.MANIPULA_PRODUTO_ESTRUTURA &&
                                        product.DD_ORIGEM_DADO === 'MANUAL' ? (
                                            <button
                                                className="text-red-500"
                                                onClick={() => {
                                                    setIdToRemove({
                                                        id: Number(
                                                            component.SK_PRODUTO_COMPONENTE
                                                        ),
                                                        isExtraCost: true,
                                                    })
                                                    onRemoveCostDialogOpen()
                                                }}
                                            >
                                                <MinusCircle size={14} />
                                            </button>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <TypographyP className="text-center text-primary-600 dark:text-primary">
                        Não há custo extra
                    </TypographyP>
                )}
            </TabSection>
        </>
    )
}

export default Product
