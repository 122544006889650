import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
} from '@/components/ui/form'
import { useFormContext } from 'react-hook-form'
import { AddProductSchema } from '../../schemas/products'
import { useUnityOfMeasurementQuery } from '@/queries/useUnityOfMeasurementQuery'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

const UMSelect = () => {
    const form = useFormContext<AddProductSchema>()

    const { data, isLoading, isError } = useUnityOfMeasurementQuery({
        enabled: true,
    })

    const disabled = isLoading || isError

    return (
        <FormField
            name="DD_UM"
            control={form.control}
            render={({ field }) => {
                return (
                    <FormItem>
                        <FormLabel>Unidade de medida</FormLabel>
                        <FormControl>
                            <Select
                                onValueChange={(value) => {
                                    field.onChange(value)
                                }}
                                value={field.value}
                                disabled={disabled}
                            >
                                <SelectTrigger>
                                    <SelectValue
                                        placeholder={
                                            isLoading
                                                ? 'Carregando...'
                                                : isError
                                                ? 'Erro'
                                                : 'Selecione uma classificação'
                                        }
                                    />
                                </SelectTrigger>
                                <SelectContent className="max-h-[200px]">
                                    {data?.map((um) => (
                                        <SelectItem
                                            key={um.DS_SIGLA}
                                            value={um.DS_SIGLA}
                                        >
                                            {um.DS_UM} - {um.DS_SIGLA}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </FormControl>
                    </FormItem>
                )
            }}
        />
    )
}

export default UMSelect
