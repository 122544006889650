import { CostViewAPI, ProductAvgCost } from '@/types/Product'
import instance from '..'
import {
    CreateCostViewProps,
    DeleteCostViewProps,
    ExtraCostPayload,
    FetchCostViewProps,
    FetchStdCostFreezeLogProps,
    FreezeLog,
    FreezeStdCostProps,
    HistoryPayload,
    InformedCostPayload,
    UpdateCostViewProps,
    UpdatedAt,
} from './type'
import { CostHistory } from '@/types/CostHistory'
import { ProductAPIResponse, View } from '@/types/StandardCost'

export const fetchStdCost = async ({
    SK_TEMPO,
    VISAO_MEDIA_ANUAL,
}: {
    SK_TEMPO: number
    VISAO_MEDIA_ANUAL: boolean
}) => {
    const { data } = await instance.post<ProductAPIResponse[]>(
        '/custopadrao/portalcusto/custopadrao/ultimoprecoeinformado',
        { SK_TEMPO, VISAO_MEDIA_ANUAL }
    )

    return data
}

export const fetchAvgCost = async ({
    SK_TEMPO_INICIO,
    SK_TEMPO_FIM,
}: {
    SK_TEMPO_INICIO: number
    SK_TEMPO_FIM: number
}) => {
    const { data } = await instance.post<ProductAvgCost[]>(
        '/custopadrao/portalcusto/custopadrao/precomedio',
        { SK_TEMPO_INICIO, SK_TEMPO_FIM }
    )

    return data
}

export const createExtraCost = async (props: ExtraCostPayload) => {
    const response = await instance.put(
        '/custopadrao/portalcusto/novocusto/custoextra',
        { ...props }
    )
    return response
}

export const createInformedCost = async (props: InformedCostPayload) => {
    const response = await instance.put(
        '/custopadrao/portalcusto/novocusto/informado',
        { ...props }
    )
    return response
}

export const removeCost = async (SK_NOVO_CUSTO: number) => {
    const response = await instance.delete(
        '/custopadrao/portalcusto/novocusto',
        {
            data: { SK_NOVO_CUSTO },
        }
    )

    return response
}

export const fetchHistory = async (props: HistoryPayload) => {
    const response = await instance.post<CostHistory[]>(
        '/custopadrao/portalcusto/novocusto/historico',
        { ...props }
    )

    return response.data
}

export const fetchCostView = async ({ SK_TEMPO }: FetchCostViewProps) => {
    const { data } = await instance.post<View[]>(
        '/custopadrao/portalcusto/configuracaoexibicao/custopadrao',
        {
            SK_TEMPO,
        }
    )

    return data
}

export const createCostView = async (props: CreateCostViewProps[]) => {
    const { data } = await instance.put<CostViewAPI[]>(
        '/custopadrao/portalcusto/configuracaoexibicao/custopadrao',
        props
    )

    return data
}

export const updateCostView = async (props: UpdateCostViewProps) => {
    const { data } = await instance.patch<CostViewAPI[]>(
        '/custopadrao/portalcusto/configuracaoexibicao/custopadrao',
        props
    )

    return data
}

export const deleteCostView = async (props: DeleteCostViewProps) => {
    const { data } = await instance.delete<CostViewAPI[]>(
        '/custopadrao/portalcusto/configuracaoexibicao/custopadrao',
        { data: { ...props } }
    )

    return data
}

export const fetchStdCostUpdateDate = async () => {
    const { data } = await instance.post<UpdatedAt[]>(
        '/ultimaatualizacao/portalcusto/ultimaatualizacao'
    )
    return data
}

export const freezeStdCost = async ({ costs, date }: FreezeStdCostProps) => {
    const { data } = await instance.put(
        '/custopadrao/portalcusto/custopadrao/congelacusto',
        {
            PERIODO: date,
            CUSTOS: costs,
        }
    )
    return data
}
export const fetchStdCostFreezeLog = async ({
    date,
}: FetchStdCostFreezeLogProps) => {
    const { data } = await instance.post<FreezeLog[]>(
        '/custopadrao/portalcusto/custopadrao/logcustocongelado',
        {
            PERIODO: date,
        }
    )
    return data
}
