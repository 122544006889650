// import { isAxiosError } from '@/api/business'
import { fetchCostView } from '@/api/business/stdCost'
import { FetchCostViewProps } from '@/api/business/stdCost/type'
// import { useToast } from '@/components/ui/use-toast'
import { useQuery } from '@tanstack/react-query'

interface UseCostViewQuery extends FetchCostViewProps {}

export const costViewKeys = {
    all: ['costView'] as const,
    lists: () => [...costViewKeys.all, 'list'] as const,
    list: (date: number) => [...costViewKeys.lists(), date] as const,
    details: () => [...costViewKeys.all, 'detail'] as const,
    detail: (params: string) => [...costViewKeys.details(), params] as const,
}

export const useCostViewQuery = ({ SK_TEMPO }: UseCostViewQuery) => {
    // const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchCostView({ SK_TEMPO }),
        queryKey: costViewKeys.list(SK_TEMPO),
        // onError: (err) => {
        //     if (isAxiosError(err)) {
        //         if (
        //             err.response?.status === 401 ||
        //             err.response?.status === 402
        //         ) {
        //             toast({
        //                 title: 'Sem permissão de acesso',
        //                 description:
        //                     'O seu perfil de usuário não possui permissão para acessar. Caso acredite que seja um erro, solicitar acessos.',
        //                 variant: 'destructive',
        //             })
        //         }
        //     }
        // },
    })
}
