import { CurrencyDS, CurrencyValue } from '@/types/Currency'
import instance from '..'
import {
    CreateCurrencyValueProps,
    DeleteCurrencyValueProps,
    UpdateCurrencyValueProps,
} from './types'

export const fetchCurrencies = async () => {
    const { data } = await instance.post<CurrencyDS[]>(
        '/moeda/portalcusto/moeda'
    )

    return data
}

export const fetchCurrencyValues = async () => {
    const { data } = await instance.post<CurrencyValue[]>(
        '/moeda/portalcusto/valormoeda'
    )

    return data
}

export const createCurrencyValue = async (props: CreateCurrencyValueProps) => {
    const { data } = await instance.put('/moeda/portalcusto/valormoeda', {
        ...props,
    })

    return data
}

export const updateCurrencyValue = async (props: UpdateCurrencyValueProps) => {
    const { data } = await instance.patch('/moeda/portalcusto/valormoeda', {
        ...props,
    })

    return data
}
export const deleteCurrencyValue = async (props: DeleteCurrencyValueProps) => {
    const { data } = await instance.delete('/moeda/portalcusto/valormoeda', {
        data: { ...props },
    })

    return data
}
