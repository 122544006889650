import Product from './components/Product'
import Component from './components/Component'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'

const DetailsTab = () => {
    const { selectedStdCostRow: selectedRow } = useBaseStore(
        stdCostSliceStateSelector
    )

    const isProduct = selectedRow?.isProduct

    return (
        <div className="flex flex-col h-full gap-6 p-4 rounded-md">
            {selectedRow && (
                <>
                    {isProduct ? (
                        <Product productId={selectedRow.id} />
                    ) : (
                        <Component componentId={selectedRow.id} />
                    )}
                </>
            )}
        </div>
    )
}

export default DetailsTab
