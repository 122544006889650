import { Product, ProductStructureFlat } from '@/types/Product'
import { Slice } from '../type'

export enum AcceptTypes {
    PRODUCT = 'PRODUCT',
    PARENT = 'PARENT',
    CHILD = 'CHILD',
}

type ProductWithId = Partial<Omit<Product, 'SK_EMPRESA'>> &
    Partial<Omit<ProductStructureFlat, 'SK_EMPRESA'>> & {
        id: string
        SK_EMPRESA: string
        ERROR?: boolean
    }

export type ProductChild = ProductWithId & {
    parentId: string
    type: AcceptTypes.CHILD | AcceptTypes.PRODUCT
    products: ProductChild[]
}
export type ProductParent = ProductWithId & {
    parentId: null
    type: AcceptTypes.PARENT | AcceptTypes.PRODUCT
    products: ProductChild[]
}

interface DroppedProductStructure {
    companyName: string
    companyId: string
    productStructures: ProductParent[]
}

export interface ProductStructureStore {
    droppedProductStructure: DroppedProductStructure[]
    selectedProductStructure: {
        productId: string
        parentId: string | null
    } | null
    isEditProductStructureDialogOpen: boolean
    isCopyStructureDialogOpen: boolean
}

export type ProductStructureActions = {
    dropItem: (item: ProductChild | ProductParent) => void
    removeItem: (productId: string, parentId: string | null) => void
    copyStructure: (structure: ProductParent) => void
    editItem: ({
        quantity,
        initial,
        initialDate,
        final,
        finalDate,
        productId,
        parentId,
        ownProduct,
    }: {
        quantity: string
        initial: string
        initialDate: Date
        final: string
        finalDate: Date
        productId: string
        parentId: string | null
        ownProduct: boolean
    }) => void
    selectItem: (productId: string, parentId: string | null) => void
    onChangeEditProductStructureDialog: (isOpen: boolean) => void
    onChangeCopyProductStructureDialog: (isOpen: boolean) => void
    setErrorOnProduct: (products: { parentId: string; id: string }[]) => void
    resetErrors: () => void
}

export type ProductStructureSlice = Slice<
    ProductStructureStore,
    ProductStructureActions
>
