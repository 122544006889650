import instance from '..'
import {
    CreateBulkInformedCostProps,
    CreateBulkInformedCostReturn,
    CreateInformedCostProps,
    DeleteInformedCostProps,
    UpdateInformedCostProps,
} from './types'
import { InformedCost } from '@/types/Costs'

export const fetchInformedCosts = async () => {
    const { data } = await instance.post<InformedCost[]>(
        '/custopadrao/portalcusto/novocusto/informado'
    )

    return data
}

export const createInformedCost = async (props: CreateInformedCostProps) => {
    const { data } = await instance.put(
        '/custopadrao/portalcusto/novocusto/informado',
        {
            ...props,
        }
    )

    return data
}

export const createBulkInformedCost = async ({
    products,
}: CreateBulkInformedCostProps) => {
    const { data } = await instance.put<CreateBulkInformedCostReturn[]>(
        '/custopadrao/portalcusto/novocusto/carga/informado',
        { PRODUTOS: products }
    )

    return data
}

export const updateInformedCost = async (props: UpdateInformedCostProps) => {
    const { data } = await instance.patch(
        '/custopadrao/portalcusto/novocusto',
        {
            ...props,
        }
    )

    return data
}
export const deleteInformedCost = async (props: DeleteInformedCostProps) => {
    const { data } = await instance.delete(
        '/custopadrao/portalcusto/novocusto',
        {
            data: { ...props },
        }
    )

    return data
}
