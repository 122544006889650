import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import StructureStatus from '@/components/StructureStatus'
import { getCostType } from '@/utils/standardCost'
import { currencyFormat, numberFormat } from '@/utils/stringFormatter'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'
import { statusLocale } from '@/types/Status'
import { cn } from '@/lib/utils'
import {
    ColumnFiltersState,
    createColumnHelper,
    GroupingState,
} from '@tanstack/react-table'
import {
    Componentes,
    FlatStructureStatus,
    StructureStatus as StructureStatusType,
} from '@/api/business/structureStatus/type'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type ComponentsTableProps = {
    data: StructureStatusType
}

const ComponentsTable = ({ data }: ComponentsTableProps) => {
    const [grouping] = useState<GroupingState>(['DS_PRODUTO_COMPONENTE'])

    const [search] = useSearchParams()

    const componentId = search.get('component')
    const costId = search.get('cost')

    const columnHelper = createColumnHelper<FlatStructureStatus>()

    type StatusTable = TableData<FlatStructureStatus>

    const flattenStructureStatus = useCallback(
        (data: StructureStatusType): FlatStructureStatus[] => {
            return data.COMPONENTES.flatMap((component) => {
                const costs = [
                    ...component.CUSTOS.EXTRA,
                    ...component.CUSTOS.INFORMADO,
                ]

                const common: Omit<
                    StructureStatusType & Componentes,
                    'COMPONENTES' | 'CUSTOS'
                > = {
                    CENARIO_CUSTO_EXTRA: data.CENARIO_CUSTO_EXTRA,
                    CENARIO_CUSTO_INFORMADO: data.CENARIO_CUSTO_INFORMADO,
                    CENARIO_ESTRUTURA: data.CENARIO_ESTRUTURA,
                    DS_PRODUTO_ESTRUTURA: data.DS_PRODUTO_ESTRUTURA,
                    NK_PRODUTO_ESTRUTURA: data.NK_PRODUTO_ESTRUTURA,
                    SK_EMPRESA: data.SK_EMPRESA,
                    SK_PRODUTO_ESTRUTURA: data.SK_PRODUTO_ESTRUTURA,
                    APROVACAO_ESTRUTURA: component.APROVACAO_ESTRUTURA,
                    DD_ESTRUTURA_PADRAO: component.DD_ESTRUTURA_PADRAO,
                    DD_ORIGEM_DADO: component.DD_ORIGEM_DADO,
                    DD_PRODUTO_PROPRIO: component.DD_PRODUTO_PROPRIO,
                    DS_PRODUTO_COMPONENTE: component.DS_PRODUTO_COMPONENTE,
                    DS_REVISAO_FINAL: component.DS_REVISAO_FINAL,
                    DS_REVISAO_INICIAL: component.DS_REVISAO_INICIAL,
                    NK_PRODUTO_COMPONENTE: component.NK_PRODUTO_COMPONENTE,
                    QTD_COMPONENTE: component.QTD_COMPONENTE,
                    SK_PRODUTO_COMPONENTE: component.SK_PRODUTO_COMPONENTE,
                    SK_TEMPO_FINAL: component.SK_TEMPO_FINAL,
                    SK_TEMPO_INICIAL: component.SK_TEMPO_INICIAL,
                    ABREVIATURA_EMPRESA: data.ABREVIATURA_EMPRESA,
                    DD_BLOQUEADO: component.DD_BLOQUEADO,
                }

                return costs.length > 0
                    ? costs.map((cost) => ({
                          ...common,
                          ...cost,
                      }))
                    : [common as FlatStructureStatus]
            })
        },
        []
    )

    const flattenData = useMemo(() => {
        if (data) return flattenStructureStatus(data)
        return []
    }, [data])

    const product = useMemo(
        () =>
            flattenData.find(
                (prod) => prod.SK_PRODUTO_COMPONENTE === componentId
            ),
        [componentId, flattenData]
    )

    const cost = useMemo(
        () => flattenData.find((cost) => cost.SK_NOVO_CUSTO === Number(costId)),
        [costId, data]
    )

    useEffect(() => {
        const filters: ColumnFiltersState = []

        if (product) {
            filters.push({
                id: 'DS_PRODUTO_COMPONENTE',
                value: product.NK_PRODUTO_COMPONENTE,
            })
        }

        if (cost) {
            filters.push({
                id: 'DD_TIPO_CUSTO',
                value: getCostType(cost.DD_TIPO_CUSTO),
            })
        }
    }, [product, cost])

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                ({ APROVACAO_CUSTO }) =>
                    statusLocale[APROVACAO_CUSTO?.DS_STATUS],
                {
                    header: 'Status',
                    id: 'STATUS_COMPONENTE',
                    size: 100,
                    cell: ({ row }) => (
                        <StructureStatus
                            status={row.original.APROVACAO_CUSTO?.DS_STATUS}
                        />
                    ),
                    aggregatedCell: ({ row }) => {
                        return (
                            <StructureStatus
                                status={
                                    row.original.APROVACAO_ESTRUTURA.DS_STATUS
                                }
                            />
                        )
                    },
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor(
                ({ DS_PRODUTO_COMPONENTE, NK_PRODUTO_COMPONENTE }) =>
                    `${DS_PRODUTO_COMPONENTE} (${NK_PRODUTO_COMPONENTE})`,
                {
                    id: 'DS_PRODUTO_COMPONENTE',
                    header: 'Componente',
                    size: 400,
                    cell: ({ row, getValue }) => {
                        const { SK_PRODUTO_COMPONENTE, DD_BLOQUEADO } =
                            row.original

                        return (
                            <div
                                className={cn(
                                    'text-primary flex items-center gap-2',
                                    SK_PRODUTO_COMPONENTE === componentId &&
                                        'font-bold'
                                )}
                            >
                                {getValue()}
                                {DD_BLOQUEADO && (
                                    <span className="p-1 text-xs text-red-500 rounded-sm bg-red-50">
                                        Bloqueado
                                    </span>
                                )}
                            </div>
                        )
                    },
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor('DS_CUSTO', {
                id: 'DS_CUSTO',
                header: 'Descrição',
                size: 130,
                cell: ({ row, getValue }) => {
                    const { SK_NOVO_CUSTO } = row.original

                    return (
                        <div
                            className={cn(
                                SK_NOVO_CUSTO === Number(costId) &&
                                    'text-primary font-bold flex'
                            )}
                        >
                            <div className="flex-1 overflow-hidden text-ellipsis">
                                {getValue()}
                            </div>
                        </div>
                    )
                },
                enableGrouping: false,
            }),
            columnHelper.accessor(
                ({ DD_TIPO_CUSTO }) => getCostType(DD_TIPO_CUSTO),
                {
                    id: 'DD_TIPO_CUSTO',
                    header: 'Tipo status',
                    size: 130,
                    cell: ({ row, getValue }) => {
                        const { SK_NOVO_CUSTO } = row.original

                        return (
                            <div
                                className={cn(
                                    SK_NOVO_CUSTO === Number(costId) &&
                                        'text-primary font-bold'
                                )}
                            >
                                {getValue()}
                            </div>
                        )
                    },
                    aggregatedCell: () => {
                        return 'Estrutura'
                    },
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor(
                ({ VL_CUSTO_ORCADO }) =>
                    currencyFormat(Number(VL_CUSTO_ORCADO) || 0, 4),
                {
                    id: 'VL_CUSTO_ORCADO',
                    header: 'Custo',
                    size: 100,
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor(
                ({ QTD_CUSTO }) => numberFormat(Number(QTD_CUSTO) || 0, 4),
                {
                    id: 'QTD_CUSTO',
                    header: 'Qtde custo',
                    size: 100,
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor(
                ({ SK_TEMPO }) =>
                    SK_TEMPO
                        ? format(getDateFromId(SK_TEMPO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'SK_TEMPO',
                    header: 'Data',
                    size: 100,
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor('DD_TIPO_FRETE', {
                id: 'DD_TIPO_FRETE',
                header: 'Frete',
                size: 100,
                enableGrouping: false,
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 100,
                enableGrouping: false,
            }),
            columnHelper.accessor('APROVACAO_CUSTO.NM_USUARIO', {
                id: 'APROVACAO_CUSTO.NM_USUARIO.NM_USUARIO',
                header: 'Executado por',
                size: 140,
                aggregatedCell: ({ row }) => {
                    return row.original.APROVACAO_ESTRUTURA?.NM_USUARIO
                },
                enableGrouping: false,
            }),
            columnHelper.accessor('DS_REVISAO_INICIAL', {
                id: 'DS_REVISAO_INICIAL',
                header: 'Revisão inicial',
                size: 100,
                enableGrouping: false,
                cell: () => null,
                aggregatedCell: ({ row }) => {
                    return row.original.DS_REVISAO_INICIAL
                },
            }),
            columnHelper.accessor('DS_REVISAO_FINAL', {
                id: 'DS_REVISAO_FINAL',
                header: 'Revisão final',
                size: 100,
                enableGrouping: false,
                cell: () => null,
                aggregatedCell: ({ row }) => {
                    return row.original.DS_REVISAO_FINAL
                },
            }),
        ],
        [componentId, costId]
    )

    return (
        <Table<StatusTable>
            data={flattenData}
            columns={columns}
            expandAllRows
            tableState={{ grouping }}
            persist={{
                canPersist: true,
                key: 'product-structure-status',
                omit: ['expanded'],
            }}
        />
    )
}

export default ComponentsTable
