import { isAxiosError } from '@/api/business'
import { fetchExtraCosts } from '@/api/business/extraCost'
import { useToast } from '@/components/ui/use-toast'
import { useQuery } from '@tanstack/react-query'

export const extraCostKeys = {
    all: ['extraCost'] as const,
    lists: () => [...extraCostKeys.all, 'list'] as const,
    list: (date: number) => [...extraCostKeys.lists(), date] as const,
    details: () => [...extraCostKeys.all, 'detail'] as const,
    detail: (props: { productId: string; componentId: string; date: number }) =>
        [...extraCostKeys.details(), { ...props }] as const,
}

export const useExtraCostQuery = () => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchExtraCosts(),
        queryKey: extraCostKeys.lists(),
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar os custos extras. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
