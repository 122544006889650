import { TypographyP } from '@/components/ui/typography'

type TaxGroupProps = {
    children: React.ReactNode
    title: string
}

const TaxGroup = ({ children, title }: TaxGroupProps) => {
    return (
        <div className="p-2 border rounded-md">
            <div className="px-2 -mt-5 bg-background w-fit">
                <TypographyP className="font-semibold">{title}</TypographyP>
            </div>
            <div className="flex items-center gap-4">{children}</div>
        </div>
    )
}

export default TaxGroup
