import { AppConfigSlice, AppConfigState } from './type'
import { ImmerStateCreator, Store } from '../type'

const initialState: AppConfigState = {
    stdCostTablesVisibleState: {},
    isSidenavOpen: false,
    isMobile: window.innerWidth < 640,
    isChangePasswordDialogOpen: false,
}
export const useAppConfigSlice: ImmerStateCreator<AppConfigSlice> = (set) => ({
    state: initialState,
    actions: {
        setSidenavOpen: (isOpen) =>
            set((state) => {
                state.appConfigSlice.state.isSidenavOpen = isOpen
            }),
        setIsMobile: (isMobile) =>
            set((state) => {
                state.appConfigSlice.state.isMobile = isMobile
            }),
        onChangePasswordDialogClose: () =>
            set((state) => {
                state.appConfigSlice.state.isChangePasswordDialogOpen = false
            }),
        onChangePasswordDialogOpen: () =>
            set((state) => {
                state.appConfigSlice.state.isChangePasswordDialogOpen = true
            }),
        setVisibleTable: (tableId, visible) => {
            set((state) => {
                state.appConfigSlice.state.stdCostTablesVisibleState[tableId] =
                    visible
            })
        },
        onReset: () =>
            set((state) => {
                state.appConfigSlice.state = initialState
            }),
    },
})

export const appConfigSliceStateSelector = (state: Store) =>
    state.appConfigSlice.state

export const appConfigSliceActionsSelector = (state: Store) =>
    state.appConfigSlice.actions
