import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { TypographyP } from '@/components/ui/typography'
import { ArrowDownToLine, FileUp, PlusCircle, RefreshCcw } from 'lucide-react'
import { ReactNode } from 'react'

interface DadosMestreHeaderActionsProps {
    CustomButtons?: ReactNode
    onAdd?: () => void
    onDownload?: () => void
    onRefech?: () => void
    onBulk?: () => void
}

const DadosMestreHeaderActions = ({
    CustomButtons,
    onAdd,
    onBulk,
    onDownload,
    onRefech,
}: DadosMestreHeaderActionsProps) => {
    return (
        <>
            {CustomButtons}
            {onAdd && (
                <>
                    <ButtonWithTooltip
                        onClick={onAdd}
                        size="default"
                        className="w-auto gap-2 px-4"
                        tooltipContent={
                            <TypographyP className="text-white">
                                Adicionar
                            </TypographyP>
                        }
                    >
                        <PlusCircle size={18} />
                        Novo
                    </ButtonWithTooltip>
                    <div className="w-[1px] h-4 bg-neutral-300" />
                </>
            )}
            {onRefech && (
                <>
                    <ButtonWithTooltip
                        tooltipContent={
                            <TypographyP className="text-white">
                                Atualizar
                            </TypographyP>
                        }
                        onClick={onRefech}
                        variant="ghost"
                    >
                        <RefreshCcw size={18} />
                    </ButtonWithTooltip>
                    <div className="w-[1px] h-4 bg-neutral-300" />
                </>
            )}

            {onBulk && (
                <>
                    <ButtonWithTooltip
                        tooltipContent={
                            <TypographyP className="text-white">
                                Carga
                            </TypographyP>
                        }
                        onClick={onBulk}
                        variant="ghost"
                    >
                        <FileUp size={18} />
                    </ButtonWithTooltip>
                    <div className="w-[1px] h-4 bg-neutral-300" />
                </>
            )}
            {onDownload && (
                <>
                    <ButtonWithTooltip
                        tooltipContent={
                            <TypographyP className="text-white">
                                Download
                            </TypographyP>
                        }
                        onClick={onDownload}
                        variant="ghost"
                    >
                        <ArrowDownToLine size={18} />
                    </ButtonWithTooltip>
                    <div className="w-[1px] h-4 bg-neutral-300" />
                </>
            )}
        </>
    )
}

export default DadosMestreHeaderActions
