import { ProductStructureFlat } from '@/types/Product'
import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { ProductStructureTable } from '../TreeTable'

export interface EditDialogState {
    quantity: string | null
    initial: string | null
    final: string | null
    initialDate: number | null
    finalDate: number | null
}

export enum EditDialogActionNames {
    SET_INITIAL_STATE,
    ON_CHANGE_QUANTITY,
    ON_CHANGE_INITIAL,
    ON_CHANGE_FINAL,
    ON_CHANGE_INITIAL_DATE,
    ON_CHANGE_FINAL_DATE,
    RESET,
}

type EditDialogActions =
    | {
          type: EditDialogActionNames.SET_INITIAL_STATE
          payload: EditDialogState
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_QUANTITY
          payload: { value: string }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_INITIAL
          payload: { value: string }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_FINAL
          payload: { value: string }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_INITIAL_DATE
          payload: { value: number }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_FINAL_DATE
          payload: { value: number }
      }
    | {
          type: EditDialogActionNames.RESET
      }

const useEditDialog = (data: ProductStructureFlat | ProductStructureTable) => {
    const initialState: EditDialogState = useMemo(
        () => ({
            quantity: data.QTD_COMPONENTE,
            initial: data.DS_REVISAO_INICIAL,
            final: data.DS_REVISAO_FINAL,
            initialDate: data.SK_TEMPO_INICIAL,
            finalDate: data.SK_TEMPO_FINAL,
        }),

        [data]
    )

    const reducer = useCallback(
        (
            state: typeof initialState,
            action: EditDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case EditDialogActionNames.SET_INITIAL_STATE: {
                    const { final, finalDate, initial, initialDate, quantity } =
                        action.payload

                    return {
                        final,
                        finalDate,
                        initial,
                        initialDate,
                        quantity,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_FINAL: {
                    const { value } = action.payload

                    return {
                        ...state,
                        final: value,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_FINAL_DATE: {
                    const { value } = action.payload

                    return {
                        ...state,
                        finalDate: value,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_INITIAL: {
                    const { value } = action.payload

                    return {
                        ...state,
                        initial: value,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_INITIAL_DATE: {
                    const { value } = action.payload

                    return {
                        ...state,
                        initialDate: value,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_QUANTITY: {
                    const { value } = action.payload

                    return {
                        ...state,
                        quantity: value,
                    }
                }
                case EditDialogActionNames.RESET:
                    return initialState
                default:
                    return state
            }
        },
        [initialState]
    )

    useEffect(() => {
        if (data) {
            dispatch({
                type: EditDialogActionNames.SET_INITIAL_STATE,
                payload: initialState,
            })
        }
    }, [data])

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        dispatch,
    }
}
export default useEditDialog
