import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { useApproverQuery } from '@/queries/useApproverQuery'
import { Approver } from '@/api/business/approver/types'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import DadosMestreHeader from '../components/DadosMestreHeader'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'

export type ApproverTable = Approver & TableCustomData<Approver>

const columnHelper = createColumnHelper<Approver>()

const ApproverDadosMestre = () => {
    const { data, isFetching, isLoading, refetch } = useApproverQuery()

    const {
        table,
        isAddDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen,
        selectedRow,
        selectedRows,
        setSelectedRows,
        onAddDialogClose,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose,
        setTable,
    } = useTable<ApproverTable>('APROVADORES_TABLE')

    // const { onDownload } = useApproverSpreadsheet(table)
    const memoData = useMemo(() => data || [], [data])

    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('NM_APROVADOR', {
                id: 'NM_APROVADOR',
                header: 'Nome',
                size: 400,
            }),
            columnHelper.accessor('DD_EMAIL', {
                id: 'DD_EMAIL',
                header: 'Email',
                size: 400,
            }),
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    return (
                        <TableRowActions
                            onClickDelete={() => onClickDelete(row)}
                            onClickEdit={() => onClickEdit(row)}
                        />
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )

    return (
        <div className="flex flex-col w-full h-full p-4 overflow-hidden">
            <Table<ApproverTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_APROVADOR.toString()}
                isLoading={isLoading}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}
                meta={{}}
                persist={{
                    canPersist: true,
                    key: 'approvers-table',
                }}
                enableRowSelection
                tableState={{
                    rowSelection: selectedRows,
                }}
                onRowSelectionChange={setSelectedRows}
                tableHeader={
                    <DadosMestreHeader
                        title={`Destinatários ${
                            data ? `(${data.length})` : ''
                        }`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onAdd={onClickAdd}
                        // onDownload={() => onDownload()}
                        onRefech={refetch}
                    />
                }
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_APROVADOR]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_APROVADOR)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                />
            )}
            {selectedRow && isEditDialogOpen && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            {isAddDialogOpen && (
                <AddDialog
                    isOpen={isAddDialogOpen}
                    onClose={onAddDialogClose}
                />
            )}
        </div>
    )
}

export default ApproverDadosMestre
