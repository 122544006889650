import { isAxiosError } from '@/api/business'
import { createCostView } from '@/api/business/stdCost'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { costViewKeys } from '@/queries/useCostViewQuery'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

interface LayoutsDialogProps {
    isOpen: boolean
    onOpen: (open: boolean) => void
}

const stateSelector = (state: Store) => ({
    selectedDate: state.stdCostSlice.state.selectedDate,
    tableState: state.stdCostSlice.state.stdCostTableState,
    tableInstance: state.stdCostSlice.state.tableInstance,
})

const SaveLayoutDialog = ({ isOpen, onOpen }: LayoutsDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { selectedDate, tableInstance } = useBaseStore(stateSelector)

    const [layoutName, setLayoutName] = useState('')
    const { mutate, isLoading } = useMutation({
        mutationFn: createCostView,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: costViewKeys.list(selectedDate.id),
            })
            toast({
                title: 'Layout criado com sucesso',
                description: `Sucesso ao criar o layout ${layoutName}`,
                variant: 'success',
            })
            onOpen(false)
            setLayoutName('')
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar layout',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    return (
        <Dialog open={isOpen} onOpenChange={onOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Salvar layout</DialogTitle>
                </DialogHeader>
                <div className="space-y-1.5">
                    <Label htmlFor="layout">Nome do layout</Label>
                    <Input
                        id="layout"
                        type="text"
                        value={layoutName}
                        onChange={(e) => setLayoutName(e.target.value)}
                    />
                </div>
                <DialogFooter>
                    <Button
                        className="w-[100px]"
                        disabled={!layoutName || isLoading}
                        onClick={() =>
                            mutate([
                                {
                                    DD_TIPO_CONFIGURACAO: 'LAYOUT_CUSTO_PADRAO',
                                    DS_CONFIGURACAO_EXIBICAO: JSON.stringify(
                                        tableInstance?.getState()
                                    ),
                                    NM_CONFIGURACAO_EXIBICAO: layoutName,
                                    SK_TEMPO: selectedDate.id,
                                },
                            ])
                        }
                    >
                        Salvar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default SaveLayoutDialog
