import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createExtraCost } from '@/api/business/stdCost'
import { useToast } from '@/components/ui/use-toast'
import { stdCostKeys } from '@/queries/useStdCostsQuery'
import { isAxiosError } from '@/api/business'
import { Separator } from '@/components/ui/separator'
import {
    ComponentCustomEntity,
    ProductCustomEntity,
} from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { z } from 'zod'
import { Store } from '@/store/type'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { TypographyP } from '@/components/ui/typography'
import { Input } from '@/components/ui/input'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'
import TaxRow from '@/pages/DadosMestre/InformedCostDadosMestre/components/EditDialog/components/TaxRow'
import TaxGroup from '@/pages/DadosMestre/InformedCostDadosMestre/components/EditDialog/components/TaxGroup'
import { Button } from '@/components/ui/button'

interface AddExtraCostDialogProps {
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    description: z.string().min(1, 'Descrição inválida'),
    costValue: z.string().min(1, 'Valor inválido'),
    freight: z.literal('CIF').or(z.literal('FOB')),
    observation: z.string().optional(),
    pis: z.string().optional(),
    cofins: z.string().optional(),
    icms: z.string().optional(),
    ipi: z.string().optional(),
    st: z.string().optional(),
    fcp: z.string().optional(),
    pis_value: z.string().optional(),
    cofins_value: z.string().optional(),
    icms_value: z.string().optional(),
    ipi_value: z.string().optional(),
    st_value: z.string().optional(),
    fcp_value: z.string().optional(),
})

type AddSchema = z.infer<typeof schema>

const stateSelector = (state: Store) => {
    return {
        selectedStdCostRow: state.stdCostSlice.state.selectedStdCostRow,
        selectedDate: state.stdCostSlice.state.selectedDate,
        isAnualAvg: state.stdCostSlice.state.isAnualAvg,
        stdCostData: state.stdCostSlice.state.stdCostData,
    }
}

const AddExtraCostDialog = ({ isOpen, onClose }: AddExtraCostDialogProps) => {
    const queryClient = useQueryClient()

    const {
        stdCostData: { products, components },
        selectedStdCostRow: selectedRow,
        selectedDate,
        isAnualAvg,
    } = useBaseStore(stateSelector)

    const productOrComponent =
        selectedRow && selectedRow?.isProduct
            ? products.entities[selectedRow.id]
            : components.entities[selectedRow!.id]

    const { toast } = useToast()

    const queryKey = stdCostKeys.list(selectedDate.id, isAnualAvg)

    const { mutate: mutateExtraCost, isLoading: isExtraCostLoading } =
        useMutation({
            mutationFn: createExtraCost,
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey)
                toast({
                    title: 'Custo adicionado com sucesso',
                })
                onClose()
            },
            onError: (err) => {
                if (isAxiosError(err)) {
                    if (
                        err.response?.status === 401 ||
                        err.response?.status === 402
                    ) {
                        toast({
                            title: 'Sem permissão de acesso',
                            description:
                                'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                            variant: 'destructive',
                        })
                    } else {
                        toast({
                            title: 'Erro ao adicionar custo',
                            description:
                                'Não foi possível adicionar o custo, tente novamente',
                            variant: 'destructive',
                        })
                    }
                }
            },
        })

    const form = useForm<AddSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            description: '',
            costValue: '',
            freight: 'CIF',
            observation: '',
            pis: '0',
            cofins: '0',
            icms: '0',
            ipi: '0',
            st: '0',
            fcp: '0',
            pis_value: '0',
            cofins_value: '0',
            icms_value: '0',
            ipi_value: '0',
            st_value: '0',
            fcp_value: '0',
        },
    })

    const onSubmit = (values: AddSchema) => {
        const {
            costValue,
            description,
            freight,
            cofins,
            cofins_value,
            fcp,
            fcp_value,
            icms,
            icms_value,
            ipi,
            ipi_value,
            observation,
            pis,
            pis_value,
            st,
            st_value,
        } = values

        if (productOrComponent) {
            if ((productOrComponent as ComponentCustomEntity)?._parentId) {
                const component = productOrComponent as ComponentCustomEntity

                const product = products.entities[component._parentId]

                mutateExtraCost({
                    SK_EMPRESA: product.SK_EMPRESA,
                    SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                    SK_PRODUTO_COMPONENTE: component.SK_PRODUTO_COMPONENTE,
                    SK_TEMPO: [selectedDate.id],
                    DS_CUSTO: description,
                    CATEGORIA_VALOR: 'VARIAVEL',
                    QTD_CUSTO: Number(component.INDICE_STANDARD),
                    VL_CUSTO_ORCADO: costValue,
                    DD_TIPO_FRETE: freight,
                    DD_OBSERVACAO: observation,
                    VL_ALIQUOTA_COFINS: cofins_value,
                    VL_ALIQUOTA_FCP: fcp_value,
                    VL_ALIQUOTA_ICMS: icms_value,
                    VL_ALIQUOTA_IPI: ipi_value,
                    VL_ALIQUOTA_PIS: pis_value,
                    VL_ALIQUOTA_ST: st_value,
                    VL_COFINS: cofins,
                    VL_FCP: fcp,
                    VL_ICMS: icms,
                    VL_IPI: ipi,
                    VL_PIS: pis,
                    VL_ST: st,
                })
            } else {
                const product = productOrComponent as ProductCustomEntity

                mutateExtraCost({
                    SK_EMPRESA: product.SK_EMPRESA,
                    SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                    SK_PRODUTO_COMPONENTE: null,
                    SK_TEMPO: [selectedDate.id],
                    DS_CUSTO: description,
                    CATEGORIA_VALOR: 'FIXO',
                    QTD_CUSTO: 1,
                    VL_CUSTO_ORCADO: costValue,
                    DD_TIPO_FRETE: freight,
                    DD_OBSERVACAO: observation,
                    VL_ALIQUOTA_COFINS: cofins_value,
                    VL_ALIQUOTA_FCP: fcp_value,
                    VL_ALIQUOTA_ICMS: icms_value,
                    VL_ALIQUOTA_IPI: ipi_value,
                    VL_ALIQUOTA_PIS: pis_value,
                    VL_ALIQUOTA_ST: st_value,
                    VL_COFINS: cofins,
                    VL_FCP: fcp,
                    VL_ICMS: icms,
                    VL_IPI: ipi,
                    VL_PIS: pis,
                    VL_ST: st,
                })
            }
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[900px]">
                <DialogHeader>
                    <DialogTitle>Adicionar custo extra</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="flex gap-4">
                            <div className="flex-1 p-2 space-y-4 overflow-hidden">
                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Informações
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="space-y-2">
                                        <FormField
                                            name="description"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel>
                                                            Descrição do custo
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input {...field} />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="freight"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Frete
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Select
                                                                {...field}
                                                                onValueChange={
                                                                    field.onChange
                                                                }
                                                            >
                                                                <SelectTrigger>
                                                                    <SelectValue />
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectItem value="CIF">
                                                                        CIF
                                                                    </SelectItem>
                                                                    <SelectItem value="FOB">
                                                                        FOB
                                                                    </SelectItem>
                                                                </SelectContent>
                                                            </Select>
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="observation"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Observação
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Textarea
                                                                {...field}
                                                                className="resize-none"
                                                                maxLength={200}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 p-2 space-y-4 overflow-hidden">
                                <div>
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Custo
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="flex gap-4">
                                        <FormField
                                            name="costValue"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem className="flex-1">
                                                        <FormLabel>
                                                            Custo
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                {...field}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Impostos
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <TaxRow>
                                        <TaxGroup title="PIS">
                                            <FormField
                                                name="pis"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="pis_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="COFINS">
                                            <FormField
                                                name="cofins"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="cofins_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                    <TaxRow>
                                        <TaxGroup title="ICMS">
                                            <FormField
                                                name="icms"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="icms_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="IPI">
                                            <FormField
                                                name="ipi"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="ipi_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                    <TaxRow>
                                        <TaxGroup title="ST">
                                            <FormField
                                                name="st"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="st_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="FCP">
                                            <FormField
                                                name="fcp"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="fcp_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                </div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                variant="ghost"
                                type="button"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={isExtraCostLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddExtraCostDialog
