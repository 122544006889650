import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import {
    Status,
    statusLocale,
    statusTextVariants,
    statusVariants,
} from '@/types/Status'
import { CircleCheckBig, SquareArrowOutUpRight } from 'lucide-react'
import FinishCostDialog from '../FinishCostDialog'

type InformedCostDropdownProps = {
    status: Status
}

const InformedCostDropdown = ({ status }: InformedCostDropdownProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center gap-2 p-1 border rounded-md hover:bg-accent">
                    <>
                        <TypographyMuted>
                            Cenário custo informado:
                        </TypographyMuted>
                        <div className="flex items-center gap-1">
                            <div
                                className={cn(
                                    'h-3 w-3 rounded-full',
                                    statusVariants({
                                        status,
                                    })
                                )}
                            ></div>
                            <TypographyP
                                className={statusTextVariants({
                                    status,
                                })}
                            >
                                {statusLocale[status]}
                            </TypographyP>
                        </div>
                    </>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <DropdownMenuGroup>
                        <DropdownMenuItem
                            className="gap-2"
                            onClick={onOpen}
                            disabled={
                                status === 'FINALIZADO' || status === 'APROVADO'
                            }
                        >
                            <CircleCheckBig
                                size={14}
                                className="text-primary"
                            />
                            Finalizar
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            className="gap-2"
                            onClick={() =>
                                open(
                                    window.location.origin +
                                        '/dados-mestre/custo-informado'
                                )
                            }
                        >
                            <SquareArrowOutUpRight
                                size={14}
                                className="text-neutral-500"
                            />
                            Ir para custo informado
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
            <FinishCostDialog
                type="informed"
                isOpen={isOpen}
                onClose={onClose}
            />
        </>
    )
}

export default InformedCostDropdown
