import { memo } from 'react'
import StdCostAnualAvgToggle from '../StdCostAnualAvgToggle'

const StdCostTableActions = memo(() => {
    console.count('tableactions')
    return (
        <>
            <StdCostAnualAvgToggle />
            <span className="ml-2 text-neutral-300">|</span>
        </>
    )
})

export default StdCostTableActions
