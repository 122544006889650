import instance from '..'
import {
    FinishCostProps,
    FinishStructureProps,
    FetchStructureStatusByIdProps,
    StructureStatus,
    ApproveStructureProps,
    FetchCostHistoryProps,
    ResponseData,
} from './type'

export const fetchStructureStatus = async () => {
    const { data } = await instance.post<StructureStatus[]>(
        '/statusaprovacao/portalcusto/statusaprovacao'
    )

    return data
}

export const fetchStructureStatusById = async ({
    id,
}: FetchStructureStatusByIdProps) => {
    const { data } = await instance.post<StructureStatus>(
        '/statusaprovacao/portalcusto/statusaprovacao/id',
        { SK_PRODUTO_ESTRUTURA: id }
    )

    return data
}

export const finishStructure = async ({ id }: FinishStructureProps) => {
    const { data } = await instance.put(
        'statusaprovacao/portalcusto/estruturadeproduto/finalizar',
        { SK_PRODUTO_ESTRUTURA: id }
    )

    return data
}

export const finishCost = async ({ id, type }: FinishCostProps) => {
    const { data } = await instance.put(
        'statusaprovacao/portalcusto/custo/finalizar',
        { SK_PRODUTO_ESTRUTURA: id, DD_TIPO_CUSTO: type }
    )

    return data
}

export const approveStructure = async ({ id }: ApproveStructureProps) => {
    const { data } = await instance.put(
        'statusaprovacao/portalcusto/estruturadeproduto/aprovar',
        { SK_PRODUTO_ESTRUTURA: id }
    )

    return data
}

export const fetchCostHistory = async ({
    structureId,
}: FetchCostHistoryProps) => {
    const { data } = await instance.post<ResponseData>(
        'statusaprovacao/portalcusto/custo/historico',
        { SK_PRODUTO_ESTRUTURA: structureId }
    )

    return data
}
