import { isAxiosError } from '@/api/business'
import { createApprover } from '@/api/business/approver'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { approverKeys } from '@/queries/useApproverQuery'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

type AddDialogProps = {
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    name: z.string().min(1, 'Nome é obrigatório'),
    email: z.string().email('Email inválido'),
})

type AddSchema = z.infer<typeof schema>

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading } = useMutation({
        mutationFn: createApprover,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: approverKeys.lists() })
            toast({
                title: 'Aprovador adicionado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar aprovador',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const form = useForm<AddSchema>({
        defaultValues: {
            name: '',
            email: '',
        },
        resolver: zodResolver(schema),
    })

    const onSubmit = ({ email, name }: AddSchema) => {
        mutate({
            DD_EMAIL: email,
            NM_APROVADOR: name,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Adicionar aprovador</DialogTitle>
                </DialogHeader>
                <div>
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(onSubmit)}
                            className="space-y-4"
                        >
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome</FormLabel>
                                        <Input {...field} type="text" />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            {...field}
                                            type="text"
                                            placeholder="Exemplo: usuario@cervejariacidadeimperial.com"
                                        />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <DialogFooter>
                                <Button
                                    type="button"
                                    variant="ghost"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </Button>
                                <Button disabled={isLoading}>Confirmar</Button>
                            </DialogFooter>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog
