import { useMemo } from 'react'
import TabSection from '../../../../../TabSection'
import { Box, Minus, MinusCircle, Plus } from 'lucide-react'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { IdToRemove } from '../..'
import { useBaseStore } from '@/store'
import {
    getComponentByIdSelector,
    stdCostSliceStateSelector,
} from '@/store/stdCostSlice'
import { TypographyP } from '@/components/ui/typography'

interface ComponentProps {
    componentId: string
    onAddCostDialogOpen: () => void
    onAddExtraCostDialogOpen: () => void
    onRemoveCostDialogOpen: () => void
    setIdToRemove: (props: IdToRemove) => void
}

const Component = ({
    componentId,
    onAddCostDialogOpen,
    onAddExtraCostDialogOpen,
    onRemoveCostDialogOpen,
    setIdToRemove,
}: ComponentProps) => {
    const component = useBaseStore(getComponentByIdSelector(componentId))

    const {
        stdCostData: { components },
    } = useBaseStore(stdCostSliceStateSelector)

    const extraCosts = useMemo(
        () =>
            component.COMPONENTES.map((id) => components.entities[id]).filter(
                (component) =>
                    component.ESTRUTURA === 'EXTRA-COMPONENTE' ||
                    component.ESTRUTURA === 'EXTRA-PRODUTO'
            ) || [],
        [component]
    )

    return (
        <>
            <TabSection title={'Produto'}>
                <TypographyP className="flex items-center gap-2 ">
                    <Box size={16} />
                    <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                        {component.PRODUTO_COMPONENTE}
                    </span>
                </TypographyP>
            </TabSection>
            <TabSection
                title="Custo informado"
                actionButton={
                    component?.MANIPULA_COMPONENTE === 1 &&
                    !component.productUsedAsComponentId && (
                        <div className="flex items-center gap-4">
                            <button onClick={onAddCostDialogOpen}>
                                <Plus size={16} />
                            </button>
                            {component?.VALOR_INFORMADO_COMPONENTE ? (
                                <button
                                    onClick={() => {
                                        if (
                                            component.SK_NOVO_CUSTO_COMPONENTE
                                        ) {
                                            setIdToRemove({
                                                id: component.SK_NOVO_CUSTO_COMPONENTE,
                                                isExtraCost: false,
                                            })
                                            onRemoveCostDialogOpen()
                                        }
                                    }}
                                >
                                    <Minus size={14} />
                                </button>
                            ) : null}
                        </div>
                    )
                }
            >
                {component.VALOR_INFORMADO_COMPONENTE ? (
                    <>
                        <TypographyP className="relative text-center text-primary-700 dark:text-primary">
                            {currencyFormat(
                                Number(component.VALOR_INFORMADO_COMPONENTE),
                                4
                            )}
                        </TypographyP>
                        {component.MOEDA_UTILIZADA_COMPONENTE &&
                            component.MOEDA_UTILIZADA_COMPONENTE !== 'REAL' && (
                                <TypographyP className="mt-2 text-center text-zinc-500">
                                    Valor original:
                                    {currencyFormat(
                                        Number(
                                            component.VALOR_MOEDA_PADRAO_COMPONENTE
                                        ),
                                        4,
                                        component.MOEDA_UTILIZADA_COMPONENTE ||
                                            'REAL'
                                    )}
                                </TypographyP>
                            )}
                    </>
                ) : (
                    <TypographyP className="text-center text-primary-700 dark:text-primary">
                        Nenhum custo informado
                    </TypographyP>
                )}
            </TabSection>
            <TabSection title="Impostos">
                <table className="">
                    <thead className="text-left">
                        <tr>
                            <th className="px-4 w-[100px]">
                                <TypographyP className="text-sm font-semibold text-primary-600 dark:text-primary">
                                    Descrição
                                </TypographyP>
                            </th>
                            <th className="px-4 w-[100px]">
                                <TypographyP className="text-sm font-semibold text-primary-600 dark:text-primary">
                                    Valor
                                </TypographyP>
                            </th>
                            <th className="text-primary-600 dark:text-primary px-4 w-[100px]">
                                <TypographyP className="text-sm font-semibold text-primary-600 dark:text-primary">
                                    Aliquota
                                </TypographyP>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    PIS
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {component?.VL_PIS || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {percentageFormat(
                                        Number(component?.VL_ALIQUOTA_PIS) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    COFINS
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {component?.VL_COFINS || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {percentageFormat(
                                        Number(component?.VL_ALIQUOTA_COFINS) ||
                                            0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    FCP
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {component?.VL_FCP || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {percentageFormat(
                                        Number(component?.VL_ALIQUOTA_FCP) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    ICMS
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {component?.VL_ICMS || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {percentageFormat(
                                        Number(component?.VL_ALIQUOTA_ICMS) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    IPI
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {component?.VL_IPI || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {percentageFormat(
                                        Number(component?.VL_ALIQUOTA_IPI) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    ST
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {component?.VL_ST || 0}
                                </TypographyP>
                            </td>
                            <td className="p-1 px-4">
                                <TypographyP className="text-accent-foreground">
                                    {percentageFormat(
                                        Number(component?.VL_ALIQUOTA_ST) || 0
                                    )}
                                </TypographyP>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TabSection>
            <TabSection
                title="Custos extras"
                actionButton={
                    component?.MANIPULA_COMPONENTE === 1 &&
                    !component.productUsedAsComponentId && (
                        <button onClick={onAddExtraCostDialogOpen}>
                            <Plus size={16} />
                        </button>
                    )
                }
            >
                {extraCosts.length > 0 ? (
                    <table>
                        <thead>
                            <tr className="text-left text-primary-600">
                                <th className="px-2">
                                    <TypographyP className="text-sm font-semibold text-primary-600">
                                        Descrição
                                    </TypographyP>
                                </th>
                                <th className="px-2">
                                    <TypographyP className="text-sm font-semibold text-primary-600">
                                        Valor
                                    </TypographyP>
                                </th>
                                <th className="px-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {extraCosts.map((component) => (
                                <tr key={component.SK_PRODUTO_COMPONENTE}>
                                    <td className="px-2">
                                        <TypographyP className="text-accent-foreground">
                                            {component.PRODUTO_COMPONENTE}
                                        </TypographyP>
                                    </td>
                                    <td className="px-2">
                                        <TypographyP className="text-accent-foreground">
                                            {currencyFormat(
                                                component.ULTIMO_PRECO,
                                                4
                                            )}
                                        </TypographyP>
                                    </td>
                                    <td className="px-2">
                                        {component?.MANIPULA_COMPONENTE &&
                                        component.DD_ORIGEM_DADO ===
                                            'MANUAL' ? (
                                            <button
                                                className="text-red-500"
                                                onClick={() => {
                                                    setIdToRemove({
                                                        id: Number(
                                                            component.SK_PRODUTO_COMPONENTE
                                                        ),
                                                        isExtraCost: true,
                                                    })
                                                    onRemoveCostDialogOpen()
                                                }}
                                            >
                                                <MinusCircle size={14} />
                                            </button>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <TypographyP className="text-center text-primary-600 dark:text-primary">
                        Não há custo extra
                    </TypographyP>
                )}
            </TabSection>
        </>
    )
}

export default Component
