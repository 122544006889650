import { Currency } from '@/types/Currency'
import { ImmerStateCreator, Store } from '../type'
import { CurrencySlice, CurrencyState } from './type'

const initialState: CurrencyState = {
    currencies: [],
}

export const useCurrencySlice: ImmerStateCreator<CurrencySlice> = (set) => ({
    state: initialState,
    actions: {
        setCurrencies: (data) => {
            set((state) => {
                state.currencySlice.state.currencies = data.map(
                    (currency): Currency => {
                        switch (currency.DS_MOEDA) {
                            case 'REAL':
                                return {
                                    SK_MOEDA: currency.SK_MOEDA,
                                    DS_MOEDA: currency.DS_MOEDA,
                                    MOEDA: 'BRL',
                                    SIMBOLO: 'R$',
                                }
                            case 'DOLAR':
                                return {
                                    SK_MOEDA: currency.SK_MOEDA,
                                    DS_MOEDA: currency.DS_MOEDA,
                                    MOEDA: 'USD',
                                    SIMBOLO: 'US$',
                                }
                            case 'EURO':
                                return {
                                    SK_MOEDA: currency.SK_MOEDA,
                                    DS_MOEDA: currency.DS_MOEDA,
                                    MOEDA: 'EUR',
                                    SIMBOLO: '€',
                                }

                            case 'IENE':
                                return {
                                    SK_MOEDA: currency.SK_MOEDA,
                                    DS_MOEDA: currency.DS_MOEDA,
                                    MOEDA: 'JPY',
                                    SIMBOLO: '¥',
                                }

                            case 'FRANCO SUIÇO':
                                return {
                                    SK_MOEDA: currency.SK_MOEDA,
                                    DS_MOEDA: currency.DS_MOEDA,
                                    MOEDA: 'CHF',
                                    SIMBOLO: 'SFr',
                                }
                            default:
                                return {
                                    SK_MOEDA: currency.SK_MOEDA,
                                    DS_MOEDA: currency.DS_MOEDA,
                                    MOEDA: 'BRL',
                                    SIMBOLO: 'R$',
                                }
                        }
                    }
                )
            })
        },
        onReset: () =>
            set((state) => {
                state.currencySlice.state = initialState
            }),
    },
})

export const currencySliceActionsSelector = (state: Store) =>
    state.currencySlice.actions
