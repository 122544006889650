import { extraCostKeys } from '@/queries/useExtraCostQuery'
import { updateInformedCost as updateExtraCost } from '@/api/business/informedCost'
import { useToast } from '@/components/ui/use-toast'
import { getDateFromId, getIdFromDate } from '@/utils/date'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from '@/api/business'
import { z } from 'zod'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useEffect } from 'react'
import { TypographyP } from '@/components/ui/typography'
import { Separator } from '@/components/ui/separator'
import TaxGroup from './components/TaxGroup'
import TaxRow from './components/TaxRow'
import { Button } from '@/components/ui/button'
import { ExtraCostTable } from '../..'
import { Row } from '@tanstack/react-table'
import DatePickerMonthYear from '@/components/DatePickerMonthYear'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'

interface EditDialogProps {
    row: Row<ExtraCostTable>
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    date: z.date(),
    description: z.string().min(1, 'Descrição inválida'),
    costValue: z.string().min(1, 'Valor inválido'),
    freight: z.literal('CIF').or(z.literal('FOB')),
    observation: z.string().optional(),
    pis: z.string().optional(),
    cofins: z.string().optional(),
    icms: z.string().optional(),
    ipi: z.string().optional(),
    st: z.string().optional(),
    fcp: z.string().optional(),
    pis_value: z.string().optional(),
    cofins_value: z.string().optional(),
    icms_value: z.string().optional(),
    ipi_value: z.string().optional(),
    st_value: z.string().optional(),
    fcp_value: z.string().optional(),
})

export type EditSchema = z.infer<typeof schema>

const EditDialog = ({ isOpen, row, onClose }: EditDialogProps) => {
    const {
        SK_NOVO_CUSTO,
        QTD_CUSTO,
        CATEGORIA_VALOR,
        SK_EMPRESA,
        DD_TIPO_FRETE,
        DD_OBSERVACAO,
        PRODUTO_COMPONENTE,
        PRODUTO_ESTRUTURA,
        VL_ALIQUOTA_COFINS,
        VL_ALIQUOTA_FCP,
        VL_ALIQUOTA_ICMS,
        VL_ALIQUOTA_IPI,
        VL_ALIQUOTA_PIS,
        VL_ALIQUOTA_ST,
        VL_COFINS,
        VL_FCP,
        VL_ICMS,
        VL_IPI,
        VL_PIS,
        VL_ST,
        DS_CUSTO,
        SK_TEMPO,
        VL_CUSTO_ORCADO,
    } = row.original

    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: updateExtraCost,
        onSuccess: () => {
            queryClient.invalidateQueries(extraCostKeys.lists())
            toast({
                title: 'Custo informado atualizado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao atualizar custo informado',
                        description: 'Verifique os dados e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const onSubmit = ({
        costValue,
        date,
        description,
        cofins,
        cofins_value,
        fcp,
        fcp_value,
        icms,
        icms_value,
        ipi,
        ipi_value,
        pis,
        pis_value,
        st,
        st_value,
        freight,
        observation,
    }: EditSchema) => {
        mutate({
            IDENTIFICADOR: SK_NOVO_CUSTO,
            CAMPOS: {
                SK_TEMPO: Number(getIdFromDate(date).join('')),
                DS_CUSTO: description,
                DD_OBSERVACAO: observation,
                DD_TIPO_FRETE: freight,
                VL_CUSTO_ORCADO: Number(costValue),
                VL_ALIQUOTA_COFINS: cofins || '0',
                VL_COFINS: cofins_value || '0',
                VL_ALIQUOTA_FCP: fcp || '0',
                VL_FCP: fcp_value || '0',
                VL_ALIQUOTA_ICMS: icms || '0',
                VL_ICMS: icms_value || '0',
                VL_ALIQUOTA_IPI: ipi || '0',
                VL_IPI: ipi_value || '0',
                VL_ALIQUOTA_PIS: pis || '0',
                VL_PIS: pis_value || '0',
                VL_ALIQUOTA_ST: st || '0',
                VL_ST: st_value || '0',
            },
        })
    }

    const form = useForm<EditSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            cofins: '0',
            cofins_value: '0',
            costValue: '0',
            date: new Date(),
            description: '',
            fcp: '0',
            fcp_value: '0',
            icms: '0',
            icms_value: '0',
            ipi: '0',
            ipi_value: '0',
            pis: '0',
            pis_value: '0',
            st: '0',
            st_value: '0',
            observation: '',
            freight: 'CIF',
        },
    })

    useEffect(() => {
        form.reset({
            cofins: VL_ALIQUOTA_COFINS || '0',
            cofins_value: VL_COFINS || '0',
            costValue: VL_CUSTO_ORCADO || '0',
            date: getDateFromId(SK_TEMPO),
            description: DS_CUSTO,
            freight: DD_TIPO_FRETE,
            observation: DD_OBSERVACAO || '',
            fcp: VL_ALIQUOTA_FCP || '0',
            fcp_value: VL_FCP || '0',
            icms: VL_ALIQUOTA_ICMS || '0',
            icms_value: VL_ICMS || '0',
            ipi: VL_ALIQUOTA_IPI || '0',
            ipi_value: VL_IPI || '0',
            pis: VL_ALIQUOTA_PIS || '0',
            pis_value: VL_PIS || '0',
            st: VL_ALIQUOTA_ST || '0',
            st_value: VL_ST || '0',
        })
    }, [row.original])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[900px]">
                <DialogHeader>
                    <DialogTitle>Editar custo extra</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="flex gap-4">
                            <div className="flex-1 p-2 space-y-4 overflow-hidden">
                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Estrutura
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <FormItem>
                                        <FormLabel>Empresa</FormLabel>
                                        <Input value={SK_EMPRESA} readOnly />
                                    </FormItem>
                                    <FormItem>
                                        <FormLabel>Produto estrutura</FormLabel>
                                        <Input
                                            value={PRODUTO_ESTRUTURA}
                                            readOnly
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <FormLabel>
                                            Componente estrutura
                                        </FormLabel>
                                        <Input
                                            value={PRODUTO_COMPONENTE}
                                            readOnly
                                        />
                                    </FormItem>
                                </div>
                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Informações
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="space-y-2">
                                        <FormField
                                            name="description"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel>
                                                            Descrição do custo
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input {...field} />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="date"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Data
                                                        </FormLabel>
                                                        <FormControl>
                                                            <DatePickerMonthYear
                                                                month={field.value.getMonth()}
                                                                year={field.value.getFullYear()}
                                                                setDate={
                                                                    field.onChange
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="freight"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Frete
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Select
                                                                {...field}
                                                                onValueChange={
                                                                    field.onChange
                                                                }
                                                            >
                                                                <SelectTrigger>
                                                                    <SelectValue />
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectItem value="CIF">
                                                                        CIF
                                                                    </SelectItem>
                                                                    <SelectItem value="FOB">
                                                                        FOB
                                                                    </SelectItem>
                                                                </SelectContent>
                                                            </Select>
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="observation"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Observação
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Textarea
                                                                {...field}
                                                                className="resize-none"
                                                                maxLength={200}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 p-2 space-y-4 overflow-hidden">
                                <div>
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Custo
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="flex gap-4">
                                        <FormField
                                            name="costValue"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem className="flex-1">
                                                        <FormLabel>
                                                            Custo
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                {...field}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormItem className="max-w-[80px]">
                                            <FormLabel>Qtde</FormLabel>
                                            <Input
                                                value={QTD_CUSTO}
                                                className="text-ellipsis"
                                                readOnly
                                            />
                                        </FormItem>
                                        <FormItem className="max-w-[100px]">
                                            <FormLabel>Categoria</FormLabel>
                                            <Input
                                                value={CATEGORIA_VALOR}
                                                readOnly
                                            />
                                        </FormItem>
                                    </div>
                                </div>
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Impostos
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <TaxRow>
                                        <TaxGroup title="PIS">
                                            <FormField
                                                name="pis"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="pis_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="COFINS">
                                            <FormField
                                                name="cofins"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="cofins_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                    <TaxRow>
                                        <TaxGroup title="ICMS">
                                            <FormField
                                                name="icms"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="icms_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="IPI">
                                            <FormField
                                                name="ipi"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="ipi_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                    <TaxRow>
                                        <TaxGroup title="ST">
                                            <FormField
                                                name="st"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="st_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="FCP">
                                            <FormField
                                                name="fcp"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="fcp_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                </div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                variant="ghost"
                                type="button"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" disabled={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
