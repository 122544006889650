import { isAxiosError } from '@/api/business'
import { fetchClassification } from '@/api/business/product'
import { useToast } from '@/components/ui/use-toast'
import { useQuery } from '@tanstack/react-query'

export const classificationKeys = {
    all: ['classifetchClassification'] as const,
    lists: () => [...classificationKeys.all, 'list'] as const,
    list: (date: number) => [...classificationKeys.lists(), date] as const,
    details: () => [...classificationKeys.all, 'detail'] as const,
    detail: (props: {
        classifetchClassificationId: string
        componentId: string
        date: number
    }) => [...classificationKeys.details(), { ...props }] as const,
}

interface UseProductClassificationQuery {
    enabled?: boolean
}

export const useProductClassificationQuery = (
    props?: UseProductClassificationQuery
) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchClassification(),
        queryKey: classificationKeys.lists(),
        enabled: props?.enabled,
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar classificação de produtos. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
