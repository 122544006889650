import { AlterationType, CostType } from '@/api/business/structureStatus/type'
import { cn } from '@/lib/utils'
import { Status, statusTextVariants } from '@/types/Status'
import { ArrowDownUp, CircleDollarSign, Pencil, Trash } from 'lucide-react'
import { ReactNode, useMemo } from 'react'

const getIsExtraCost = (type: CostType) => type.includes('EXTRA')

export const ALTERATION_ICON: Record<
    AlterationType,
    (type: CostType) => ReactNode
> = {
    ADICAO: (type) => (
        <CircleDollarSign
            size={14}
            className={cn(
                getIsExtraCost(type) ? 'text-purple-500' : 'text-emerald-600'
            )}
        />
    ),
    EDICAO: (type) => (
        <Pencil
            size={14}
            className={cn(
                getIsExtraCost(type) ? 'text-purple-500' : 'text-emerald-600'
            )}
        />
    ),
    EXCLUSAO: (type) => (
        <Trash
            size={14}
            className={cn(
                getIsExtraCost(type) ? 'text-purple-500' : 'text-emerald-600'
            )}
        />
    ),
    STATUS_ALTERADO: (type) => (
        <ArrowDownUp
            size={14}
            className={cn(
                getIsExtraCost(type) ? 'text-purple-500' : 'text-emerald-600'
            )}
        />
    ),
}

export const ALTERATION_TITLE: Record<AlterationType, () => string> = {
    ADICAO: () => 'Custo adicionado',
    EDICAO: () => 'Custo editado',
    EXCLUSAO: () => 'Custo excluído',
    STATUS_ALTERADO: () => 'Status alterado',
}

type HistoryTextProps = {
    alterationType: AlterationType
    entity: string
    status: Status
}

export const HistoryText = ({
    alterationType,
    entity,
    status,
}: HistoryTextProps) => {
    const ALTERATION_STATUS = useMemo(
        () => ({
            ADICAO: ({ entity }: { entity: string }) => (
                <span className="text-muted-foreground">
                    adicionou um novo custo em{' '}
                    <span className="text-foreground">{entity}</span>{' '}
                </span>
            ),
            STATUS_ALTERADO: ({
                entity,
                status,
            }: {
                entity: string
                status: Status
            }) => (
                <span className="text-muted-foreground">
                    alterou o status de{' '}
                    <span className="text-foreground">{entity}</span> para{' '}
                    <span
                        className={cn(
                            'text-foreground font-semibold',
                            statusTextVariants({ status })
                        )}
                    >
                        {status}
                    </span>{' '}
                </span>
            ),
            EDICAO: ({ entity }: { entity: string }) => (
                <span className="text-muted-foreground">
                    editou custo em{' '}
                    <span className="text-foreground">{entity}</span>{' '}
                </span>
            ),
            EXCLUSAO: ({ entity }: { entity: string }) => (
                <span className="text-muted-foreground">
                    excluiu o custo de{' '}
                    <span className="text-foreground">{entity}</span>{' '}
                </span>
            ),
        }),
        []
    )

    switch (alterationType) {
        case 'ADICAO':
            return ALTERATION_STATUS.ADICAO({ entity: entity })
        case 'EDICAO':
            return ALTERATION_STATUS.EDICAO({ entity: entity })
        case 'EXCLUSAO':
            return ALTERATION_STATUS.EXCLUSAO({
                entity: entity,
            })
        case 'STATUS_ALTERADO':
            return ALTERATION_STATUS.STATUS_ALTERADO({
                entity: entity,
                status: status,
            })
    }
}
