import { useFormContext } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'

import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useState } from 'react'
import useDisclosure from '@/hooks/useDisclosure'
import { MultiSelect } from '@/components/MultiSelect'

const ProductSelect = () => {
    const { control } = useFormContext<AddSchema>()
    const [selectedComponents, setSelectedComponents] = useState<
        Record<string, boolean>
    >({})

    const {
        onOpen: onOpenMultiSelect,
        isOpen: isMultiSelectOpen,
        onClose: onMultiSelectClose,
    } = useDisclosure()

    const { data: componentStructureData = [] } = useProductStructureQuery({
        enabled: true,
    })

    const [components, setComponents] = useState<typeof componentStructureData>(
        []
    )

    const form = useFormContext<AddSchema>()

    const companyField = form.watch('companyId')
    const componentField = form.watch('componentId')

    useEffect(() => {
        if (companyField && componentField) {
            const unique = {}

            const filtered = componentStructureData.filter(
                (component) =>
                    component.SK_EMPRESA.toString() === companyField &&
                    component.SK_PRODUTO_COMPONENTE === componentField
            )

            for (const component of filtered) {
                if (!unique[component.SK_PRODUTO_ESTRUTURA]) {
                    unique[component.SK_PRODUTO_ESTRUTURA] = component
                }
            }

            setSelectedComponents({})
            setComponents(Object.values(unique))
        } else {
            form.resetField('componentId')
            setSelectedComponents({})
            setComponents([])
        }
    }, [componentStructureData, componentField, companyField])

    return (
        <FormField
            name="productId"
            control={control}
            render={({ field }) => {
                return (
                    <FormItem>
                        <FormLabel>Produto estrutura</FormLabel>
                        <FormControl>
                            <MultiSelect
                                isOpen={isMultiSelectOpen}
                                onClose={onMultiSelectClose}
                                onOpen={onOpenMultiSelect}
                                onChange={(value) => {
                                    setSelectedComponents((prev) => ({
                                        ...prev,
                                        ...value,
                                    }))

                                    field.onChange(
                                        Object.entries(value)
                                            .filter(([, val]) => val)
                                            .map(([key]) => key)
                                    )
                                }}
                                options={components.map((component) => {
                                    return {
                                        label: component.PRODUTO_ESTRUTURA,
                                        value: component.SK_PRODUTO_ESTRUTURA,
                                    }
                                })}
                                selectedValues={selectedComponents}
                                label="Selecione um produto"
                                placeholder="Selecione um produto"
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default ProductSelect
