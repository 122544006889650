import { useFormContext } from 'react-hook-form'
import { AddSchema } from '../..'
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useEffect, useState } from 'react'

const ComponentSelect = () => {
    const { control } = useFormContext<AddSchema>()

    const { data: componentStructureData = [] } = useProductStructureQuery({
        enabled: true,
    })

    const [components, setComponents] = useState<typeof componentStructureData>(
        []
    )

    const form = useFormContext<AddSchema>()

    const companyField = form.watch('companyId')
    const productField = form.watch('productId')

    useEffect(() => {
        if (companyField && productField) {
            const unique = {}

            const filtered = componentStructureData.filter(
                (component) =>
                    component.SK_EMPRESA.toString() === companyField &&
                    component.SK_PRODUTO_ESTRUTURA === productField
            )

            for (const component of filtered) {
                if (!unique[component.SK_PRODUTO_COMPONENTE]) {
                    unique[component.SK_PRODUTO_COMPONENTE] = component
                }
            }

            setComponents(Object.values(unique))
        } else {
            form.resetField('componentId')
            setComponents([])
        }
    }, [componentStructureData, productField, companyField])

    return (
        <FormField
            name="componentId"
            control={control}
            render={({ field }) => {
                return (
                    <FormItem>
                        <FormLabel>Componente estrutura</FormLabel>
                        <FormControl>
                            <Select
                                onValueChange={field.onChange}
                                value={field.value}
                            >
                                <SelectTrigger>
                                    <SelectValue
                                        placeholder={
                                            !companyField
                                                ? 'Selecione uma empresa'
                                                : !productField
                                                ? 'Selecione um produto'
                                                : 'Selecione um componente'
                                        }
                                    />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {components.map((component) => {
                                            return (
                                                <SelectItem
                                                    key={
                                                        component.SK_PRODUTO_COMPONENTE
                                                    }
                                                    value={
                                                        component.SK_PRODUTO_COMPONENTE
                                                    }
                                                >
                                                    {
                                                        component.PRODUTO_COMPONENTE
                                                    }
                                                </SelectItem>
                                            )
                                        })}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}

export default ComponentSelect
