import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import {
    ArrowDownToLine,
    Layers,
    MoreVertical,
    Save,
    Snowflake,
} from 'lucide-react'
import { useStdCostSpreadsheet } from '../../useStdCostSpreadsheet'
import { Noop } from '@/types/utils'
import { TypographyMutedXs } from '@/components/ui/typography'

import { ThickArrowUpIcon } from '@radix-ui/react-icons'

interface StdCostActionsDropdown {
    disabled?: boolean
    onOpenViewDialog: Noop
    onOpenLayoutsDialog: Noop
    onOpenSaveLayoutDialog: Noop
    onOpenFreezeDialog: Noop
    onDownloadSum: Noop
}

const StdCostActionsDropdown = ({
    disabled,
    onOpenViewDialog,
    onOpenLayoutsDialog,
    onOpenSaveLayoutDialog,
    onOpenFreezeDialog,
    onDownloadSum,
}: StdCostActionsDropdown) => {
    const { onDownload } = useStdCostSpreadsheet()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild disabled={disabled}>
                <Button
                    variant="ghost"
                    className="px-3 hover:text-primary-600 hover:bg-slate-50"
                >
                    <MoreVertical size={16} />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-[280px]">
                <DropdownMenuItem
                    className="gap-2 focus:bg-sky-50 focus:text-sky-600 dark:focus:text-sky-400 group dark:focus:bg-sky-900/30"
                    onClick={onOpenLayoutsDialog}
                >
                    <Layers
                        size={16}
                        className="text-muted-foreground group-focus:text-sky-600"
                    />
                    Visualizar layouts
                    {
                        <div className="flex items-center gap-1 ml-auto text-xs">
                            <TypographyMutedXs>Ctrl</TypographyMutedXs>
                            <TypographyMutedXs>
                                <ThickArrowUpIcon />
                            </TypographyMutedXs>
                            <TypographyMutedXs>L</TypographyMutedXs>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    className="gap-2 focus:bg-sky-50 focus:text-sky-600 dark:focus:text-sky-400 group dark:focus:bg-sky-900/30"
                    onClick={onOpenSaveLayoutDialog}
                >
                    <Save
                        size={16}
                        className="text-muted-foreground group-focus:text-sky-600"
                    />
                    Salvar layout
                    {
                        <div className="flex items-center gap-1 ml-auto text-xs">
                            <TypographyMutedXs>Ctrl</TypographyMutedXs>
                            <TypographyMutedXs>
                                <ThickArrowUpIcon />
                            </TypographyMutedXs>
                            <TypographyMutedXs>S</TypographyMutedXs>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuItem
                    className="gap-2 focus:bg-sky-50 focus:text-sky-600 dark:focus:text-sky-400 group dark:focus:bg-sky-900/30"
                    onClick={onOpenViewDialog}
                >
                    <Save
                        size={16}
                        className="text-muted-foreground group-focus:text-sky-600"
                    />
                    Salvar visões
                    {
                        <div className="flex items-center gap-1 ml-auto text-xs">
                            <TypographyMutedXs>Ctrl</TypographyMutedXs>
                            <TypographyMutedXs>
                                <ThickArrowUpIcon />
                            </TypographyMutedXs>
                            <TypographyMutedXs>V</TypographyMutedXs>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    className="gap-2 focus:bg-sky-50 focus:text-sky-600 dark:focus:text-sky-400 group dark:focus:bg-sky-900/30"
                    onClick={onOpenFreezeDialog}
                >
                    <Snowflake
                        className="text-muted-foreground group-focus:text-sky-600"
                        size={16}
                        strokeWidth={1.9}
                    />
                    Congelar
                    {
                        <div className="flex items-center gap-1 ml-auto text-xs">
                            <TypographyMutedXs>Ctrl</TypographyMutedXs>
                            <TypographyMutedXs>
                                <ThickArrowUpIcon />
                            </TypographyMutedXs>
                            <TypographyMutedXs>G</TypographyMutedXs>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuItem
                    className="gap-2 focus:bg-emerald-50 focus:text-emerald-600 dark:focus:text-emerald-300 group dark:focus:bg-emerald-800/30"
                    onClick={onDownload}
                >
                    <ArrowDownToLine
                        className="text-muted-foreground group-focus:text-emerald-600 dark:group-focus:text-emerald-300"
                        size={16}
                        strokeWidth={1.9}
                    />
                    Exportar custo padrão
                    {
                        <div className="flex items-center gap-1 ml-auto text-xs">
                            <TypographyMutedXs>Ctrl</TypographyMutedXs>
                            <TypographyMutedXs>
                                <ThickArrowUpIcon />
                            </TypographyMutedXs>
                            <TypographyMutedXs>D</TypographyMutedXs>
                        </div>
                    }
                </DropdownMenuItem>
                <DropdownMenuItem
                    className="gap-2 focus:bg-emerald-50 focus:text-emerald-600 dark:focus:text-emerald-300 group dark:focus:bg-emerald-800/30"
                    onClick={onDownloadSum}
                >
                    <ArrowDownToLine
                        className="text-muted-foreground group-focus:text-emerald-600 dark:group-focus:text-emerald-300"
                        size={16}
                        strokeWidth={1.9}
                    />
                    Exportar custo pricing
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default StdCostActionsDropdown
