import { Custo } from '@/api/business/structureStatus/type'
import { TableData } from '@/components/Table/type'
import { useCostHistoryQuery } from '@/queries/useHistoryQuery'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { createColumnHelper } from '@tanstack/react-table'
import Table from '@/components/Table'
import { TypographyP } from '@/components/ui/typography'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { cn } from '@/lib/utils'

type HistoryTable = TableData<Custo>

const columnHelper = createColumnHelper<HistoryTable>()

const FirstVersionTabSection = () => {
    const { id } = useParams()

    const { data } = useCostHistoryQuery({ structureId: id! })

    const history = useMemo(() => {
        if (!data) return []

        // if (!Object.values(data.PRIMEIRA_VERSAO).every(Boolean)) return []

        const newVal = Object.values(data.PRIMEIRA_VERSAO)
            .filter(Boolean)
            .flatMap((value) => value!.CUSTO)

        return newVal
    }, [data])

    const columns = useMemo(() => {
        return [
            columnHelper.accessor(
                ({ DD_TIPO_CUSTO }) =>
                    DD_TIPO_CUSTO.includes('EXTRA')
                        ? 'Custo extra'
                        : 'Custo informado',
                {
                    id: 'DD_TIPO_CUSTO',
                    header: 'Tipo',
                    cell: ({ getValue }) => {
                        return (
                            <div
                                className={cn(
                                    'overflow-hidden text-ellipsis',
                                    getValue() === 'Custo extra'
                                        ? 'text-purple-500'
                                        : 'text-emerald-500'
                                )}
                            >
                                {getValue()}
                            </div>
                        )
                    },
                }
            ),
            columnHelper.accessor('NK_PRODUTO_COMPONENTE', {
                id: 'NK_PRODUTO_COMPONENTE',
                header: 'Cod componente',
                size: 120,
            }),
            columnHelper.accessor('PRODUTO_COMPONENTE', {
                id: 'PRODUTO_COMPONENTE',
                header: 'Componente',
                size: 400,
            }),
            columnHelper.accessor(
                ({ VL_CUSTO_ORCADO }) =>
                    currencyFormat(Number(VL_CUSTO_ORCADO), 4),
                {
                    id: 'VL_CUSTO_ORCADO',
                    header: 'Custo',
                }
            ),
            columnHelper.accessor('QTD_CUSTO', {
                id: 'QTD_CUSTO',
                header: 'Qtde',
            }),
            columnHelper.accessor(
                ({ SK_TEMPO }) => format(getDateFromId(SK_TEMPO), 'MM/yyyy'),
                {
                    id: 'SK_TEMPO',
                    header: 'Data',
                }
            ),
            columnHelper.accessor('DS_CUSTO', {
                id: 'DS_CUSTO',
                header: 'Descrição',
                size: 400,
            }),
            columnHelper.accessor(({ VL_PIS }) => VL_PIS || 0, {
                id: 'VL_PIS',
                header: 'Vl PIS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_PIS }) =>
                    percentageFormat(Number(VL_ALIQUOTA_PIS || 0), 4),
                {
                    id: 'VL_ALIQUOTA_PIS',
                    header: 'PIS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_COFINS }) => VL_COFINS || 0, {
                id: 'VL_COFINS',
                header: 'Vl COFINS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_COFINS }) => VL_ALIQUOTA_COFINS || 0,
                {
                    id: 'VL_ALIQUOTA_COFINS',
                    header: 'COFINS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_FCP }) => VL_FCP || 0, {
                id: 'VL_FCP',
                header: 'Vl FCP',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_FCP }) =>
                    percentageFormat(Number(VL_ALIQUOTA_FCP || 0), 4),
                {
                    id: 'VL_ALIQUOTA_FCP',
                    header: 'FCP (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_ICMS }) => VL_ICMS || 0, {
                id: 'VL_ICMS',
                header: 'Vl ICMS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_ICMS }) =>
                    percentageFormat(Number(VL_ALIQUOTA_ICMS || 0), 4),
                {
                    id: 'VL_ALIQUOTA_ICMS',
                    header: 'ICMS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_IPI }) => VL_IPI || 0, {
                id: 'VL_IPI',
                header: 'Vl IPI',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_IPI }) =>
                    percentageFormat(Number(VL_ALIQUOTA_IPI || 0), 4),
                {
                    id: 'VL_ALIQUOTA_IPI',
                    header: 'IPI (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_ST }) => VL_ST || 0, {
                id: 'VL_ST',
                header: 'Vl ST',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_ST }) =>
                    percentageFormat(Number(VL_ALIQUOTA_ST || 0), 4),
                {
                    id: 'VL_ALIQUOTA_ST',
                    header: 'ST (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
        ]
    }, [data])

    return (
        <div className="flex flex-col h-full overflow-hidden">
            <div className="mb-2">
                <TypographyP className="font-semibold text-md">
                    Primeira versão
                </TypographyP>
            </div>
            <div className="flex-1 overflow-hidden">
                {history.length > 0 ? (
                    <Table data={history} columns={columns} expandAllRows />
                ) : (
                    <div>
                        <TypographyP className="text-muted-foreground">
                            Cenários ainda não foram aprovados
                        </TypographyP>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FirstVersionTabSection
