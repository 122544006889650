import { CreateBulkInformedCostReturn } from '@/api/business/informedCost/types'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'

interface ResponseDialogProps {
    response: CreateBulkInformedCostReturn[]
    isOpen: boolean
    onClose: () => void
}

type ResponseDialogTable = CreateBulkInformedCostReturn &
    TableCustomData<CreateBulkInformedCostReturn>
const columnHelper = createColumnHelper<CreateBulkInformedCostReturn>()

const ResponseDialog = ({ response, isOpen, onClose }: ResponseDialogProps) => {
    const columns = useMemo(
        () => [
            columnHelper.accessor('NK_PRODUTO_ESTRUTURA', {
                id: 'NK_PRODUTO_ESTRUTURA',
                header: 'Cod produto',
            }),
            columnHelper.accessor('PRODUTO_ESTRUTURA', {
                id: 'PRODUTO_ESTRUTURA',
                header: 'Produto',
            }),
            columnHelper.accessor('NK_PRODUTO_COMPONENTE', {
                id: 'NK_PRODUTO_COMPONENTE',
                header: 'Cod componente',
            }),
            columnHelper.accessor('PRODUTO_COMPONENTE', {
                id: 'PRODUTO_COMPONENTE',
                header: 'Componente',
            }),
            columnHelper.accessor('BODY', {
                id: 'BODY',
                header: 'Erro',
                size: 300,
            }),
        ],
        []
    )

    const data = useMemo(() => response, [response])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent
                className={cn(
                    'flex flex-col max-w-4xl ',
                    data.length > 0 ? 'h-2/3' : 'h-fit'
                )}
            >
                <DialogHeader>
                    {data.length > 0 ? (
                        <>
                            <DialogTitle>Produtos não inseridos</DialogTitle>
                            <DialogDescription>
                                Os produtos com erros serão mantidos na tabela
                                para alteração
                            </DialogDescription>
                        </>
                    ) : (
                        <>
                            <DialogTitle>Inseridos com sucesso</DialogTitle>
                            <DialogDescription>
                                Todos os produtos foram inseridos com sucesso
                            </DialogDescription>
                        </>
                    )}
                </DialogHeader>
                <div className="flex-1 overflow-hidden">
                    <Table<ResponseDialogTable> data={data} columns={columns} />
                </div>
                <DialogFooter>
                    <Button
                        className={'px-6 py-2  rounded-md'}
                        variant={data.length > 0 ? 'destructive' : 'default'}
                        onClick={onClose}
                    >
                        Fechar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ResponseDialog
