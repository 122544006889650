import { Table } from '@tanstack/react-table'
import { CurrencyTable } from '.'
import { utils, writeFile } from 'xlsx'
import { CurrencyDS } from '@/types/Currency'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'

const HEADERS: {
    original: keyof CurrencyTable
    header: string
}[] = [
    { original: 'DS_MOEDA', header: 'MOEDA' },
    { original: 'SK_TEMPO', header: 'DATA' },
    { original: 'VL_MOEDA', header: 'VALOR' },
]

export const useCurrencySpreadsheet = () => {
    const getTableRows = (table: Table<CurrencyTable>): Partial<CurrencyDS>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original)) {
                const header = HEADERS.find((h) => h.original === key)?.header

                if (header && value) {
                    if (key === 'SK_TEMPO') {
                        obj[header] = format(getDateFromId(value), 'dd/MM/yyyy')
                    } else {
                        obj[header] = value.toString()
                    }
                }
            }

            return obj as CurrencyDS
        })

    const onDownload = (table: Table<CurrencyTable>) => {
        const rows = getTableRows(table)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Moedas')

        writeFile(workbook, 'Moeda.xlsx', {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
