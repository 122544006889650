import { isAxiosError } from '@/api/business'
import { fetchCurrencies } from '@/api/business/currency'
import { useToast } from '@/components/ui/use-toast'
import { CurrencyDS } from '@/types/Currency'
import { useQuery } from '@tanstack/react-query'

export const currencyKeys = {
    all: ['currency'] as const,
    lists: () => [...currencyKeys.all, 'list'] as const,
    list: (initial: number, end: number) =>
        [...currencyKeys.lists(), { initial, end }] as const,
    details: () => [...currencyKeys.all, 'detail'] as const,
    detail: (params: string) => [...currencyKeys.details(), params] as const,
}

interface CurrencyQuery {
    onSuccess?: (data: CurrencyDS[]) => void
}

export const useCurrencyQuery = (props?: CurrencyQuery) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () => fetchCurrencies(),
        queryKey: currencyKeys.lists(),
        staleTime: Infinity,
        onSuccess: props?.onSuccess,
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar as moedas. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
