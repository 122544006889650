import { useBaseStore } from '@/store'

import { ProductChild, ProductParent } from '@/store/productStructureSlice/type'
import { Store } from '@/store/type'
import { getDateFromId } from '@/utils/date'
import { useCallback, useEffect, useReducer } from 'react'

export interface EditDialogState {
    item: ProductParent | ProductChild | null
    quantity: string
    initial: string
    final: string
    initialDate: Date
    finalDate: Date
    own: 0 | 1
}

export enum EditDialogActionNames {
    SET_INITIAL_STATE = 'SET_INITIAL_STATE',
    ON_CHANGE_INITIAL_DATE = 'ON_CHANGE_INITIAL_DATE',
    ON_CHANGE_FINAL_DATE = 'ON_CHANGE_FINAL_DATE',
    ON_CHANGE_QUANTITY = 'ON_CHANGE_QUANTITY',
    ON_CHANGE_INITIAL = 'ON_CHANGE_INITIAL',
    ON_CHANGE_FINAL = 'ON_CHANGE_FINAL',
    ON_CHANGE_IS_OWN_PRODUCT = 'ON_CHANGE_IS_OWN_PRODUCT',
    RESET = 'RESET',
}

type EditDialogActions =
    | {
          type: EditDialogActionNames.SET_INITIAL_STATE
          payload: { item: ProductParent | ProductChild }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_INITIAL_DATE
          payload: { value: Date }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_FINAL_DATE
          payload: { value: Date }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_QUANTITY
          payload: {
              value: string
          }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_INITIAL
          payload: {
              value: string
          }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_FINAL
          payload: {
              value: string
          }
      }
    | {
          type: EditDialogActionNames.ON_CHANGE_IS_OWN_PRODUCT
          payload: {
              value: boolean
          }
      }
    | {
          type: EditDialogActionNames.RESET
      }

const stateSelector = (state: Store) => ({
    productStructures:
        state.productStructureSlice.state.droppedProductStructure,
    isOpen: state.productStructureSlice.state.isEditProductStructureDialogOpen,
    selected: state.productStructureSlice.state.selectedProductStructure,
    onOpenChange:
        state.productStructureSlice.actions.onChangeEditProductStructureDialog,
    onChangeProduct: state.productStructureSlice.actions.editItem,
})

const useEditProductDialog = () => {
    const {
        isOpen,
        productStructures,
        selected,
        onChangeProduct,
        onOpenChange,
    } = useBaseStore(stateSelector)

    const reducer = useCallback(
        (
            state: EditDialogState,
            action: EditDialogActions
        ): EditDialogState => {
            switch (action.type) {
                case EditDialogActionNames.SET_INITIAL_STATE: {
                    const { item } = action.payload

                    if (!item) return state

                    return {
                        ...state,
                        item,
                        final: item.DS_REVISAO_FINAL || '',
                        finalDate: item.SK_TEMPO_FINAL
                            ? getDateFromId(item.SK_TEMPO_FINAL)
                            : new Date(),
                        initial: item.DS_REVISAO_INICIAL || '',
                        initialDate: item.SK_TEMPO_INICIAL
                            ? getDateFromId(item.SK_TEMPO_INICIAL)
                            : new Date(),
                        quantity: item.QTD_COMPONENTE || '0',
                    }
                }
                case EditDialogActionNames.ON_CHANGE_INITIAL_DATE: {
                    const { value } = action.payload
                    return {
                        ...state,
                        initialDate: value,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_FINAL_DATE: {
                    const { value } = action.payload
                    return {
                        ...state,
                        finalDate: value,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_QUANTITY: {
                    const { value } = action.payload
                    return {
                        ...state,
                        quantity: value,
                    }
                }
                case EditDialogActionNames.ON_CHANGE_INITIAL: {
                    const { value } = action.payload
                    return {
                        ...state,
                        initial: value.toUpperCase(),
                    }
                }
                case EditDialogActionNames.ON_CHANGE_FINAL: {
                    const { value } = action.payload
                    return {
                        ...state,
                        final: value.toUpperCase(),
                    }
                }
                case EditDialogActionNames.ON_CHANGE_IS_OWN_PRODUCT: {
                    const { value } = action.payload
                    console.log(value)
                    return {
                        ...state,
                        own: value ? 1 : 0,
                    }
                }
                case EditDialogActionNames.RESET:
                    return {
                        final: '',
                        finalDate: new Date(),
                        initial: '',
                        initialDate: new Date(),
                        quantity: '0',
                        item: state.item,
                        own: 1,
                    }
                default:
                    return state
            }
        },
        []
    )

    const [state, dispatch] = useReducer(reducer, {} as EditDialogState)

    useEffect(() => {
        if (!isOpen) {
            dispatch({
                type: EditDialogActionNames.RESET,
            })
        } else {
            const item = productStructures
                .flatMap((company) => {
                    return company.productStructures.flatMap(
                        (productStructure) => {
                            const { products, id } = productStructure

                            return [
                                { ...productStructure },
                                ...products.map((product) => ({
                                    ...product,
                                    parentId: id,
                                })),
                            ]
                        }
                    )
                })
                .find(
                    (product) =>
                        product.id === selected?.productId &&
                        product.parentId === selected.parentId
                )
            if (item) {
                dispatch({
                    type: EditDialogActionNames.SET_INITIAL_STATE,
                    payload: { item },
                })
            }
        }
    }, [isOpen])

    return {
        ...state,
        isOpen,
        onOpenChange,
        onChangeProduct,
        dispatch,
    }
}
export default useEditProductDialog
