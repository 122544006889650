import instance from '..'
import {
    Approver,
    CreateApproverProps,
    DeleteApproverProps,
    UpdateApproverProps,
} from './types'

const BASE_ROUTE = '/aprovador/portalcusto/aprovador'

export const fetchCurrencies = async () => {
    const { data } = await instance.post<Approver[]>(BASE_ROUTE)

    return data
}

export const createApprover = async (props: CreateApproverProps) => {
    const { data } = await instance.put(BASE_ROUTE, {
        ...props,
    })

    return data
}

export const updateApprover = async (props: UpdateApproverProps) => {
    const { data } = await instance.patch(BASE_ROUTE, {
        ...props,
    })

    return data
}

export const deleteApprover = async ({ id }: DeleteApproverProps) => {
    const { data } = await instance.delete(BASE_ROUTE, {
        data: { SK_APROVADOR: id },
    })

    return data
}
