import {
    CreateProductionVolumeBulkResponse,
    CreateProductionVolumeBulkProps,
} from '@/api/business/productionVolume/types'
import { getIdFromDate } from '@/utils/date'
import { useCallback, useReducer } from 'react'
import { BulkData } from '.'
import { useProductQuery } from '@/queries/useProductQuery'

export interface BulkDialogState {}
export enum BulkDialogActionNames {
    SET_TABLE_DATA = 'SET_TABLE_DATA',
    UPDATE_TABLE_DATA = 'UPDATE_TABLE_DATA',
    SET_DATA_STATUS = 'SET_DATA_STATUS',
    ADD_ROW = 'ADD_ROW',
    REMOVE_ROW = 'REMOVE_ROW',
    RESET = 'RESET',
}

type BulkDialogActions =
    | {
          type: BulkDialogActionNames.SET_TABLE_DATA
          payload: CreateProductionVolumeBulkProps[]
      }
    | {
          type: BulkDialogActionNames.UPDATE_TABLE_DATA
          payload: { rowIdx: number; columnId: string; value: unknown }
      }
    | {
          type: BulkDialogActionNames.SET_DATA_STATUS
          payload: CreateProductionVolumeBulkResponse[]
      }
    | {
          type: BulkDialogActionNames.ADD_ROW
      }
    | {
          type: BulkDialogActionNames.REMOVE_ROW
          payload: {
              id: string
          }
      }
    | {
          type: BulkDialogActionNames.RESET
      }

interface State {
    tableData: BulkData[]
    feedbackResponse: CreateProductionVolumeBulkResponse[]
}

const initialState: State = {
    tableData: [],
    feedbackResponse: [],
}

const useBulkDialog = () => {
    const { data: products, isLoading } = useProductQuery({ enabled: true })

    const reducer = useCallback(
        (
            state: typeof initialState,
            action: BulkDialogActions
        ): typeof initialState => {
            switch (action.type) {
                case BulkDialogActionNames.SET_TABLE_DATA: {
                    const data = action.payload
                    return {
                        tableData: data.map((volume) => ({
                            ...volume,
                            id: crypto.randomUUID(),
                            DS_PRODUTO: products?.find((prod) => {
                                return (
                                    prod.SK_PRODUTO ===
                                    `${volume.SK_EMPRESA}${volume.NK_PRODUTO}`
                                )
                            })?.DS_PRODUTO,
                        })),
                        feedbackResponse: [],
                    }
                }
                case BulkDialogActionNames.SET_DATA_STATUS: {
                    const response = action.payload

                    return { ...state, feedbackResponse: response }
                }
                case BulkDialogActionNames.UPDATE_TABLE_DATA: {
                    const { columnId, rowIdx, value } = action.payload
                    return {
                        ...state,
                        tableData: state.tableData.map((row, idx) => {
                            if (idx === rowIdx) {
                                if (columnId === 'NK_PRODUTO') {
                                    return {
                                        ...state.tableData[rowIdx],
                                        [columnId]: value as string,
                                        DS_PRODUTO: products?.find(
                                            (prod) =>
                                                prod.SK_PRODUTO ===
                                                `${state.tableData[rowIdx]['SK_EMPRESA']}${value}`
                                        )?.DS_PRODUTO,
                                    }
                                }
                                if (columnId === 'SK_EMPRESA') {
                                    return {
                                        ...state.tableData[rowIdx],
                                        [columnId]: value as string,
                                        DS_PRODUTO: products?.find(
                                            (prod) =>
                                                prod.SK_PRODUTO ===
                                                `${value}${state.tableData[rowIdx]['NK_PRODUTO']}`
                                        )?.DS_PRODUTO,
                                    }
                                }
                                return {
                                    ...state.tableData[rowIdx],
                                    [columnId]: value,
                                }
                            }
                            return row
                        }),
                    }
                }

                case BulkDialogActionNames.ADD_ROW: {
                    return {
                        ...state,
                        tableData: [
                            ...state.tableData,
                            {
                                id: crypto.randomUUID(),
                                NK_PRODUTO: '',
                                SK_EMPRESA: '',
                                QTD_VOLUME: '0',
                                SK_TEMPO: Number(
                                    getIdFromDate(new Date()).join('')
                                ),
                            },
                        ],
                    }
                }
                case BulkDialogActionNames.REMOVE_ROW: {
                    const { id } = action.payload
                    return {
                        ...state,
                        tableData: state.tableData.filter(
                            (cost) => cost.id !== id
                        ),
                    }
                }
                case BulkDialogActionNames.RESET:
                    return initialState
                default:
                    return state
            }
        },
        [products]
    )

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        ...state,
        productsLoading: isLoading,
        dispatch,
    }
}
export default useBulkDialog
