import { Button } from '@/components/ui/button'
import { TypographyH1, TypographyLarge } from '@/components/ui/typography'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <div className="absolute flex flex-col items-center justify-center gap-4 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <div className="flex flex-col items-center gap-3">
                <TypographyH1 className="text-9xl text-primary">
                    404
                </TypographyH1>
                <TypographyLarge className="font-normal">
                    A página solicitada não foi encontrada.
                </TypographyLarge>
            </div>
            <Button
                variant="link"
                className="font-normal"
                onClick={() => navigate('/')}
            >
                Voltar ao início
            </Button>
        </div>
    )
}

export default NotFound
