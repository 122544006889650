import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { TypographyLarge } from '@/components/ui/typography'

const HistorySkeleton = () => {
    return (
        <div className="w-[420px] flex flex-col p-6 bg-background rounded-lg shadow-lg overflow-hidden">
            <div className="mb-4 space-y-2">
                <TypographyLarge className="text-foreground">
                    Histórico custo
                </TypographyLarge>
                <Separator />
            </div>
            <div className="flex-1 space-y-4 overflow-hidden overflow-y-auto">
                <Skeleton className="w-full h-[120px] rounded-sm" />
                <Skeleton className="w-full h-[120px] rounded-sm" />
                <Skeleton className="w-full h-[120px] rounded-sm" />
                <Skeleton className="w-full h-[120px] rounded-sm" />
            </div>
        </div>
    )
}

export default HistorySkeleton
