import instance from '..'
import { FinalProduct, ProductStructureFlat } from '@/types/Product'
import {
    CreateProductStructureProps,
    UpdateProductStructureProps,
} from './types'

export const fetchProductStructures = async () => {
    const { data } = await instance.post<ProductStructureFlat[]>(
        '/estruturadeproduto/portalcusto/estruturadeproduto'
    )

    return data
}

export const fetchFinalProducts = async () => {
    const { data } = await instance.post<FinalProduct[]>(
        '/estruturadeproduto/portalcusto/produtosapontados',
        {}
    )

    return data
}

export const createProductStructure = async (
    props: CreateProductStructureProps
) => {
    const { data } = await instance.put(
        '/estruturadeproduto/portalcusto/estruturadeproduto',
        { ...props }
    )

    return data
}

export const editProductStructure = async (
    props: UpdateProductStructureProps
) => {
    const { data } = await instance.patch(
        '/estruturadeproduto/portalcusto/estruturadeproduto',
        { ...props }
    )

    return data
}

export const deleteProductStructure = async ({
    SK_ESTRUTURA_DE_PRODUTO,
}: {
    SK_ESTRUTURA_DE_PRODUTO: number
}) => {
    const { data } = await instance.delete(
        '/estruturadeproduto/portalcusto/estruturadeproduto',
        {
            data: { SK_ESTRUTURA_DE_PRODUTO },
        }
    )

    return data
}
