import { Table } from '@tanstack/react-table'
import { ProductTable } from '.'
import { utils, writeFile } from 'xlsx'
import { Product } from '@/types/Product'

const HEADERS: {
    original: keyof ProductTable
    header: string
}[] = [
    { original: 'NK_PRODUTO', header: 'COD_PRODUTO' },
    { original: 'DS_PRODUTO', header: 'PRODUTO' },
    { original: 'SK_EMPRESA', header: 'COD_EMPRESA' },
    { original: 'ABREVIATURA_EMPRESA', header: 'ABREVIATURA_EMPRESA' },
    { original: 'DD_CLASSIFICACAO', header: 'CLASSIFICAÇÃO' },
    { original: 'DD_UM', header: 'UM' },
    { original: 'DD_GRUPO', header: 'GRUPO' },
    { original: 'DD_ORIGEM_DADO', header: 'ORIGEM' },
    { original: 'DD_BLOQUEADO', header: 'BLOQUEADO' },
]

export const useProductSpreadsheet = () => {
    const getTableRows = (table: Table<ProductTable>): Partial<Product>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original)) {
                const header = HEADERS.find((h) => h.original === key)?.original

                if (header && value) {
                    if (header === 'DD_BLOQUEADO') {
                        const blocked = value === 1 ? 'Bloqueado' : ''
                        obj[header] = blocked
                    } else {
                        obj[header] = value.toString()
                    }
                }
            }

            return obj as Product
        })

    const onDownload = (tableInstance: Table<ProductTable>) => {
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Produtos')

        writeFile(workbook, 'Produtos.xlsx', {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
