import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { costViewKeys, useCostViewQuery } from '@/queries/useCostViewQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteCostView } from '@/api/business/stdCost'
import { View } from '@/types/StandardCost'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import LayoutItem from './LayoutItem'
import { StdCostTableState } from '@/store/stdCostSlice/type'
import { useBaseStore } from '@/store'
import { TypographyP } from '@/components/ui/typography'
import { Button } from '@/components/ui/button'
import { Store } from '@/store/type'

interface LayoutsDialogProps {
    isOpen: boolean
    onOpen: (open: boolean) => void
    onOpenSaveLayoutDialog: () => void
}

const stateSelector = (state: Store) => ({
    date: state.stdCostSlice.state.selectedDate,
    tableInstance: state.stdCostSlice.state.tableInstance,
})

const LayoutsDialog = ({
    isOpen,
    onOpen,
    onOpenSaveLayoutDialog,
}: LayoutsDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { date, tableInstance } = useBaseStore(stateSelector)

    // const {
    //     onTableStateChange: setTableState,
    //     onTableColumnOrderChange: setColumnOrder,
    // } = useBaseStore(stdCostSliceActionsSelector)

    const { data } = useCostViewQuery({ SK_TEMPO: date.id })
    const { mutate } = useMutation({
        mutationFn: deleteCostView,
        onMutate: async (vars) => {
            const { SK_CONFIGURACAO_EXIBICAO } = vars

            // queryClient.cancelQueries(costViewKeys.list(date.id))

            const old = queryClient.getQueryData<View[]>(
                costViewKeys.list(date.id)
            )

            queryClient.setQueryData<View[]>(
                costViewKeys.list(date.id),
                (old) =>
                    old
                        ? old.filter(
                              (layout) =>
                                  layout.SK_CONFIGURACAO_EXIBICAO !==
                                  SK_CONFIGURACAO_EXIBICAO
                          )
                        : undefined
            )

            return { old }
        },
        onError: (err, _, ctx) => {
            queryClient.setQueryData<View[]>(
                costViewKeys.list(date.id),
                ctx?.old
            )
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao excluir layout',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(costViewKeys.list(date.id))
        },
    })

    const layouts = data
        ? data.filter(
              (view) => view.DD_TIPO_CONFIGURACAO === 'LAYOUT_CUSTO_PADRAO'
          )
        : []

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onOpen}>
                <DialogContent className="flex flex-col max-w-xl overflow-hidden max-h-[400px]">
                    <DialogHeader>
                        <DialogTitle>Layouts</DialogTitle>
                        <DialogDescription>
                            Selecione um layout para aplicar na tabela do custo
                            padrão.
                        </DialogDescription>
                    </DialogHeader>
                    {layouts.length === 0 && (
                        <div className="flex flex-col items-center justify-center h-[200px]">
                            <TypographyP>Não há layouts salvos.</TypographyP>
                            <div className="flex items-center gap-1">
                                <TypographyP>Deseja</TypographyP>
                                <Button
                                    className="p-0 text-primary-500"
                                    variant="link"
                                    onClick={onOpenSaveLayoutDialog}
                                >
                                    salvar o layout atual?
                                </Button>
                            </div>
                        </div>
                    )}
                    {data && (
                        <div className="flex-1 overflow-x-hidden overflow-y-auto">
                            {layouts.map((view) => {
                                return (
                                    <LayoutItem
                                        key={view.SK_CONFIGURACAO_EXIBICAO}
                                        {...view}
                                        onClick={() => {
                                            const tableState: StdCostTableState =
                                                JSON.parse(
                                                    view.DS_CONFIGURACAO_EXIBICAO
                                                )

                                            if (tableState) {
                                                tableInstance?.setState(
                                                    (prev) => ({
                                                        ...prev,
                                                        ...tableState,
                                                    })
                                                )
                                            }

                                            onOpen(false)
                                        }}
                                        onClickDelete={() =>
                                            mutate({
                                                SK_CONFIGURACAO_EXIBICAO:
                                                    view.SK_CONFIGURACAO_EXIBICAO,
                                            })
                                        }
                                    />
                                )
                            })}
                        </div>
                    )}
                    {/* <DialogFooter>
                        <Button className="bg-primary-500 hover:bg-primary-600">
                            Novo layout
                        </Button>
                    </DialogFooter> */}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default LayoutsDialog
