export type CurrencyType = 'DOLAR' | 'REAL' | 'EURO' | 'IENE' | 'FRANCO SUIÇO'

export enum CurrencyEnum {
    DOLAR = 'USD',
    REAL = 'BRL',
    EURO = 'EUR',
    IENE = 'JPY',
    'FRANCO SUIÇO' = 'CHF',
}

export type CurrencyDS = {
    SK_MOEDA: number
    DS_MOEDA: CurrencyType
}

export type Currency = { SK_MOEDA: number } & CurrencyT

export type CurrencyT =
    | {
          DS_MOEDA: 'DOLAR'
          MOEDA: 'USD'
          SIMBOLO: 'US$'
      }
    | {
          DS_MOEDA: 'REAL'
          MOEDA: 'BRL'
          SIMBOLO: 'R$'
      }
    | {
          DS_MOEDA: 'EURO'
          MOEDA: 'EUR'
          SIMBOLO: '€'
      }
    | {
          DS_MOEDA: 'IENE'
          MOEDA: 'JPY'
          SIMBOLO: '¥'
      }
    | {
          DS_MOEDA: 'FRANCO SUIÇO'
          MOEDA: 'CHF'
          SIMBOLO: 'SFr'
      }

export interface CurrencyValue {
    SK_VALOR_MOEDA: number
    SK_MOEDA: number
    SK_TEMPO: number
    VL_MOEDA: number
    DD_ORIGEM_DADO: 'PROTHEUS' | 'MANUAL'
    SK_USUARIO: number | null
    D_E_L_E_T_: '*' | null
    CREATED_AT: string
    UPDATED_AT: string
    DS_MOEDA: CurrencyType
    NOME: string | null
}
