import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { useState } from 'react'
import FlatTable from './components/FlatTable'
import TreeTable from './components/TreeTable'
import AddDialog from './components/AddDialog'
import useDisclosure from '@/hooks/useDisclosure'
import { useProductStructureSpreadsheet } from './useProductStructureSpreadsheet'

const ProductStructureDadosMestre = () => {
    const { data, isFetching, isLoading, refetch } = useProductStructureQuery({
        enabled: true,
    })

    const { onDownload } = useProductStructureSpreadsheet()
    const [isTreeStructure, setIsTreeStructure] = useState(true)

    const {
        isOpen: isAddDialogOpen,
        onClose: onAddDialogClose,
        onOpen: onAddDialogOpen,
    } = useDisclosure()

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            {isTreeStructure ? (
                <TreeTable
                    data={data || []}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    onAddDialogOpen={onAddDialogOpen}
                    onChangeTreeStructure={() =>
                        setIsTreeStructure((prev) => !prev)
                    }
                    onDownload={() => onDownload(data)}
                    refetch={refetch}
                    title={`Estrutura de produtos ${
                        data ? `(${data.length})` : ''
                    }`}
                />
            ) : (
                <FlatTable
                    data={data || []}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    onAddDialogOpen={onAddDialogOpen}
                    onChangeTreeStructure={() =>
                        setIsTreeStructure((prev) => !prev)
                    }
                    onDownload={() => onDownload(data)}
                    refetch={refetch}
                    title={`Estrutura de produtos ${
                        data ? `(${data.length})` : ''
                    }`}
                />
            )}
            {<AddDialog isOpen={isAddDialogOpen} onClose={onAddDialogClose} />}
        </div>
    )
}

export default ProductStructureDadosMestre
