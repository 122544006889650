import { Maximize, X } from 'lucide-react'
import { motion } from 'framer-motion'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { useEffect } from 'react'

import ComponentsSection from './components/ComponentsSection'
import ProductSection from './components/ProductSection'

interface ExpandedDetailsProps {
    onChangeFullscreen: (isOpen: boolean) => void
}

const stateSelector = (state: Store) => ({
    products: state.stdCostSlice.state.stdCostData.products,
    selectedStdCostRow: state.stdCostSlice.state.selectedStdCostRow,
})

const actionsSelector = (state: Store) => ({
    onCloseDetails: state.stdCostSlice.actions.onCloseDetails,
})

const ExpandedDetails = ({ onChangeFullscreen }: ExpandedDetailsProps) => {
    const { onCloseDetails } = useBaseStore(actionsSelector)

    const { products, selectedStdCostRow } = useBaseStore(stateSelector)

    const product = products.entities[selectedStdCostRow!.id]

    useEffect(() => {
        if (!selectedStdCostRow?.isProduct) {
            onChangeFullscreen(false)
        }
    }, [selectedStdCostRow])

    if (!product) return null

    return (
        <motion.div
            layoutId="details"
            layout="position"
            className="absolute bottom-6 flex flex-col overflow-hidden w-[1000px] right-6 top-6 z-[100] bg-background shadow-lg border rounded-lg"
            animate={{
                width: 1000,
            }}
        >
            <div className="flex justify-end gap-2 px-2 py-3 border-b">
                <button onClick={() => onChangeFullscreen(false)}>
                    <Maximize size={16} />
                </button>
                <button onClick={() => onCloseDetails()}>
                    <X size={16} />
                </button>
            </div>
            <div className="flex flex-1 w-full h-full overflow-hidden divide-x-[1px]">
                <ProductSection product={product} />
                <ComponentsSection product={product} />
            </div>
        </motion.div>
    )
}

export default ExpandedDetails
