import { isAxiosError } from '@/api/business'
import { fetchHistory } from '@/api/business/stdCost'
import { useToast } from '@/components/ui/use-toast'
import { useQuery } from '@tanstack/react-query'

export const historyKeys = {
    all: ['stdCostHistory'] as const,
    lists: () => [...historyKeys.all, 'list'] as const,
    list: (date: number) => [...historyKeys.lists(), date] as const,
    details: () => [...historyKeys.all, 'detail'] as const,
    detail: (props: { productId: string; componentId: string; date: number }) =>
        [...historyKeys.details(), { ...props }] as const,
}

export const useStdCostHitoryQuery = (props: {
    productId: string
    componentId: string
    date: number
}) => {
    const { toast } = useToast()

    return useQuery({
        queryFn: () =>
            fetchHistory({
                SK_PRODUTO_COMPONENTE: props.componentId,
                SK_PRODUTO_ESTRUTURA: props.productId,
                SK_TEMPO: props.date,
            }),
        queryKey: historyKeys.detail({ ...props }),
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para acessar histórico de custo. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                }
            }
        },
    })
}
