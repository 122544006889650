import { isAxiosError } from '@/api/business'
import { createProductionVolume } from '@/api/business/productionVolume'
import ConfirmDialog from '@/components/ConfirmDialog'
import { DatePicker } from '@/components/DatePicker'
import Select from '@/components/Select'
import { useToast } from '@/components/ui/use-toast'
import { useFinalProductsQuery } from '@/queries/useFinalProductsQuery'
import { productionVolumeKeys } from '@/queries/useProductionVolumeQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useEffect, useMemo, useState } from 'react'

interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { data, isLoading } = useFinalProductsQuery()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: createProductionVolume,
        onSuccess: () => {
            queryClient.invalidateQueries(productionVolumeKeys.lists())
            onClose()
            onReset()
            toast({
                title: 'Volume de produção adicionado com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar volume de produção',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const uniqueCompanies = useMemo(
        () => [...new Set(data?.map((product) => product.SK_EMPRESA))],
        [data]
    )

    const [selectedCompany, setSelectedCompany] = useState(
        uniqueCompanies[0] || ''
    )

    const [productFound, setProductFound] = useState(true)
    const [selectedProduct, setSelectedProduct] = useState('')
    const [quantity, setQuantity] = useState('0')
    const [date, setDate] = useState(new Date())

    useEffect(() => {
        if (data) {
            setSelectedCompany(uniqueCompanies[0])
        }
    }, [data])

    const onSubmit = () => {
        const product = data?.find(
            (product) =>
                product.SK_EMPRESA === selectedCompany &&
                product.NK_PRODUTO.toLowerCase() ===
                    selectedProduct.toLowerCase()
        )
        if (!product) {
            setProductFound(false)
        } else {
            mutate({
                SK_PRODUTO: product.SK_PRODUTO_ESTRUTURA,
                SK_EMPRESA: selectedCompany,
                QTD_VOLUME: quantity,
                SK_TEMPO: Number(format(date, 'yyyyMM') + '01'),
            })
        }
    }

    const onReset = () => {
        setProductFound(true)
        setSelectedProduct('')
        setSelectedCompany(uniqueCompanies[0] || '')
        setDate(new Date())
        setQuantity('0')
    }

    return (
        <ConfirmDialog
            title="Criar volume de produção"
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onSubmit}
            isLoading={mutateLoading}
        >
            <div className="pt-4 space-y-4">
                <div>
                    <label htmlFor="">Selecionar empresa</label>
                    {data && data.length > 0 ? (
                        <Select
                            placeholder={
                                isLoading ? `Carregando empresas...` : ''
                            }
                            value={selectedCompany}
                            options={uniqueCompanies.map((company) => ({
                                label: company,
                                value: company,
                            }))}
                            onChange={(value) => setSelectedCompany(value)}
                        />
                    ) : null}
                </div>
                <div>
                    <label htmlFor="">Selecionar produto</label>
                    <input
                        id="product"
                        className="block w-full p-2 border rounded-md"
                        list="products"
                        name="product"
                        placeholder={isLoading ? `Carregando produtos...` : ''}
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                    />
                    {data && data.length > 0 ? (
                        <datalist id="products">
                            {data
                                .filter(
                                    (product) =>
                                        product.SK_EMPRESA === selectedCompany
                                )
                                .map((product) => (
                                    <option
                                        key={product.SK_PRODUTO_ESTRUTURA}
                                        value={product.NK_PRODUTO}
                                        label={product.DS_PRODUTO}
                                    />
                                ))}
                        </datalist>
                    ) : null}
                    {!productFound && (
                        <p className="text-red-500">Insira um produto válido</p>
                    )}
                </div>
                <div>
                    <label htmlFor="">Quantidade</label>
                    <input
                        id="quantity"
                        className="block w-full p-2 border rounded-md"
                        name="quantity"
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="">Data</label>
                    <DatePicker
                        className="w-full"
                        date={date}
                        setDate={(newDate) => newDate && setDate(newDate)}
                        locale={ptBR}
                    />
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default AddDialog
