import { useStdCostHitoryQuery } from '@/queries/useStdCostHistoryQuery'
import { getRandomNumber } from '@/utils/math'
import Empty from '@/assets/images/empty.svg'
import HistoryItem from './components/HistoryItem'
import HistoryItemSkeleton from './components/HistoryItemSkeleton'
import { useBaseStore } from '@/store'
import { stdCostSliceStateSelector } from '@/store/stdCostSlice'
import { TypographyMuted } from '@/components/ui/typography'
// import { useStdCostSliceState } from '@/store/stdCostSlice'

const HistoryTab = () => {
    const {
        selectedStdCostRow: selectedRow,
        selectedDate: date,
        stdCostData: { components, products },
    } = useBaseStore(stdCostSliceStateSelector)

    const getParent = (parentId: string | number) => {
        const parent =
            products.entities[parentId] || components.entities[parentId]
        return parent._parentId ? products.entities[parent._parentId] : parent
    }

    const component = selectedRow && components.entities[selectedRow?.id]
    const product = component && getParent(component._parentId)

    const { data, isLoading } = useStdCostHitoryQuery({
        productId: product?.SK_PRODUTO_ESTRUTURA || '',
        componentId: component?.SK_PRODUTO_COMPONENTE || '',
        date: date.id,
    })

    return (
        <div className="relative w-full h-full">
            {isLoading && (
                <div className="relative w-full h-fit">
                    <div className="absolute left-7 w-[1px] h-full bg-neutral-100"></div>
                    <HistoryItemSkeleton total={getRandomNumber(2, 5)} />
                </div>
            )}
            {data?.length === 0 && (
                <div className="flex flex-col items-center justify-center w-full gap-8 h-1/2">
                    <img src={Empty} className="w-1/2 opacity-90" alt="" />
                    <TypographyMuted>
                        Não há histórico para esse produto
                    </TypographyMuted>
                </div>
            )}
            {data && data.length > 0 ? (
                <div className="relative w-full h-fit">
                    <div className="absolute left-7 w-[1px] h-full bg-border"></div>
                    {data.map((history, idx) => (
                        <HistoryItem
                            key={idx}
                            user={history.USUARIO}
                            name={history.NOME}
                            email={history.EMAIL}
                            cost={Number(history.VL_CUSTO_ORCADO)}
                            currency={history.DS_MOEDA}
                            costView={history.DD_VISAO}
                            createdAt={new Date(history.CREATED_AT)}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default HistoryTab
