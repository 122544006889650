import DatePickerMonthWithRange from '@/components/DatePickerMonthWithRange'
import Select from '@/components/Select'
import { useToast } from '@/components/ui/use-toast'
import { useCurrencyQuery } from '@/queries/useCurrencyQuery'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
    addMonths,
    eachDayOfInterval,
    endOfMonth,
    isAfter,
    isBefore,
    isSameMonth,
    isSameYear,
    isSunday,
} from 'date-fns'
import { createCurrencyValue } from '@/api/business/currency'
import { getIdFromDate } from '@/utils/date'
import { currencyValuesKeys } from '@/queries/useCurrencyValuesQuery'
import { isAxiosError } from '@/api/business'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { useEffect } from 'react'

interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    date: z.object({
        to: z.date(),
        from: z.date(),
    }),
    value: z.number(),
    currencyId: z.number({ message: 'Moeda inválida' }),
})

type AddSchema = z.infer<typeof schema>

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { data } = useCurrencyQuery()

    const { mutate, isLoading } = useMutation({
        mutationFn: createCurrencyValue,
        onSuccess: () => {
            queryClient.invalidateQueries(currencyValuesKeys.lists())
            onClose()
            toast({
                title: 'Valor de moeda adicionado com sucesso',
            })
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao criar valor de moeda',
                        description:
                            'Verifique se os dados estão corretos e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const form = useForm<AddSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            date: {
                from: new Date(),
                to: addMonths(new Date(), 1),
            },
            currencyId: data ? data[0].SK_MOEDA : 0,
            value: 0,
        },
    })

    const onSubmit = ({ date, value, currencyId }: AddSchema) => {
        const dates = eachDayOfInterval({
            start: date.from,
            end: endOfMonth(date.to),
        })
            .filter((day) => isAfter(day, new Date()) && !isSunday(day))
            .map((day) => Number(getIdFromDate(day).join('')))

        mutate({
            SK_MOEDA: Number(currencyId),
            SK_TEMPO: dates,
            VL_MOEDA: Number(value),
        })
    }

    const currentDate = new Date()

    useEffect(() => {
        if (data)
            form.resetField('currencyId', { defaultValue: data[0].SK_MOEDA })
    }, [data])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Criar valor de moeda</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            name="currencyId"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Selecionar moeda</FormLabel>
                                    <Select
                                        value={field.value?.toString()}
                                        onChange={(value) =>
                                            field.onChange(Number(value))
                                        }
                                        options={
                                            data?.map((currency) => ({
                                                value: currency.SK_MOEDA.toString(),
                                                label: currency.DS_MOEDA,
                                            })) || []
                                        }
                                    />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="value"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Valor</FormLabel>
                                    <Input
                                        className="w-full p-2 border rounded-md"
                                        type="number"
                                        {...field}
                                        onChange={(e) =>
                                            field.onChange(
                                                Number(e.target.value)
                                            )
                                        }
                                    />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="date"
                            control={form.control}
                            render={({ field }) => {
                                const date = field.value

                                return (
                                    <FormItem>
                                        <FormLabel>Selecionar data</FormLabel>
                                        <DatePickerMonthWithRange
                                            className="w-full"
                                            startMonthRange={{
                                                start: isSameYear(
                                                    date.from,
                                                    currentDate
                                                )
                                                    ? currentDate.getMonth()
                                                    : 0,
                                            }}
                                            endMonthRange={{
                                                start:
                                                    (isBefore(
                                                        date.from,
                                                        date.to
                                                    ) &&
                                                        isSameYear(
                                                            date.from,
                                                            date.to
                                                        )) ||
                                                    isSameMonth(
                                                        date.from,
                                                        date.to
                                                    )
                                                        ? Math.min(
                                                              date.from.getMonth(),
                                                              11
                                                          )
                                                        : 0,
                                                end: 11,
                                            }}
                                            startYearRange={{
                                                start: currentDate.getFullYear(),
                                            }}
                                            endYearRange={{
                                                start: currentDate.getFullYear(),
                                            }}
                                            date={date}
                                            setDate={(value) =>
                                                field.onChange(value)
                                            }
                                        />
                                    </FormItem>
                                )
                            }}
                        />
                        <DialogFooter>
                            <Button onClick={onClose} variant="ghost">
                                Cancelar
                            </Button>
                            <Button disabled={isLoading}>Confirmar</Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog
