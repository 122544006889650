import { Row } from '@tanstack/react-table'
import { CurrencyTable } from '../..'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { updateCurrencyValue } from '@/api/business/currency'
import { currencyValuesKeys } from '@/queries/useCurrencyValuesQuery'
import { isAxiosError } from '@/api/business'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Form, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useEffect } from 'react'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'

interface EditDialogProps {
    row: Row<CurrencyTable>
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    value: z.number(),
})

type EditSchema = z.infer<typeof schema>

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading } = useMutation({
        mutationFn: updateCurrencyValue,
        onSuccess: () => {
            queryClient.invalidateQueries(currencyValuesKeys.lists())
            toast({
                title: 'Custo da moeda atualizado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao atualizar custo da moeda',
                        description: 'Verifique os dados e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const { original } = row

    const form = useForm<EditSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            value: original.VL_MOEDA,
        },
    })

    const onSubmit = ({ value }: EditSchema) => {
        mutate({
            IDENTIFICADOR: original.SK_VALOR_MOEDA,
            CAMPOS: {
                VL_MOEDA: Number(value),
            },
        })
    }

    useEffect(() => {
        form.reset({
            value: original.VL_MOEDA,
        })
    }, [row])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Editar valor de moeda</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                    >
                        <FormItem>
                            <FormLabel>Moeda</FormLabel>
                            <Input value={original.DS_MOEDA} readOnly />
                        </FormItem>
                        <FormItem>
                            <FormLabel>Selecionar data</FormLabel>
                            <Input value={original.SK_TEMPO} readOnly />
                        </FormItem>
                        <FormField
                            name="value"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Valor</FormLabel>
                                    <Input
                                        className="w-full p-2 border rounded-md"
                                        type="number"
                                        {...field}
                                        onChange={(e) =>
                                            field.onChange(
                                                Number(e.target.value)
                                            )
                                        }
                                    />
                                </FormItem>
                            )}
                        />

                        <DialogFooter>
                            <Button onClick={onClose} variant="ghost">
                                Cancelar
                            </Button>
                            <Button disabled={isLoading}>Confirmar</Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
