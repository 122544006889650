import { Checkbox } from '@/components/ui/checkbox'
import {
    Dialog,
    DialogHeader,
    DialogTitle,
    DialogContent,
    DialogDescription,
    DialogFooter,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyP } from '@/components/ui/typography'
import { useProductStructureQuery } from '@/queries/useProductStructureQuery'
import { ProductStructure } from '@/types/Product'
import { useEffect, useMemo, useState } from 'react'

type ProductStructureDialogProps = {
    isOpen: boolean
    replace: boolean
    onReplaceChange: (replace: boolean) => void
    onClose: () => void
    onSelect: (product: ProductStructure) => void
}

const ProductStructureDialog = ({
    isOpen,
    replace = true,
    onReplaceChange,
    onClose,
    onSelect,
}: ProductStructureDialogProps) => {
    const [filteredProducts, setFilteredProducts] = useState<
        ProductStructure[]
    >([])

    const [selectedCompany, setSelectedCompany] = useState<string>('')
    const [inputValue, setInputValue] = useState('')

    const { data = [] } = useProductStructureQuery({ enabled: isOpen })

    const { products, companies } = useMemo(() => {
        const productsObj: Record<string, ProductStructure> = {}
        const companiesObj: Record<
            string,
            { SK_EMPRESA: number; ABREVIATURA_EMPRESA: string }
        > = {}

        const stdStructure = data.filter(
            (product) => product.DD_ESTRUTURA_PADRAO === 1
        )

        for (const product of stdStructure) {
            const { DD_ORIGEM_DADO, SK_PRODUTO_ESTRUTURA, SK_EMPRESA } = product

            const id = `${SK_PRODUTO_ESTRUTURA}${DD_ORIGEM_DADO}`

            if (!productsObj[id]) {
                productsObj[id] = {
                    ...product,
                    COMPONENTES: stdStructure.filter(
                        (comp) =>
                            comp.SK_PRODUTO_ESTRUTURA ===
                                SK_PRODUTO_ESTRUTURA &&
                            comp.DD_ORIGEM_DADO === DD_ORIGEM_DADO
                    ),
                }
            }

            if (!companiesObj[SK_EMPRESA]) {
                companiesObj[SK_EMPRESA] = {
                    ABREVIATURA_EMPRESA: product.ABREVIATURA_EMPRESA,
                    SK_EMPRESA: product.SK_EMPRESA,
                }
            }
        }

        const products = Object.values(productsObj).sort((a, b) =>
            a.PRODUTO_ESTRUTURA.localeCompare(b.PRODUTO_ESTRUTURA)
        )

        const companies = Object.values(companiesObj)

        return { products, companies }
    }, [data])

    useEffect(() => {
        if (filteredProducts.length === 0 && products.length > 0) {
            const company = companies[0].SK_EMPRESA.toString()

            setSelectedCompany(company)
            setFilteredProducts(filterProducts('', company, products))
        }
    }, [data])

    const filterProducts = (
        value: string,
        company: string,
        products: any[]
    ) => {
        const lowerCaseValue = value.toLowerCase()
        return products.filter(
            (product) =>
                (product.PRODUTO_ESTRUTURA.toLowerCase().includes(
                    lowerCaseValue
                ) ||
                    product.SK_PRODUTO_ESTRUTURA.includes(value)) &&
                product.SK_EMPRESA === company
        )
    }

    const onChangeInput = (value: string) => {
        setInputValue(value)
        setFilteredProducts(filterProducts(value, selectedCompany, products))
    }

    const onChangeCompany = (value: string) => {
        setSelectedCompany(value)
        setFilteredProducts(filterProducts(inputValue, value, products))
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="h-[720px] max-w-[680px] flex flex-col overflow-hidden">
                <DialogHeader>
                    <DialogTitle>Estruturas de produto</DialogTitle>
                    <DialogDescription>
                        Selecione uma estrutura de produto
                    </DialogDescription>
                </DialogHeader>
                <div className="flex items-center gap-4">
                    <Input
                        className="flex-1"
                        placeholder="Buscar por código ou produto"
                        value={inputValue}
                        onChange={(e) => onChangeInput(e.target.value)}
                    />
                    <Select
                        onValueChange={onChangeCompany}
                        value={selectedCompany?.toString()}
                    >
                        <SelectTrigger className="w-[150px]">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {companies.map((company) => {
                                    return (
                                        <SelectItem
                                            key={company.SK_EMPRESA}
                                            value={company.SK_EMPRESA.toString()}
                                        >
                                            {company.ABREVIATURA_EMPRESA}
                                        </SelectItem>
                                    )
                                })}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex-1 overflow-auto">
                    {filteredProducts.map((product) => {
                        return (
                            <div
                                key={product.SK_ESTRUTURA_DE_PRODUTO}
                                className="p-4 rounded-md cursor-pointer hover:bg-muted"
                                onClick={() => onSelect(product)}
                            >
                                <div className="flex items-center justify-between gap-4">
                                    <TypographyP className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis text-primary">
                                        {product.NK_PRODUTO} -{' '}
                                        {product.PRODUTO_ESTRUTURA}
                                    </TypographyP>
                                    <span className="text-xs">
                                        {product.DD_ORIGEM_DADO}
                                    </span>
                                </div>
                                <div>
                                    <TypographyP>
                                        {product.ABREVIATURA_EMPRESA}
                                    </TypographyP>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <DialogFooter>
                    <div className="flex items-center gap-2">
                        <Checkbox
                            checked={replace}
                            onCheckedChange={(value) =>
                                onReplaceChange(!!value)
                            }
                            id="replace"
                        />
                        <Label htmlFor="replace">Substituir valores</Label>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ProductStructureDialog
