import instance from '..'
import {
    CreateProductionVolumeBulkProps,
    CreateProductionVolumeBulkResponse,
    CreateProductionVolumeProps,
    DeleteProductionVolumeProps,
    UpdateProductionVolumeProps,
} from './types'
import { ProductionVolume } from '@/types/Product'

export const fetchProductionVolume = async () => {
    const { data } = await instance.post<ProductionVolume[]>(
        '/volume/portalcusto/volumeproducao'
    )

    return data
}

export const createProductionVolume = async (
    props: CreateProductionVolumeProps
) => {
    const { data } = await instance.put('/volume/portalcusto/volumeproducao', {
        ...props,
    })

    return data
}

export const createProductionVolumeBulk = async (
    props: CreateProductionVolumeBulkProps[]
) => {
    const { data } = await instance.put<CreateProductionVolumeBulkResponse[]>(
        '/volume/portalcusto/carga/volumeproducao',
        [...props]
    )

    return data
}

export const updateProductionVolume = async (
    props: UpdateProductionVolumeProps
) => {
    const { data } = await instance.patch(
        '/volume/portalcusto/volumeproducao',
        {
            ...props,
        }
    )

    return data
}
export const deleteProductionVolume = async (
    props: DeleteProductionVolumeProps
) => {
    const { data } = await instance.delete(
        '/volume/portalcusto/volumeproducao',
        {
            data: { ...props },
        }
    )

    return data
}
