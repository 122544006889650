import { useState } from 'react'
import icon from '../../assets/icons/icon.png'
import { Eye, EyeOff } from 'lucide-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useBaseStore } from '@/store'
import { useMutation } from '@tanstack/react-query'
import { isAxiosError } from '@/api/business'
import { authenticateUser } from '@/api/auth/user'
import { AxiosError } from 'axios'
import { useToast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import { Store } from '@/store/type'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { TypographyH3, TypographyMuted } from '@/components/ui/typography'
import { Input } from '@/components/ui/input'
import { getHeaders } from '@/api/business/utils'

const stateSelector = (state: Store) => ({
    login: state.authSlice.actions.login,
})

const loginSchema = z.object({
    username: z.string().min(1, 'Campo obrigatório'),
    password: z.string().min(1, 'Campo obrigatório'),
})

type LoginSchemaType = z.infer<typeof loginSchema>

const Login = () => {
    const [passwordVisible, setPasswordVisible] = useState(false)

    const { toast } = useToast()
    const { state } = useLocation()
    const navigate = useNavigate()

    const { login } = useBaseStore(stateSelector)

    const { mutate: onLogin, isLoading } = useMutation({
        mutationFn: authenticateUser,
        onSuccess: (data) => {
            login({
                token: data.token,
                user: data.nomeUsuario,
                userId: null,
            })

            navigate(state?.path || '/')
        },
        onError: (err: AxiosError) => {
            if (isAxiosError(err)) {
                toast({
                    title: 'Erro ao fazer login',
                    description: 'Usuário ou senha incorretos',
                    variant: 'destructive',
                })
            }
        },
    })

    const form = useForm<LoginSchemaType>({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            password: '',
            username: '',
        },
    })

    const onSubmit = async (data: LoginSchemaType) => {
        onLogin({ user: data.username, password: data.password })
    }

    return (
        <Form {...form}>
            <form
                className="absolute top-1/2 left-1/2 shadow-md border rounded-md flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col gap-4 p-10 sm:w-[480px]"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <div className="flex items-center gap-2 mx-auto">
                    <img
                        src={icon}
                        className="w-8 h-8 md:w-11 md:h-11"
                        alt="Portal icon"
                    />
                    <TypographyH3>Portal Custo</TypographyH3>
                </div>
                <FormField
                    name="username"
                    control={form.control}
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>Usuário</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )
                    }}
                />
                <FormField
                    name="password"
                    control={form.control}
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>Senha</FormLabel>
                                <FormControl>
                                    <div className="flex items-center gap-2">
                                        <Input
                                            {...field}
                                            type={
                                                passwordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                        />
                                        {passwordVisible ? (
                                            <EyeOff
                                                className="cursor-pointer hover:text-neutral-500"
                                                onClick={() =>
                                                    setPasswordVisible(
                                                        (prev) => !prev
                                                    )
                                                }
                                                size={24}
                                            />
                                        ) : (
                                            <Eye
                                                className="cursor-pointer hover:text-neutral-500"
                                                onClick={() =>
                                                    setPasswordVisible(
                                                        (prev) => !prev
                                                    )
                                                }
                                                size={24}
                                            />
                                        )}
                                    </div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )
                    }}
                />
                <TypographyMuted asChild className="text-end hover:underline">
                    <Link to="/recuperar-senha">Recuperar senha</Link>
                </TypographyMuted>

                <Button className="w-full md:py-3" disabled={isLoading}>
                    Entrar
                </Button>
                <TypographyMuted className="text-xs text-center">
                    V{getHeaders()['x-versao']}
                </TypographyMuted>
            </form>
        </Form>
    )
}

export default Login
