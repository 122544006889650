import { Skeleton } from '@/components/ui/skeleton'

interface HistoryItemSkeletonProps {
    total: number
}

const HistoryItemSkeleton = ({ total }: HistoryItemSkeletonProps) => {
    return (
        <>
            {Array.from(Array(total).keys()).map((_, idx) => (
                <div className="flex items-start gap-2 p-4" key={idx}>
                    <Skeleton className="w-6 h-6 rounded-full" />
                    <div className="flex-1">
                        <div className="flex items-start gap-1 mb-2 text-sm">
                            <div className="space-y-1">
                                <Skeleton className="w-[200px] h-4 rounded-none" />
                                <Skeleton className="w-[80px] h-3 rounded-none" />
                            </div>
                        </div>
                        <Skeleton className="w-full h-5 rounded-none" />
                    </div>
                </div>
            ))}
        </>
    )
}

export default HistoryItemSkeleton
