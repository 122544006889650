import ConfirmDialog from '@/components/ConfirmDialog'
import { Row } from '@tanstack/react-table'
import { ProductionVolumeTable } from '../..'
import { updateProductionVolume } from '@/api/business/productionVolume'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DatePicker } from '@/components/DatePicker'
import { useEffect, useState } from 'react'
import { ptBR } from 'date-fns/locale'
import { format } from 'date-fns'
import { productionVolumeKeys } from '@/queries/useProductionVolumeQuery'
import { useToast } from '@/components/ui/use-toast'
import { getDateFromId } from '@/utils/date'
import { isAxiosError } from '@/api/business'

interface EditDialogProps {
    row: Row<ProductionVolumeTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, ...props }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate, isLoading: mutateLoading } = useMutation({
        mutationFn: updateProductionVolume,
        onSuccess: () => {
            queryClient.invalidateQueries(productionVolumeKeys.lists())
            toast({
                title: 'Volume de produção atualizado com sucesso',
            })
            props.onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao atualizar volume de produção',
                        description: 'Verifique os dados e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const { original } = row

    const [quantity, setQuantity] = useState(original.QTD_VOLUME)
    const [date, setDate] = useState(getDateFromId(original.SK_TEMPO))

    useEffect(() => {
        setQuantity(original.QTD_VOLUME)
        setDate(getDateFromId(original.SK_TEMPO))
    }, [row])

    const onSubmit = () => {
        if (!original || !quantity || !date) return

        mutate({
            IDENTIFICADOR: original.SK_VOLUME_PRODUCAO,
            CAMPOS: {
                QTD_VOLUME: quantity,
                SK_TEMPO: Number(format(date, 'yyyyMM') + '01'),
            },
        })
    }

    return (
        <ConfirmDialog
            title="Editar volume de produção"
            {...props}
            isLoading={mutateLoading}
            onConfirm={onSubmit}
        >
            <div className="pt-4 space-y-4">
                <div>
                    <label htmlFor="">Produto</label>
                    <input
                        className="w-full p-2 border rounded-md text-neutral-400"
                        type="text"
                        disabled
                        value={original.DS_PRODUTO}
                    />
                </div>
                <div>
                    <label htmlFor="">Quantidade</label>
                    <input
                        id="quantity"
                        className="block w-full p-2 border rounded-md"
                        name="quantity"
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="">Data</label>
                    <DatePicker
                        className="w-full"
                        date={date}
                        selected={date}
                        setDate={(newDate) => newDate && setDate(newDate)}
                        locale={ptBR}
                    />
                </div>
            </div>
        </ConfirmDialog>
    )
}

export default EditDialog
