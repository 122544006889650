import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { useProductionVolumeQuery } from '@/queries/useProductionVolumeQuery'
import { ProductionVolume } from '@/types/Product'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns-tz'
import { ReactNode, useMemo } from 'react'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import { getDateFromId } from '@/utils/date'
import { isBefore, isEqual, isToday } from 'date-fns'
import DadosMestreHeader from '../components/DadosMestreHeader'
import { useProductionVolumeSpreadsheet } from './useProductionVolumeSpreadsheet'
import BulkDialog from './components/BulkDialog'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'

export type ProductionVolumeTable = ProductionVolume &
    TableCustomData<ProductionVolume>

const columnHelper = createColumnHelper<ProductionVolume>()

const ProductionVolumeDadosMestre = () => {
    const { data, isFetching, isLoading, refetch } = useProductionVolumeQuery()

    const {
        table,
        isAddDialogOpen,
        isBulkDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen,
        selectedRow,
        selectedRows,
        setSelectedRows,
        setTable,
        onAddDialogClose,
        onBulkDialogClose,
        onBulkDialogOpen,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose,
    } = useTable<ProductionVolumeTable>(
        import.meta.env.VITE_DADOS_MESTRE_PRODUCTION_VOLUME_TABLE_TOKEN
    )

    const { onDownload } = useProductionVolumeSpreadsheet()

    const memoData = useMemo(
        () =>
            data?.sort((a, b) =>
                isBefore(new Date(a.CREATED_AT), new Date(b.CREATED_AT))
                    ? 1
                    : -1
            ) || [],
        [data]
    )
    const memoColumns = useMemo(
        () => [
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    return (
                        <TableRowActions
                            onClickDelete={() => onClickDelete(row)}
                            onClickEdit={() => onClickEdit(row)}
                        />
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'empresa',
                header: 'Empresa',
                cell: ({ getValue }) => getValue(),
                size: 120,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'sk_produto',
                header: 'Cod Produto',
                cell: ({ getValue }) => getValue(),
                size: 120,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'produto',
                header: 'Produto',
                cell: ({ getValue }) => getValue(),
                size: 400,
            }),

            columnHelper.accessor(
                ({ QTD_VOLUME }) =>
                    Intl.NumberFormat().format(Number(QTD_VOLUME)),
                {
                    id: 'QTD_VOLUME',
                    header: 'Qtde',
                    cell: ({ column, getValue }) => {
                        column.columnDef.meta = {
                            cell: {
                                className: 'justify-end',
                            },
                        }

                        return getValue()
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(
                ({ SK_TEMPO }) => format(getDateFromId(SK_TEMPO), 'MM/yyyy'),
                {
                    id: 'SK_TEMPO',
                    header: 'Período de produção',
                    cell: ({ getValue }) => getValue(),
                    size: 90,
                }
            ),
            columnHelper.accessor(
                ({ INFORMADO }) =>
                    format(new Date(INFORMADO), 'dd/MM/yyyy HH:mm:ss', {
                        timeZone: 'America/Sao_Paulo',
                    }),
                {
                    id: 'INFORMADO',
                    header: 'Data de criação',
                    cell: ({ getValue }) => getValue(),
                }
            ),
            columnHelper.display({
                id: 'badge',
                cell: ({ row, column }) => {
                    const { CREATED_AT, INFORMADO } = row.original
                    let Badge: ReactNode | null = null
                    const createdAt = new Date(CREATED_AT)
                    const updatedAt = new Date(INFORMADO)

                    if (isEqual(createdAt, updatedAt)) {
                        if (isToday(createdAt)) {
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-emerald-500 ">
                                    Novo *
                                </span>
                            )
                        }
                    } else {
                        Badge = (
                            <span className="px-2 py-1 text-xs rounded-md text-zinc-400">
                                Editado
                            </span>
                        )
                    }

                    column.columnDef.meta = {
                        cell: {
                            className: 'flex items-center justify-center',
                        },
                    }

                    return Badge
                },
                size: 80,
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<ProductionVolumeTable>
                data={memoData}
                isLoading={isLoading}
                isFetching={isFetching}
                columns={memoColumns}
                getRowId={(row) => row.SK_VOLUME_PRODUCAO.toString()}
                getTableInstance={(table) => setTable(table)}
                enableRowSelection
                persist={{
                    canPersist: true,
                    key: 'production-volume-table',
                }}
                tableState={{
                    rowSelection: selectedRows,
                }}
                onRowSelectionChange={setSelectedRows}
                tableHeader={
                    <DadosMestreHeader
                        title={`Volume de produção ${
                            data ? `(${data.length})` : ''
                        }`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onAdd={onClickAdd}
                        onDownload={() => table && onDownload(table)}
                        onRefech={refetch}
                        onBulk={onBulkDialogOpen}
                    />
                }
                meta={{
                    layout: 'stretch',
                }}
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_VOLUME_PRODUCAO]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_VOLUME_PRODUCAO)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                    setSelectedRows={setSelectedRows}
                />
            )}
            {selectedRow && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            <AddDialog isOpen={isAddDialogOpen} onClose={onAddDialogClose} />
            {isBulkDialogOpen && table ? (
                <BulkDialog
                    isOpen={isBulkDialogOpen}
                    onClose={onBulkDialogClose}
                />
            ) : null}
        </div>
    )
}

export default ProductionVolumeDadosMestre
