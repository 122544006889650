import { Coins, LucideIcon, Table } from 'lucide-react'

export const SIDENAV_ITEMS: SidenavItems[] = [
    {
        id: 1,
        label: 'Visualizar custos',
        to: '/custos',
        Icon: Coins,
    },
    {
        id: 2,
        label: 'Dados mestre',
        Icon: Table,
        subItems: [
            {
                id: 2.6,
                label: 'Custo extra',
                to: '/dados-mestre/custo-extra',
            },
            {
                id: 2.1,
                label: 'Custo informado',
                to: '/dados-mestre/custo-informado',
            },
            {
                id: 2.7,
                label: 'Destinatários',
                to: '/dados-mestre/aprovadores',
            },
            {
                id: 2.2,
                label: 'Estrutura de produto',
                to: '/dados-mestre/estrutura-produto',
            },
            {
                id: 2.3,
                label: 'Moeda',
                to: '/dados-mestre/moeda',
            },
            {
                id: 2.4,
                label: 'Produto',
                to: '/dados-mestre/produto',
            },
            {
                id: 2.5,
                label: 'Volume de produção',
                to: '/dados-mestre/volume-producao',
            },
        ],
    },
]

export type SidenavItems = {
    id: number
    label: string
    to?: string
    Icon?: LucideIcon
    subItems?: SidenavItems[]
}
