import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import { useExtraCostQuery } from '@/queries/useExtraCostQuery'
import { ExtraCost } from '@/types/Costs'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns-tz'
import { ReactNode, useMemo } from 'react'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import { isBefore, isEqual, isToday } from 'date-fns'
import DadosMestreHeader from '../components/DadosMestreHeader'
import { useExtraCostSpreadsheet } from './useExtraCostSpreadsheet'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import BulkDialog from './components/BulkDialog'
import { getDateFromId } from '@/utils/date'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'
import { statusLocale } from '@/types/Status'
import StructureStatus from '@/components/StructureStatus'
import { Eye } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

export type ExtraCostTable = ExtraCost & TableCustomData<ExtraCost>

const columnHelper = createColumnHelper<ExtraCost>()

const ExtraCostDadosMestre = () => {
    const { data, isFetching, isLoading, refetch } = useExtraCostQuery()
    const navigate = useNavigate()

    const {
        table,
        isAddDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen,
        selectedRow,
        selectedRows,
        isBulkDialogOpen,
        setSelectedRows,
        onAddDialogClose,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose,
        setTable,
        onBulkDialogOpen,
        onBulkDialogClose,
    } = useTable<ExtraCostTable>(
        import.meta.env.VITE_DADOS_MESTRE_EXTRA_COST_TABLE_TOKEN
    )

    const { onDownload } = useExtraCostSpreadsheet(table)

    const memoData = useMemo(
        () =>
            data?.sort((a, b) =>
                isBefore(new Date(a.CREATED_AT), new Date(b.CREATED_AT))
                    ? 1
                    : -1
            ) || [],
        [data]
    )

    const memoColumns = useMemo(
        () => [
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    const {
                        DD_ORIGEM_DADO,
                        SK_PRODUTO_ESTRUTURA,
                        SK_PRODUTO_COMPONENTE,
                        SK_NOVO_CUSTO,
                    } = row.original
                    return (
                        <TableRowActions
                            {...(DD_ORIGEM_DADO === 'MANUAL' && {
                                onClickDelete: () => onClickDelete(row),
                                onClickEdit: () => onClickEdit(row),
                            })}
                            actions={[
                                {
                                    action: () =>
                                        navigate(
                                            `/dados-mestre/estrutura-produto/status/${SK_PRODUTO_ESTRUTURA}/?component=${SK_PRODUTO_COMPONENTE}&cost=${SK_NOVO_CUSTO}`
                                        ),
                                    label: 'Visualizar status',
                                    icon: <Eye size={14} />,
                                },
                            ]}
                        />
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
                size: 80,
            }),
            columnHelper.accessor(({ DS_STATUS }) => statusLocale[DS_STATUS], {
                id: 'DS_STATUS',
                header: 'Status',
                cell: ({ row }) => {
                    const { DS_STATUS } = row.original

                    return <StructureStatus status={DS_STATUS} />
                },
                size: 100,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'sk_empresa',
                header: 'Cod Empresa',
                size: 80,
            }),
            columnHelper.accessor('NK_PRODUTO_ESTRUTURA', {
                id: 'sk_produto',
                header: 'Cod Produto',
                size: 100,
            }),
            columnHelper.accessor('PRODUTO_ESTRUTURA', {
                id: 'produto',
                header: 'Produto',
                size: 300,
            }),
            columnHelper.accessor('NK_PRODUTO_COMPONENTE', {
                id: 'sk_componente',
                header: 'Cod Componente',
                size: 100,
            }),
            columnHelper.accessor('PRODUTO_COMPONENTE', {
                id: 'componente',
                header: 'Componente',
                size: 300,
            }),
            columnHelper.accessor('QTD_CUSTO', {
                id: 'qtde',
                header: 'Qtde',
                size: 80,
            }),
            columnHelper.accessor(({ DS_CUSTO }) => DS_CUSTO, {
                id: 'ds_custo',
                header: 'Descrição',
                size: 100,
            }),
            columnHelper.accessor(
                ({ VL_CUSTO_ORCADO, DS_MOEDA }) =>
                    currencyFormat(Number(VL_CUSTO_ORCADO), 4, DS_MOEDA),
                {
                    id: 'VL_CUSTO',
                    header: 'Custo',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 120,
                }
            ),
            columnHelper.accessor(
                ({ SK_TEMPO }) => format(getDateFromId(SK_TEMPO), 'MM/yyyy'),
                {
                    id: 'SK_TEMPO',
                    header: 'Data',
                    size: 90,
                }
            ),
            columnHelper.accessor('DD_TIPO_FRETE', {
                id: 'DD_TIPO_FRETE',
                header: 'Frete',
                size: 80,
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 400,
            }),
            columnHelper.accessor(({ VL_PIS }) => VL_PIS || 0, {
                id: 'VL_PIS',
                header: 'Vl PIS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_PIS }) =>
                    percentageFormat(Number(VL_ALIQUOTA_PIS || 0), 4),
                {
                    id: 'VL_ALIQUOTA_PIS',
                    header: 'PIS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_COFINS }) => VL_COFINS || 0, {
                id: 'VL_COFINS',
                header: 'Vl COFINS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_COFINS }) => VL_ALIQUOTA_COFINS || 0,
                {
                    id: 'VL_ALIQUOTA_COFINS',
                    header: 'COFINS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_FCP }) => VL_FCP || 0, {
                id: 'VL_FCP',
                header: 'Vl FCP',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_FCP }) =>
                    percentageFormat(Number(VL_ALIQUOTA_FCP || 0), 4),
                {
                    id: 'VL_ALIQUOTA_FCP',
                    header: 'FCP (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_ICMS }) => VL_ICMS || 0, {
                id: 'VL_ICMS',
                header: 'Vl ICMS',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_ICMS }) =>
                    percentageFormat(Number(VL_ALIQUOTA_ICMS || 0), 4),
                {
                    id: 'VL_ALIQUOTA_ICMS',
                    header: 'ICMS (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_IPI }) => VL_IPI || 0, {
                id: 'VL_IPI',
                header: 'Vl IPI',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_IPI }) =>
                    percentageFormat(Number(VL_ALIQUOTA_IPI || 0), 4),
                {
                    id: 'VL_ALIQUOTA_IPI',
                    header: 'IPI (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(({ VL_ST }) => VL_ST || 0, {
                id: 'VL_ST',
                header: 'Vl ST',
                meta: {
                    cell: {
                        className: 'justify-end',
                    },
                },
                size: 90,
            }),
            columnHelper.accessor(
                ({ VL_ALIQUOTA_ST }) =>
                    percentageFormat(Number(VL_ALIQUOTA_ST || 0), 4),
                {
                    id: 'VL_ALIQUOTA_ST',
                    header: 'ST (%)',
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    size: 90,
                }
            ),
            columnHelper.accessor(
                ({ CREATED_AT }) =>
                    format(new Date(CREATED_AT), 'dd/MM/yyyy HH:mm:ss'),
                {
                    id: 'CREATED_AT',
                    header: 'Data de criação',
                    size: 150,
                }
            ),
            columnHelper.accessor(
                ({ UPDATED_AT }) =>
                    format(new Date(UPDATED_AT), 'dd/MM/yyyy HH:mm:ss', {
                        timeZone: 'America/Sao_Paulo',
                    }),
                {
                    id: 'UPDATED_AT',
                    header: 'Data de atualização',
                }
            ),
            columnHelper.accessor('DD_ORIGEM_DADO', {
                id: 'origem',
                header: 'Origem',
                cell: ({ getValue }) => {
                    const value = getValue()
                    let Badge: ReactNode | null = null

                    switch (value) {
                        case 'MANUAL':
                            Badge = (
                                <span className="px-2 py-1 text-xs text-green-600 rounded-lg bg-green-50 dark:bg-emerald-600/30 dark:text-emerald-400">
                                    {value}
                                </span>
                            )
                            break
                        case 'ETL':
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-lg text-cyan-600 bg-cyan-50 dark:bg-cyan-600/30 dark:text-cyan-400">
                                    {value}
                                </span>
                            )

                            break
                    }

                    return Badge
                },
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
                size: 100,
            }),
            columnHelper.display({
                id: 'badge',
                cell: ({ row }) => {
                    const { CREATED_AT, UPDATED_AT } = row.original
                    let Badge: ReactNode | null = null
                    const createdAt = new Date(CREATED_AT)
                    const updatedAt = new Date(UPDATED_AT)

                    if (isEqual(createdAt, updatedAt)) {
                        if (isToday(createdAt)) {
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-emerald-500 ">
                                    Novo *
                                </span>
                            )
                        }
                    } else {
                        Badge = (
                            <span className="px-2 py-1 text-xs rounded-md text-zinc-400">
                                Editado
                            </span>
                        )
                    }

                    return Badge
                },
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
                size: 80,
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )

    return (
        <div className="flex flex-col w-full h-full p-4 overflow-hidden">
            <Table<ExtraCostTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_NOVO_CUSTO.toString()}
                isLoading={isLoading}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)}
                enableRowSelection
                persist={{
                    canPersist: true,
                    key: 'extra-costs-table',
                }}
                tableState={{
                    rowSelection: selectedRows,
                }}
                onRowSelectionChange={setSelectedRows}
                tableHeader={
                    <DadosMestreHeader
                        title={`Custos extras ${
                            data ? `(${data.length})` : ''
                        }`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onAdd={onClickAdd}
                        onDownload={() => onDownload()}
                        onBulk={onBulkDialogOpen}
                        onRefech={refetch}
                    />
                }
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_NOVO_CUSTO]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_NOVO_CUSTO)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                    setSelectedRows={setSelectedRows}
                />
            )}
            {selectedRow && isEditDialogOpen && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            {isAddDialogOpen && (
                <AddDialog
                    isOpen={isAddDialogOpen}
                    onClose={onAddDialogClose}
                />
            )}
            {isBulkDialogOpen && table ? (
                <BulkDialog
                    isOpen={isBulkDialogOpen}
                    onClose={onBulkDialogClose}
                />
            ) : null}
        </div>
    )
}

export default ExtraCostDadosMestre
