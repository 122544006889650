import { Cost } from '@/api/business/structureStatus/type'
import StructureStatus from '@/components/StructureStatus'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'

import { statusLocale } from '@/types/Status'
import { getDateFromId } from '@/utils/date'
import { currencyFormat } from '@/utils/stringFormatter'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { ReactNode, useMemo } from 'react'

type ProductExtraCostTableProps = {
    costs: Cost[]
}

const columnHelper = createColumnHelper<Cost>()

type ExtraCostTable = TableData<Cost>

const ProductExtraCostTable = ({ costs }: ProductExtraCostTableProps) => {
    const columns = useMemo(
        () => [
            columnHelper.accessor(
                ({ APROVACAO_CUSTO }) =>
                    statusLocale[APROVACAO_CUSTO?.DS_STATUS],
                {
                    header: 'Status',
                    id: 'STATUS_COMPONENTE',
                    size: 100,
                    cell: ({ row }) => (
                        <StructureStatus
                            status={row.original.APROVACAO_CUSTO?.DS_STATUS}
                        />
                    ),
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor('DS_CUSTO', {
                id: 'DS_CUSTO',
                header: 'Descrição',
                size: 200,
            }),
            columnHelper.accessor(
                ({ VL_CUSTO_ORCADO }) =>
                    currencyFormat(Number(VL_CUSTO_ORCADO) || 0, 4),
                {
                    id: 'VL_CUSTO_ORCADO',
                    header: 'Custo',
                }
            ),
            columnHelper.accessor(
                ({ SK_TEMPO }) =>
                    SK_TEMPO
                        ? format(getDateFromId(SK_TEMPO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'SK_TEMPO',
                    header: 'Data',
                    size: 100,
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor('QTD_CUSTO', {
                id: 'QTD_CUSTO',
                header: 'Qtde',
            }),
            columnHelper.accessor('DD_ORIGEM_DADO', {
                id: 'origem',
                header: 'Origem',
                cell: ({ getValue }) => {
                    const value = getValue()
                    let Badge: ReactNode | null = null

                    switch (value) {
                        case 'MANUAL':
                            Badge = (
                                <span className="px-2 py-1 text-xs text-green-600 rounded-md bg-green-50">
                                    {value}
                                </span>
                            )
                            break
                        case 'ETL':
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-md text-cyan-600 bg-blue-50">
                                    {value}
                                </span>
                            )

                            break
                    }

                    return Badge
                },
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
                size: 100,
            }),
            columnHelper.accessor('APROVACAO_CUSTO.NM_USUARIO', {
                id: 'APROVACAO_CUSTO.NM_USUARIO',
                header: 'Executado por',
                size: 400,
            }),
        ],
        []
    )

    return <Table<ExtraCostTable> columns={columns} data={costs} />
}

export default ProductExtraCostTable
