import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createInformedCost } from '@/api/business/stdCost'
import { memo, useCallback, useMemo } from 'react'
import { stdCostKeys } from '@/queries/useStdCostsQuery'
import { useToast } from '@/components/ui/use-toast'
import { Checkbox } from '@/components/ui/checkbox'
import {
    addDays,
    eachDayOfInterval,
    format,
    isBefore,
    isFirstDayOfMonth,
    isSameDay,
    isSunday,
} from 'date-fns'
import { DatePicker } from '@/components/DatePicker'
import { Separator } from '@/components/ui/separator'
import DatePickerMonthWithRange from '@/components/DatePickerMonthWithRange'
import { getIdFromDate } from '@/utils/date'
import { historyKeys } from '@/queries/useStdCostHistoryQuery'
import { MultiSelect } from '@/components/MultiSelect'
import useDisclosure from '@/hooks/useDisclosure'
import { isAxiosError } from '@/api/business'
import { ComponentCustomEntity } from '@/types/StandardCost'
import { useBaseStore } from '@/store'
import { z } from 'zod'
import { Store } from '@/store/type'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { TypographyP } from '@/components/ui/typography'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'
import { Input } from '@/components/ui/input'
import CurrencySelect from '@/pages/DadosMestre/InformedCostDadosMestre/components/AddDialog/components/CurrencySelect'
import TaxRow from '@/pages/DadosMestre/InformedCostDadosMestre/components/EditDialog/components/TaxRow'
import TaxGroup from '@/pages/DadosMestre/InformedCostDadosMestre/components/EditDialog/components/TaxGroup'
import { Button } from '@/components/ui/button'

interface AddCostDialogProps {
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    currencyId: z.coerce.number().min(1, 'Selecione uma moeda'),
    productId: z.array(z.string().min(1, 'Selecione um produto'), {
        message: 'Deve ser selecionado no mínimo um produto',
    }),
    date: z
        .object({
            from: z.date(),
            to: z.date(),
        })
        .refine(
            ({ from, to }) => !isBefore(to, from) || isSameDay(to, from),
            'Data final não pode ser menor que a data inicial'
        ),
    canReplicate: z.boolean(),
    costValue: z.string().min(1, 'Valor inválido'),
    freight: z.literal('CIF').or(z.literal('FOB')),
    observation: z.string().optional(),
    pis: z.string().optional(),
    cofins: z.string().optional(),
    icms: z.string().optional(),
    ipi: z.string().optional(),
    st: z.string().optional(),
    fcp: z.string().optional(),
    pis_value: z.string().optional(),
    cofins_value: z.string().optional(),
    icms_value: z.string().optional(),
    ipi_value: z.string().optional(),
    st_value: z.string().optional(),
    fcp_value: z.string().optional(),
})

type AddSchema = z.infer<typeof schema>

const stateSelector = (state: Store) => {
    return {
        selectedStdCostRow: state.stdCostSlice.state.selectedStdCostRow,
        selectedDate: state.stdCostSlice.state.selectedDate,
        stdCostData: state.stdCostSlice.state.stdCostData,
        isAnualAvg: state.stdCostSlice.state.isAnualAvg,
    }
}

const AddCostDialog = memo(({ isOpen, onClose }: AddCostDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const {
        selectedStdCostRow: selectedRow,
        selectedDate,
        stdCostData: { components, products },
        isAnualAvg,
    } = useBaseStore(stateSelector)

    const getParent = useCallback((parentId: string | number) => {
        const parent =
            products.entities[parentId] || components.entities[parentId]

        return parent._parentId ? products.entities[parent._parentId] : parent
    }, [])

    const component =
        selectedRow && !selectedRow.isProduct
            ? components.entities[selectedRow.id]
            : null

    const product = component ? getParent(component._parentId) : null

    const productOptions = useMemo(() => {
        if (!component || !product) return []

        const allProducts = products.ids
            .map((id) => products.entities[id])
            .map((product) => ({
                ...product,
                COMPONENTES: product.COMPONENTES.map(
                    (componentId) => components.entities[componentId]
                ),
            }))

        const filtered: typeof allProducts = allProducts.filter(
            (product) =>
                product.COMPONENTES.some(
                    (comp) =>
                        comp.SK_PRODUTO_COMPONENTE ===
                        component.SK_PRODUTO_COMPONENTE
                ) ||
                product.COMPONENTES.some((comp) =>
                    comp.COMPONENTES.some(
                        (c) =>
                            components.entities[c].SK_PRODUTO_COMPONENTE ===
                            component.SK_PRODUTO_COMPONENTE
                    )
                )
        )

        return filtered
    }, [component, product])

    const queryKey = stdCostKeys.list(selectedDate.id, isAnualAvg)

    const { mutate: mutateInformedCost, isLoading: isInformedCostLoading } =
        useMutation({
            mutationFn: createInformedCost,
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey)

                queryClient.invalidateQueries(
                    historyKeys.detail({
                        productId: product?.SK_PRODUTO_ESTRUTURA || '',
                        componentId: component?.SK_PRODUTO_COMPONENTE || '',
                        date: selectedDate.id,
                    })
                )
                toast({
                    title: 'Custo adicionado com sucesso',
                })
                onClose()
            },
            onError: (err) => {
                if (isAxiosError(err)) {
                    if (
                        err.response?.status === 401 ||
                        err.response?.status === 402
                    ) {
                        toast({
                            title: 'Sem permissão de acesso',
                            description:
                                'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                            variant: 'destructive',
                        })
                    } else {
                        toast({
                            title: 'Erro ao adicionar custo',
                            description:
                                'Não foi possível adicionar o custo, tente novamente',
                            variant: 'destructive',
                        })
                    }
                }
            },
        })

    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            currencyId: 0,
            productId: product ? [product.SK_PRODUTO_ESTRUTURA] : [],
            date: {
                from: new Date(),
                to: new Date(),
            },
            canReplicate: false,
            costValue: '0',
            freight: 'CIF' as const,
            observation: '',
            pis: '0',
            cofins: '0',
            icms: '0',
            ipi: '0',
            st: '0',
            fcp: '0',
            pis_value: '0',
            cofins_value: '0',
            icms_value: '0',
            ipi_value: '0',
            st_value: '0',
            fcp_value: '0',
        },
    })

    const {
        isOpen: isMultiSelectOpen,
        onClose: onMultiSelectClose,
        onOpen: onMultiSelectOpen,
    } = useDisclosure()

    const canReplicate = form.watch('canReplicate')

    const onSubmit = (values: AddSchema) => {
        const {
            costValue,
            currencyId,
            date,
            freight,
            productId,
            cofins,
            canReplicate,
            cofins_value,
            fcp,
            fcp_value,
            icms,
            icms_value,
            ipi,
            ipi_value,
            observation,
            pis,
            pis_value,
            st,
            st_value,
        } = values

        if (!component || !currencyId) return

        const dates = eachDayOfInterval({
            start: date.from,
            end: date.to,
        })
            .filter((day) => isFirstDayOfMonth(day))
            .map((day) =>
                isSunday(day)
                    ? Number(getIdFromDate(addDays(day, 1)).join(''))
                    : Number(getIdFromDate(day).join(''))
            )

        if (!component || !product) return

        mutateInformedCost({
            SK_EMPRESA: product.SK_EMPRESA,
            VL_CUSTO_ORCADO: costValue,
            SK_PRODUTO_COMPONENTE: component.SK_PRODUTO_COMPONENTE,
            SK_PRODUTO_ESTRUTURA: canReplicate
                ? productId
                : [product.SK_PRODUTO_ESTRUTURA],
            SK_TEMPO:
                canReplicate && date
                    ? dates
                    : isSunday(date.from)
                    ? [Number(`${format(date.from, 'yyyyMM')}02`)]
                    : [Number(`${format(date.from, 'yyyyMM')}01`)],
            SK_MOEDA: Number(currencyId),
            DD_TIPO_FRETE: freight,
            DD_OBSERVACAO: observation,
            VL_ALIQUOTA_COFINS: cofins,
            VL_COFINS: cofins_value,
            VL_ALIQUOTA_FCP: fcp,
            VL_FCP: fcp_value,
            VL_ALIQUOTA_ICMS: icms,
            VL_ICMS: icms_value,
            VL_ALIQUOTA_IPI: ipi,
            VL_IPI: ipi_value,
            VL_ALIQUOTA_PIS: pis,
            VL_PIS: pis_value,
            VL_ALIQUOTA_ST: st,
            VL_ST: st_value,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[900px]">
                <DialogHeader>
                    <DialogTitle>Adicionar custo informado</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="flex gap-4">
                            <div className="flex-1 p-2 space-y-4 overflow-hidden">
                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Estrutura
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <FormField
                                        name="canReplicate"
                                        control={form.control}
                                        render={({ field }) => {
                                            return (
                                                <FormItem className="py-4">
                                                    <div className="flex items-center gap-2">
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={
                                                                    field.value
                                                                }
                                                                onCheckedChange={(
                                                                    value
                                                                ) =>
                                                                    field.onChange(
                                                                        !!value
                                                                    )
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormLabel>
                                                            Replicar estruturas
                                                        </FormLabel>
                                                    </div>
                                                    <FormMessage />
                                                </FormItem>
                                            )
                                        }}
                                    />
                                    <div className="space-y-1.5">
                                        <FormLabel>Componente</FormLabel>
                                        <Input
                                            value={
                                                component?.PRODUTO_COMPONENTE
                                            }
                                            readOnly
                                        />
                                    </div>
                                    {canReplicate ? (
                                        <FormField
                                            name="productId"
                                            control={form.control}
                                            render={({ field }) => {
                                                const onChange = (
                                                    value: Record<
                                                        string,
                                                        boolean
                                                    >
                                                ) => {
                                                    return Object.entries(value)
                                                        .filter(([, value]) =>
                                                            Boolean(value)
                                                        )
                                                        .map(([key]) => key)
                                                }

                                                const selectedValues =
                                                    field.value.reduce(
                                                        (acc, curr) => {
                                                            return {
                                                                ...acc,
                                                                [curr]: true,
                                                            }
                                                        },
                                                        {}
                                                    )

                                                return (
                                                    <FormItem>
                                                        <FormLabel>
                                                            Replicar estruturas
                                                        </FormLabel>
                                                        <FormControl>
                                                            <MultiSelect
                                                                isOpen={
                                                                    isMultiSelectOpen
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    field.onChange(
                                                                        onChange(
                                                                            value
                                                                        )
                                                                    )
                                                                }
                                                                onClose={
                                                                    onMultiSelectClose
                                                                }
                                                                onOpen={
                                                                    onMultiSelectOpen
                                                                }
                                                                options={productOptions.map(
                                                                    (
                                                                        product
                                                                    ) => ({
                                                                        label: product.PRODUTO_ESTRUTURA,
                                                                        value: product.SK_PRODUTO_ESTRUTURA.toLowerCase(),
                                                                    })
                                                                )}
                                                                selectedValues={
                                                                    selectedValues
                                                                }
                                                                customFilter={(
                                                                    id,
                                                                    search
                                                                ) => {
                                                                    const value =
                                                                        productOptions.find(
                                                                            (
                                                                                product
                                                                            ) =>
                                                                                product.SK_PRODUTO_ESTRUTURA ==
                                                                                id
                                                                        )

                                                                    if (!value)
                                                                        return 0

                                                                    const matchName =
                                                                        value.PRODUTO_ESTRUTURA.toLowerCase()
                                                                            .trim()
                                                                            .includes(
                                                                                search
                                                                                    .toLocaleLowerCase()
                                                                                    .trim()
                                                                            )

                                                                    const matchId =
                                                                        value.SK_PRODUTO_ESTRUTURA.includes(
                                                                            search
                                                                        )

                                                                    return matchName ||
                                                                        matchId
                                                                        ? 1
                                                                        : 0
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                    ) : (
                                        <div>
                                            <FormLabel>Estruturas</FormLabel>
                                            <Input
                                                type="text"
                                                value={
                                                    product?.PRODUTO_ESTRUTURA ||
                                                    (
                                                        product as unknown as ComponentCustomEntity
                                                    )?.PRODUTO_COMPONENTE
                                                }
                                                readOnly
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Informações
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="space-y-2">
                                        <FormField
                                            name="date"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Data
                                                        </FormLabel>
                                                        <FormControl>
                                                            {canReplicate ? (
                                                                <DatePickerMonthWithRange
                                                                    date={
                                                                        field.value
                                                                    }
                                                                    setDate={
                                                                        field.onChange
                                                                    }
                                                                    className="w-full"
                                                                />
                                                            ) : (
                                                                <DatePicker
                                                                    className="w-full"
                                                                    date={
                                                                        field
                                                                            .value
                                                                            .from
                                                                    }
                                                                    setDate={(
                                                                        date
                                                                    ) =>
                                                                        field.onChange(
                                                                            {
                                                                                from: date,
                                                                                to: date,
                                                                            }
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        isSunday
                                                                    }
                                                                />
                                                            )}
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="freight"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Frete
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Select
                                                                {...field}
                                                                onValueChange={
                                                                    field.onChange
                                                                }
                                                            >
                                                                <SelectTrigger>
                                                                    <SelectValue />
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    <SelectItem value="CIF">
                                                                        CIF
                                                                    </SelectItem>
                                                                    <SelectItem value="FOB">
                                                                        FOB
                                                                    </SelectItem>
                                                                </SelectContent>
                                                            </Select>
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <FormField
                                            name="observation"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem>
                                                        <FormLabel className="block">
                                                            Observação
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Textarea
                                                                {...field}
                                                                className="resize-none"
                                                                maxLength={200}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 p-2 space-y-4 overflow-hidden">
                                <div>
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Custo
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <div className="flex gap-4">
                                        <FormField
                                            name="costValue"
                                            control={form.control}
                                            render={({ field }) => {
                                                return (
                                                    <FormItem className="flex-1">
                                                        <FormLabel>
                                                            Custo
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                {...field}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )
                                            }}
                                        />
                                        <CurrencySelect />
                                    </div>
                                </div>
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <TypographyP className="font-semibold">
                                            Impostos
                                        </TypographyP>
                                        <Separator />
                                    </div>
                                    <TaxRow>
                                        <TaxGroup title="PIS">
                                            <FormField
                                                name="pis"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="pis_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="COFINS">
                                            <FormField
                                                name="cofins"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="cofins_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                    <TaxRow>
                                        <TaxGroup title="ICMS">
                                            <FormField
                                                name="icms"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="icms_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="IPI">
                                            <FormField
                                                name="ipi"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="ipi_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                    <TaxRow>
                                        <TaxGroup title="ST">
                                            <FormField
                                                name="st"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="st_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                        <TaxGroup title="FCP">
                                            <FormField
                                                name="fcp"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Aliquota
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                            <FormField
                                                name="fcp_value"
                                                control={form.control}
                                                render={({ field }) => {
                                                    return (
                                                        <FormItem>
                                                            <FormLabel>
                                                                Valor
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )
                                                }}
                                            />
                                        </TaxGroup>
                                    </TaxRow>
                                </div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                variant="ghost"
                                type="button"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                type="submit"
                                disabled={isInformedCostLoading}
                            >
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
})

export default AddCostDialog
