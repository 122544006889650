import { TypographyH3, TypographyMuted } from '@/components/ui/typography'

interface DadosMestreHeaderProps {
    title: string
    selectedRows?: number
    onDelete?: () => void
}

const DadosMestreHeader = ({
    title,
    selectedRows,
    onDelete,
}: DadosMestreHeaderProps) => {
    return (
        <div>
            <TypographyH3 className="text-xl font-semibold">
                {title}
            </TypographyH3>
            {selectedRows && selectedRows > 0 ? (
                <div className="flex items-center gap-2">
                    <TypographyMuted>
                        {selectedRows} itens selecionados
                    </TypographyMuted>
                    <span>-</span>
                    <button
                        className="text-sm rounded-md bg-tranparent text-neutral-500 hover:underline"
                        onClick={onDelete}
                    >
                        Excluir
                    </button>
                </div>
            ) : null}
        </div>
    )
}

export default DadosMestreHeader
