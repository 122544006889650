type DefaultCellProps = {
    children?: React.ReactNode
}

const DefaultCell = ({ children }: DefaultCellProps) => {
    return (
        <p className="overflow-hidden whitespace-nowrap text-ellipsis">
            {children}
        </p>
    )
}

export default DefaultCell
