import { z } from 'zod'

export const addProductSchema = z.object({
    SK_EMPRESA: z.string(),
    DS_PRODUTO: z.string().min(1, 'Campo obrigatório'),
    DD_UM: z.string().min(1, 'Campo obrigatório'),
    DD_CLASSIFICACAO: z.string().min(1, 'Campo obrigatório'),
    QTD_VOLUME: z.string().optional(),
    DD_TAMANHO_EMBALAGEM: z.string().optional(),
    QTD_PRODUTO_POR_EMBALAGEM: z.string().optional(),
})

export const updateProductSchema = z.object({
    SK_EMPRESA: z.string().min(1),
    DS_PRODUTO: z.string().min(1),
    DD_UM: z.string(),
    DD_CLASSIFICACAO: z.string(),
    QTD_VOLUME: z.string().optional().nullable(),
    DD_TAMANHO_EMBALAGEM: z.string().optional().nullable(),
})

export type AddProductSchema = z.infer<typeof addProductSchema>
export type UpdateProductSchema = z.infer<typeof updateProductSchema>
