import instance from '..'
import { Classification, Product, UnityOfMeasurement } from '@/types/Product'
import {
    CreateProductProps,
    DeleteProductProps,
    UpdateProductProps,
} from './type'

export const fetchProducts = async () => {
    const { data } = await instance.post<Product[]>(
        '/produto/portalcusto/produto'
    )

    return data
}

export const createProduct = async (props: CreateProductProps[]) => {
    const { data } = await instance.put<Product[]>(
        '/produto/portalcusto/produto',
        props
    )

    return data
}

export const updateProduct = async (props: UpdateProductProps) => {
    const { data } = await instance.patch<Product[]>(
        '/produto/portalcusto/produto',
        props
    )

    return data
}

export const deleteProduct = async (props: DeleteProductProps) => {
    const { data } = await instance.delete('/produto/portalcusto/produto', {
        data: props,
    })

    return data
}

export const fetchClassification = async () => {
    const { data } = await instance.post<Classification[]>(
        '/produto/portalcusto/classificacao'
    )

    return data
}

export const fetchUnityOfMeasurement = async () => {
    const { data } = await instance.post<UnityOfMeasurement[]>(
        '/produto/portalcusto/unidadedemedida'
    )

    return data
}
