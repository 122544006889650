import { Row } from '@tanstack/react-table'
import { ApproverTable } from '../..'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { approverKeys } from '@/queries/useApproverQuery'
import { isAxiosError } from '@/api/business'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { updateApprover } from '@/api/business/approver'
import {
    Form,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Button } from '@/components/ui/button'

interface EditDialogProps {
    row: Row<ApproverTable>
    isOpen: boolean
    onClose: () => void
}

const schema = z.object({
    name: z.string().min(1, 'Nome é obrigatório'),
    email: z.string().email('Email inválido'),
})

type EditSchema = z.infer<typeof schema>

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { original } = row

    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            name: original.NM_APROVADOR,
            email: original.DD_EMAIL,
        },
    })

    const { mutate, isLoading } = useMutation({
        mutationFn: updateApprover,
        onSuccess: () => {
            queryClient.invalidateQueries(approverKeys.lists())
            toast({
                title: 'Aprovador atualizado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    toast({
                        title: 'Sem permissão de acesso',
                        description:
                            'O seu perfil de usuário não possui permissão para essa ação. Caso acredite que seja um erro, solicitar acessos.',
                        variant: 'destructive',
                    })
                } else {
                    toast({
                        title: 'Erro ao atualizar aprovador',
                        description: 'Verifique os dados e tente novamente',
                        variant: 'destructive',
                    })
                }
            }
        },
    })

    const onSubmit = ({ email, name }: EditSchema) => {
        mutate({
            DD_EMAIL: email,
            NM_APROVADOR: name,
            SK_APROVADOR: original.SK_APROVADOR,
        })
    }

    useEffect(() => {
        form.reset({
            email: original.DD_EMAIL,
            name: original.NM_APROVADOR,
        })
    }, [row])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Editar aprovador</DialogTitle>
                </DialogHeader>
                <div>
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(onSubmit)}
                            className="space-y-4"
                        >
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome</FormLabel>
                                        <Input {...field} type="text" />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            {...field}
                                            type="text"
                                            placeholder="Exemplo: usuario@cervejariacidadeimperial.com"
                                        />
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <DialogFooter>
                                <Button
                                    type="button"
                                    variant="ghost"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </Button>
                                <Button disabled={isLoading}>Confirmar</Button>
                            </DialogFooter>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
