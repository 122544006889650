import { useDrop } from 'react-dnd'
import CompanyDropSection from './CompanyDropSection'
import { cn } from '@/lib/utils'
import { useProductQuery } from '@/queries/useProductQuery'
import { useMemo } from 'react'
import { ScrollArea } from '@/components/ui/scroll-area'
import { ClipboardCopy, Plus } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { AcceptTypes, ProductParent } from '@/store/productStructureSlice/type'
import { useBaseStore } from '@/store'

import { Store } from '@/store/type'
import { TypographyP } from '@/components/ui/typography'

interface ProductStructureDropzoneProps {
    isOpen: boolean
}

const stateSelector = (state: Store) => ({
    droppedItems: state.productStructureSlice.state.droppedProductStructure,
    dropItem: state.productStructureSlice.actions.dropItem,
    onOpenCopyStructureDialog:
        state.productStructureSlice.actions.onChangeCopyProductStructureDialog,
})

const ProductStructureDropzone = ({
    isOpen,
}: ProductStructureDropzoneProps) => {
    const { droppedItems, dropItem, onOpenCopyStructureDialog } =
        useBaseStore(stateSelector)

    const { data } = useProductQuery({ enabled: isOpen })

    const companies = useMemo(
        () => (data ? [...new Set(data.map((prod) => prod.SK_EMPRESA))] : []),
        [data]
    )

    const [{ isOver, canDrop }, drop] = useDrop<
        ProductParent,
        unknown,
        { isOver: boolean; canDrop: boolean }
    >(() => ({
        accept: [AcceptTypes.PRODUCT, ...companies],
        canDrop: (item) => {
            if (
                droppedItems
                    .find((company) => company.companyId === item.SK_EMPRESA)
                    ?.productStructures.some(
                        (product) => product.id === item.id
                    )
            )
                return false

            return true
        },
        drop: (item, monitor) => {
            if (!monitor.didDrop()) {
                dropItem({ ...item, type: AcceptTypes.PARENT })
            }
        },
        collect: (monitor) => {
            return {
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }
        },
    }))

    return (
        <div className="flex flex-col h-full">
            <div className="flex items-center justify-between mb-4">
                <TypographyP className="font-semibold">Estruturas</TypographyP>
                <ButtonWithTooltip
                    className="flex items-center justify-center w-8 h-8 rounded-md hover:bg-primary-600"
                    onClick={() => onOpenCopyStructureDialog(true)}
                    tooltipContent={<p>Copiar estrutura</p>}
                >
                    <ClipboardCopy size={16} />
                </ButtonWithTooltip>
            </div>
            {droppedItems.length === 0 ? (
                <div
                    className={cn(
                        'flex items-center justify-center w-full flex-1 border rounded-md border-neutral-400 border-dashed',
                        isOver && canDrop
                            ? 'border-solid border-primary text-primary'
                            : ''
                    )}
                    ref={drop}
                >
                    <TypographyP
                        className={cn(isOver && canDrop ? 'text-primary' : '')}
                    >
                        Arraste um produto
                    </TypographyP>
                </div>
            ) : (
                <ScrollArea className={cn('w-full flex-1 relative')} ref={drop}>
                    {droppedItems.map((company) => {
                        return (
                            <CompanyDropSection
                                key={company.companyId}
                                id={company.companyId}
                                title={company.companyName}
                                items={company.productStructures}
                            />
                        )
                    })}
                    <AnimatePresence>
                        {isOver && canDrop ? (
                            <motion.div
                                className="absolute bottom-0 left-0 right-0 flex items-center justify-center w-full h-6 rounded-sm bg-primary-300"
                                layout="position"
                                initial={{
                                    y: 16,
                                    opacity: 0.5,
                                }}
                                animate={{
                                    y: 0,
                                    opacity: 1,
                                }}
                            >
                                <Plus
                                    size={14}
                                    className="text-white"
                                    strokeWidth={3}
                                />
                            </motion.div>
                        ) : null}
                    </AnimatePresence>
                </ScrollArea>
            )}
        </div>
    )
}

export default ProductStructureDropzone
