import { useEffect, useState } from 'react'
import TabSection from '../../../../../TabSection'
import { CostView } from '@/types/Product'
import { Box, Boxes, DollarSign } from 'lucide-react'
import {
    getComponentByIdSelector,
    stdCostSliceActionsSelector,
    stdCostSliceStateSelector,
} from '@/store/stdCostSlice'
import { useBaseStore } from '@/store'
import { cn } from '@/lib/utils'
import { TypographyP } from '@/components/ui/typography'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

interface ComponentProps {
    componentId: string
}

export const CostViewSelectOptions = [
    {
        label: 'Custo informado',
        value: CostView.CUSTO_INFORMADO,
    },
    {
        label: 'Último preço',
        value: CostView.ULTIMO_PRECO,
    },
]

const Component = ({ componentId }: ComponentProps) => {
    const component = useBaseStore(getComponentByIdSelector(componentId))

    const {
        stdCostData: { components, products },
        selectedDate,
    } = useBaseStore(stdCostSliceStateSelector)

    const { onChangeCostView, onSelectStdCostRow: onSelectRow } = useBaseStore(
        stdCostSliceActionsSelector
    )

    const product = products.entities[component._parentId]
    const parentComponent = components.entities[component._parentId]

    const [selectedCostView, setSelectedCostView] = useState<CostView | ''>(
        component?.VISAO_SELECIONADA || ''
    )

    const onChangeSelectedCostView = (value: CostView) => {
        setSelectedCostView(value)

        if (!component) return

        onChangeCostView({
            productId: component._id,
            costView: value,
            dateId: selectedDate.id,
        })
    }

    useEffect(() => {
        setSelectedCostView(component.VISAO_SELECIONADA || '')
    }, [component])

    return (
        <>
            <TabSection title="Componente selecionado">
                {component?.PRODUTO_COMPONENTE && (
                    <TypographyP
                        className={cn(
                            'flex items-center w-full gap-2 text-accent-foreground',
                            component.productUsedAsComponentId &&
                                'text-yellow-600 hover:underline cursor-pointer'
                        )}
                        onClick={
                            component.productUsedAsComponentId
                                ? () => {
                                      if (component.productUsedAsComponentId)
                                          onSelectRow({
                                              id: component.productUsedAsComponentId,
                                              isProduct: true,
                                          })
                                  }
                                : undefined
                        }
                    >
                        {component.ESTRUTURA !== 'PADRAO' ? (
                            <DollarSign size={16} />
                        ) : (
                            <Box size={16} />
                        )}
                        <span className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                            {component?.PRODUTO_COMPONENTE}
                        </span>
                    </TypographyP>
                )}
            </TabSection>
            {component?.ESTRUTURA === 'PADRAO' &&
            component.COMPONENTES.length === 0 ? (
                <>
                    {component.productUsedAsComponentId ? (
                        <TabSection title="Tipo visão">
                            <div className="flex items-center justify-center">
                                <TypographyP className="text-yellow-600">
                                    Custo padrão
                                </TypographyP>
                            </div>
                        </TabSection>
                    ) : (
                        <>
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center justify-between">
                                    <TypographyP asChild>
                                        <label className="font-semibold text-accent-foreground">
                                            Selecionar visão
                                        </label>
                                    </TypographyP>
                                </div>
                                <Select
                                    onValueChange={(value) =>
                                        onChangeSelectedCostView(
                                            value as CostView
                                        )
                                    }
                                    value={selectedCostView || ''}
                                >
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {CostViewSelectOptions.map(
                                                (option) => {
                                                    return (
                                                        <SelectItem
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </SelectItem>
                                                    )
                                                }
                                            )}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </div>
                        </>
                    )}
                    {selectedCostView === CostView.CUSTO_INFORMADO &&
                    Number(component.VALOR_INFORMADO_COMPONENTE) === 0 ? (
                        <TypographyP className="py-1 text-center text-red-400 rounded-md bg-red-50 dark:bg-red-400/20">
                            Não há custo informado para esse produto
                        </TypographyP>
                    ) : null}
                </>
            ) : null}
            <>
                <TabSection title="Produto">
                    <TypographyP
                        className="flex items-center gap-2 cursor-pointer text-primary-700 dark:text-primary hover:underline"
                        onClick={() => {
                            onSelectRow({
                                id: product._id,
                                isProduct: true,
                            })
                        }}
                    >
                        <Boxes size={20} strokeWidth={1.5} />
                        <span className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                            {parentComponent?.PRODUTO_COMPONENTE ||
                                product.PRODUTO_ESTRUTURA}
                        </span>
                    </TypographyP>
                </TabSection>
                {component.COMPONENTES.length > 0 ? (
                    <TabSection title="Componentes">
                        {component.COMPONENTES.map((id) => {
                            const subComponent = components.entities[id]

                            return (
                                <TypographyP
                                    className="flex items-center gap-2 mb-2 cursor-pointer hover:underline"
                                    key={subComponent._id}
                                    onClick={() => {
                                        onSelectRow({
                                            id: subComponent._id,
                                            isProduct: false,
                                        })
                                    }}
                                >
                                    {subComponent.ESTRUTURA ===
                                    'EXTRA-COMPONENTE' ? (
                                        <DollarSign size={16} />
                                    ) : (
                                        <Box size={16} strokeWidth={2} />
                                    )}
                                    <span className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">
                                        {subComponent.PRODUTO_COMPONENTE}
                                    </span>
                                </TypographyP>
                            )
                        })}
                    </TabSection>
                ) : null}
            </>
        </>
    )
}

export default Component
