import { Boxes, RotateCcw } from 'lucide-react'
import { CostView, ProductCustomEntity } from '@/types/StandardCost'
import StdCostAnualAvgToggle from '@/pages/StdCosts/components/StdCostsTable/components/StdCostAnualAvgToggle'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { ScrollArea } from '@/components/ui/scroll-area'
import { cn } from '@/lib/utils'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { useEffect, useMemo, useState } from 'react'
import CompositionPieChart from '../../../CompositionPieChart'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
// import Chart from './components/Chart'

const stateSelector = (state: Store) => ({
    onChangeStdCostQuantity: state.stdCostSlice.actions.onChangeStdCostQuantity,
    components: state.stdCostSlice.state.stdCostData.components.entities,
})

interface ProductSectionProps {
    product: ProductCustomEntity
}

const ProductSection = ({ product }: ProductSectionProps) => {
    const { onChangeStdCostQuantity, components } = useBaseStore(stateSelector)

    const [quantity, setQuantity] = useState(0)

    const children = useMemo(
        () =>
            Object.values(components).filter(
                (component) => component._parentId === product._id
            ),
        [components, product]
    )

    const base = children.filter(
        (child) => child.TIPO_VISAO !== CostView.CUSTO_EXTRA
    )

    const expenses = children.filter(
        (child) => child.TIPO_VISAO === CostView.CUSTO_EXTRA
    )

    const baseValue = base.reduce(
        (acc, comp) => (acc += comp.CUSTO_SELECIONADO || 0),
        0
    )
    const expensesValue = expenses.reduce(
        (acc, comp) => (acc += comp.CUSTO_SELECIONADO || 0),
        0
    )

    const baseValuePercentage = baseValue / (baseValue + expensesValue) || 0
    const expensesValuePercentage =
        expensesValue / (baseValue + expensesValue) || 0

    useEffect(() => {
        setQuantity(
            product?.PRODUTO_QTD_SELECIONADA ||
                product?.QTD_PRODUCAO_APONTADA ||
                0
        )
    }, [product])

    return (
        <ScrollArea className="flex-1 p-4">
            <div className="mb-4">
                <div className="flex items-center justify-between w-full mb-4">
                    <TypographyP className="font-semibold">
                        Detalhamento
                    </TypographyP>
                    <StdCostAnualAvgToggle />
                </div>
                <div className="flex items-end gap-4">
                    <div className="space-y-1.5 flex-1">
                        <TypographyP>Produto</TypographyP>
                        <div className="flex items-center gap-2 p-2 text-primary-600 dark:text-primary bg-accent">
                            <Boxes size={20} strokeWidth={1.5} />
                            <TypographyP className="text-primary-600 dark:text-primary">
                                {product.PRODUTO_ESTRUTURA}
                            </TypographyP>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center justify-between">
                            <TypographyP>Qtde</TypographyP>
                            {product?.PRODUTO_QTD_SELECIONADA ? (
                                <button
                                    onClick={() =>
                                        onChangeStdCostQuantity({
                                            isProduct: true,
                                            id: product._id,
                                            quantity: null,
                                        })
                                    }
                                >
                                    <RotateCcw size={14} />
                                </button>
                            ) : null}
                        </div>
                        <input
                            className="w-full py-1 text-center bg-transparent border rounded-md border-primary-500"
                            type="number"
                            value={quantity}
                            onChange={(e) =>
                                setQuantity(Number(e.target.value))
                            }
                            onBlur={() => {
                                if (!product) return
                                onChangeStdCostQuantity({
                                    isProduct: true,
                                    id: product._id,
                                    quantity: Number(quantity),
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4 space-y-2">
                <div>
                    <TypographyP className="font-semibold">Custos</TypographyP>
                </div>
                <div className="grid w-full grid-cols-[repeat(3,minmax(50px,1fr))] gap-4">
                    <div className="p-2 rounded-md bg-accent">
                        <TypographyMuted>Custo padrão</TypographyMuted>
                        <div>
                            <TypographyP className="font-semibold text-md">
                                {currencyFormat(product.CUSTO_SELECIONADO, 6)}
                            </TypographyP>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-accent">
                        <TypographyMuted>Custo padrão real</TypographyMuted>
                        <div>
                            <TypographyP className="font-semibold text-md">
                                {currencyFormat(product.CUSTO_PADRAO_REAL, 6)}
                            </TypographyP>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-accent">
                        <TypographyMuted>Custo real</TypographyMuted>
                        <div>
                            <TypographyP className="font-semibold text-md">
                                {currencyFormat(
                                    product.VALOR_CUSTO_REAL_COMPONENTE,
                                    6
                                )}
                            </TypographyP>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-accent">
                        <TypographyMuted>Custo padrão total</TypographyMuted>
                        <div>
                            <TypographyP className="font-semibold text-md">
                                {currencyFormat(product.CUSTO_PADRAO_TOTAL, 6)}
                            </TypographyP>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-accent">
                        <TypographyMuted>
                            Custo padrão real total
                        </TypographyMuted>
                        <div>
                            <TypographyP className="font-semibold text-md">
                                {currencyFormat(
                                    product.CUSTO_PADRAO_TOTAL_REAL,
                                    6
                                )}
                            </TypographyP>
                        </div>
                    </div>
                    <div className="p-2 rounded-md bg-accent">
                        <TypographyMuted>Custo real total</TypographyMuted>
                        <div>
                            <TypographyP className="font-semibold text-md">
                                {currencyFormat(product.CUSTO_TOTAL_REAL, 6)}
                            </TypographyP>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-4 space-y-2">
                <div>
                    <TypographyP className="font-semibold">
                        Composição do custo
                    </TypographyP>
                </div>
                <div className="grid h-[200px] w-full grid-cols-3 gap-4">
                    <div className="flex items-center justify-center col-span-2 row-span-2 bg-accent">
                        <CompositionPieChart
                            product={product}
                            baseValue={baseValue || 0}
                            expensesValue={expensesValue || 0}
                        />
                    </div>
                    <div className="flex flex-col justify-center gap-2 p-2 text-center rounded-md bg-accent">
                        <TypographyMuted>Matéria prima</TypographyMuted>
                        <div>
                            <TypographyP className="text-lg font-semibold">
                                {currencyFormat(baseValue, 6)}
                            </TypographyP>
                            <TypographyMuted>
                                {percentageFormat(baseValuePercentage * 100)}
                            </TypographyMuted>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center gap-2 p-2 text-center rounded-md bg-accent">
                        <TypographyMuted>Rateios</TypographyMuted>
                        <div>
                            <TypographyP className="text-lg font-semibold">
                                {currencyFormat(expensesValue, 6)}
                            </TypographyP>
                            <TypographyMuted>
                                {percentageFormat(
                                    expensesValuePercentage * 100
                                )}
                            </TypographyMuted>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-4 space-y-2">
                <div>
                    <TypographyP className="font-semibold">
                        Variações
                    </TypographyP>
                </div>
                <div className="gap-4 space-y-2">
                    <div className="flex items-center justify-between p-2 rounded-md bg-accent">
                        <TypographyMuted>
                            Variação custo padrão real total
                        </TypographyMuted>
                        <div className="flex items-center gap-8">
                            <div>
                                <TypographyP className="font-semibold text-md text-end">
                                    {currencyFormat(
                                        product.VARIACAO_CUSTO_PADRAO_TOTAL ||
                                            0,
                                        6
                                    )}
                                </TypographyP>
                            </div>
                            <div>
                                <TypographyP
                                    className={cn(
                                        'font-semibold text-md text-end',
                                        product.VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL <
                                            0
                                            ? 'text-emerald-500'
                                            : 'text-red-500/90'
                                    )}
                                >
                                    {percentageFormat(
                                        product.VARIACAO_PERCENTUAL_CUSTO_PADRAO_REAL_TOTAL ||
                                            0,
                                        6
                                    )}
                                </TypographyP>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between p-2 rounded-md bg-accent">
                        <TypographyMuted>
                            Variação custo real total
                        </TypographyMuted>
                        <div className="flex items-center gap-8">
                            <div>
                                <TypographyP className="font-semibold text-md text-end">
                                    {currencyFormat(
                                        product.VARIACAO_CUSTO_REAL_TOTAL || 0,
                                        6
                                    )}
                                </TypographyP>
                            </div>
                            <div>
                                <TypographyP
                                    className={cn(
                                        'font-semibold text-md text-end',
                                        product.VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL <
                                            0
                                            ? 'text-emerald-500'
                                            : 'text-red-500/90'
                                    )}
                                >
                                    {percentageFormat(
                                        product.VARIACAO_PERCENTUAL_CUSTO_REAL_TOTAL ||
                                            0,
                                        6
                                    )}
                                </TypographyP>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}

export default ProductSection
