import { StdCost } from '@/store/stdCostSlice/type'

export enum WorkerTypes {
    'ON_CALCULATE_STD_COST',
}

export type WorkerData = {
    type: WorkerTypes.ON_CALCULATE_STD_COST
    payload: { data: StdCost; productId: string | null }
}
