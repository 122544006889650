import { SIDENAV_ITEMS } from './consts'
import SidenavItem from './components/SidenavItem'
import Logo from '@/assets/icons/icon192.png'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useBaseStore } from '@/store'
import { TypographyP } from '../ui/typography'
import { Store } from '@/store/type'

const stateSelector = (state: Store) => ({
    isOpen: state.appConfigSlice.state.isSidenavOpen,
    setIsOpen: state.appConfigSlice.actions.setSidenavOpen,
})

const Sidenav = () => {
    const { isOpen, setIsOpen } = useBaseStore(stateSelector)

    return (
        <>
            {isOpen && (
                <div
                    className="absolute top-0 bottom-0 left-0 right-0 z-10 sm:hidden"
                    onClick={() => setIsOpen(false)}
                ></div>
            )}
            <motion.div
                className={`fixed left-0 flex flex-col h-full z-40 bg-zinc-900 text-neutral-50 ease-in transition`}
                initial={{
                    width: isOpen ? 224 : 56,
                }}
                animate={{
                    width: isOpen ? 224 : 56,
                }}
            >
                <Link
                    className={`${
                        isOpen ? 'px-4' : ''
                    } flex items-center w-full gap-2 h-header`}
                    to="/"
                >
                    <img src={Logo} className={`w-10 h-10 mx-auto`} />
                    {isOpen && (
                        <TypographyP className="flex-1 whitespace-nowrap text-ellipsis text-neutral-50">
                            Portal Custo
                        </TypographyP>
                    )}
                </Link>
                <div
                    className={`${
                        isOpen ? 'px-4' : 'p-2'
                    } flex-1 w-full overflow-y-auto overflow-x-hidden`}
                >
                    {SIDENAV_ITEMS.map((item) => (
                        <SidenavItem
                            key={item.id}
                            id={item.id}
                            Icon={item.Icon}
                            label={item.label}
                            to={item.to}
                            subItems={item.subItems}
                        />
                    ))}
                </div>
                <div className="p-4" />
            </motion.div>
        </>
    )
}
export default Sidenav
